import React, { Component } from 'react';
import Countries from '../json/geo_new_portlex_countries.json';
// import Country_ISO3 from './json/new_portlex_countries';

// require("./pdfmake.min.js");

// require("./busy_load.css");
require('./component.home.css')




class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            partecipantCountries: [],
        };
        this.arrAlphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "U", "V", "W", "X", "Y", "Z"];
        this.partecipantCountry = []
        this.CountryList = [];
    }

    componentDidMount() {


        //         let sidenav = document.querySelector("#slide-out");
        //    M.Sidenav.init(sidenav, {});

        var $ = require("jquery");
        //  $('.busy-load-container').show()
        setTimeout(function() {
            $('.busy-load-container').hide();

            $('.sidenav_react_buttons .collapsible-header.active').removeClass('active');
            $('.sidenav_react_buttons .collapsible-header.home').addClass('active');


        }, 2000)
        $('.facet-nav').hide();


        var queryString = window.location.search;
        console.warn(queryString)
            //var queryString = '?q=THA';
        if (queryString) {

            if (queryString.includes('atc')) {
                var code = queryString.split('?atc=')[1];

                $('.countries_select_chosen.single_country  select option[iso3=' + code + ']').prop('selected', true)
                $('.countries_select_chosen.single_country  select').trigger("chosen:updated").change();
            }
            if (queryString.includes('country')) {
                var code = queryString.split('?country=')[1];
                //flags_action(code)
                window.location.replace(`${process.env.PUBLIC_URL}/CountryProfile?` + code); // if is a country profile

            }
        }
        // ###################  PARTECIPANT COUNTRY QUERY ################################ 
        const partecipantCountryService = async() => {
            var reqPC = {
                "query": "* treatyId:TRE-000003",
                "requestOptions": {
                    "searchApplicationId": "searchapplications/1be285f8874b8c6b2c3ab405344c95ee"
                },
                "facetOptions": [
                    { "operatorName": "pariso", "numFacetBuckets": 100 },
                    { "operatorName": "typeofinstrumenten", "numFacetBuckets": 100 },
                    { "operatorName": "subjecten", "numFacetBuckets": 100 },
                    { "operatorName": "yeardateadopted", "numFacetBuckets": 100 },
                    { "operatorName": "yearentryintoforce", "numFacetBuckets": 100 },
                    { "operatorName": "treatytypeen", "numFacetBuckets": 100 },
                    { "operatorName": "clspariso", "numFacetBuckets": 100 }
                ],
                "sortOptions": {
                    "operatorName": "yeardateadopted",
                    "sortOrder": "DESCENDING"
                },
                "start": 0
            }

            //     const responsePC = await fetch(Config.apis.gcs, { method: 'post', 
            //     headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(reqPC) });
            //     const json = await responsePC.json();

            //     console.info(json)
            //     var _tempPartecipantCountries = [];


            //     var x = json.results[0].metadata.fields;
            //     for (var i = 0; i<x.length; i++) {
            //         if (x[i].name === "parCode") {

            //             var xx = x[i].textValues.values;
            //             //it can be a very long list!
            //             for (var j = 0; j<xx.length; j++) {

            //                 _tempPartecipantCountries.push(xx[j])
            //             }
            //         }


            //     }
            //    // console.warn(_tempPartecipantCountries)
            //     this.setState({
            //         partecipantCountries: _tempPartecipantCountries
            //     })

        };
        //#############################################################################





        partecipantCountryService()

        if (document.getElementsByClassName('divRouting')[0]) {
            var x = document.getElementsByClassName('divRouting')[0].offsetHeight;
            document.getElementsByClassName('footer')[0].style.top = x + 100 + 'px';
        }

    }



    render() {
        // console.log(Countries)
        const createNewCountriesArray = () => {
            var _tempCountriesList = []
            for (var i = 0; i<Countries.length; i++) {
                if (i === 3)
                    console.log(Countries[i])
                    //  var isPartecipant = this.state.partecipantCountries.includes(Countries[i].country_code) ? true : false;
                var x = {
                        en: Countries[i].en,
                        country_code: Countries[i].country_code,
                        iso3: Countries[i].iso3
                    }
                    //ANgola should be false
                    // "psma_signatory": "20091122",
                    // "national_plans": "No",
                    // "measures": "17"
                if (parseInt(Countries[i].measures)> 0 || Countries[i].psma_party)

                {
                    //  if (Countries[i].national_plans=='Yes' )
                    if (Countries[i].psma_party) {
                        //BENIN
                        // "psma_party": "20210820",
                        // "national_plans": "No",
                        // "measures": "10",
                        x.isPartecipant = true;
                        // isPartecipant: isPartecipant
                    } else

                    {

                        x.isPartecipant = false;
                    }
                    _tempCountriesList.push(x)
                }

            }

            this.CountryList = _tempCountriesList
            //console.info(_tempCountriesList)

        }
        createNewCountriesArray();


        // var CountriesLIst_A = this.CountryList.map(function (item) {

        //     if (item.en.charAt(0) === 'A') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a dataToggle style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} 
        //                 href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? 
        //                     { display: 'inline-block' } : { display: 'none' }} 
        //                 className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_B = this.CountryList.map(function (item) {

        //     if (item.en.charAt(0) === 'B') {

        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} 
        //                 href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? 
        //                 { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_C = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'C') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_D = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'D') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_E = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'E') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_F = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'F') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_G = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'G') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_H = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'H') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_I = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'I') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_J = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'J') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_K = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'K') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_L = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'L') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_M = this.CountryList.map(function (item) {

        //     if (item.en.charAt(0) === 'M') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_N = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'N') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_O = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'O') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_P = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'P') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_Q = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'Q') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_R = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'R') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_S = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'S') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_T = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'T') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_U = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'U') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_V = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'V') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_W = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'W') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_X = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'X') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_Y = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'Y') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })

        // var CountriesLIst_Z = this.CountryList.map(function (item) {
        //     if (item.en.charAt(0) === 'Z') {
        //         return (
        //             <li _code={item.iso3} style={{ listStyleType: 'none' }}>
        //                 <a style={{ whiteSpace: 'nowrap', fontSize: '12px', padding: '0', width: 'auto' }} href={`${process.env.PUBLIC_URL}/CountryProfile?` + item.iso3}>{item.en}<i style={item.isPartecipant === true ? { display: 'inline-block' } : { display: 'none' }} className="fas fa-circle psmaagreement"><span class="tooltiptext">Part of PSM Agreement</span></i></a>
        //             </li>
        //         )
        //     } else
        //         return null
        // })


        // const options = {
        //     height: 600,
        //     width: 900,
        //     hideTabs: false,
        //     // All other vizCreate options are supported here, too
        //     // They are listed here: https://onlinehelp.tableau.com/current/api/js_api/en-us/JavaScriptAPI/js_api_ref.htm#ref_head_9
        // };



        return (

            <div> {
                /* <div class="busy-load-container" id="qje70el23u69orhl0fy8">
                        <div class="busy-load-container-item">
                            <div class="spinner-accordion busy-load-spinner-css busy-load-spinner">
                                <div class="rect rect1"></div>
                                <div class="rect rect2"></div>
                                <div class="rect rect3"></div>
                                <div class="rect rect4"></div>
                                <div class="rect rect5"></div>
                            </div>
                        </div>
                    </div> */
            } <div className="top_map_info row"><span className="col s4 offset-s3"> Click a marker to get general information</span></div>
            <div id = "map"> { /* <img alt="world map" src={World_map} style={{ height: '-webkit-fill-available', maxWidth: '900px' }} /> */ } 
            </div>  {
                /* <div id="worldMap">
                                    <TableauReport
                                        url="https://public.tableau.com/views/PSMAMembership/Sheet1"
                                        options={options}
                                        // Overwrite default query params
                                        // defaults to '?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes'
                                        query="?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes"
                                    />
                                </div> */
            } <div className = "portlexDescription">

            <h1>The <a href = "https://www.fao.org/port-state-measures/en/"
            target = "_blank" rel="noopener noreferrer">Agreement on Port State Measures (PSMA)</a></h1> It is the first binding international agreement to specifically target <a href ="https://www.fao.org/iuu-fishing/en/"
            target = "_blank" rel="noopener noreferrer">Illegal, Unreported and Unregulated (IUU) fishing</a>. 
            Its objective is to prevent, deter and eliminate IUU fishing by preventing vessels engaged in IUU fishing from using ports and landing their catches. The provisions of the PSMA apply to fishing vessels seeking entry into a designated port of a State which is different to their flag State.
            <p></p>

            <h1>Port-Lex database</h1>
            This database provides access to legal provisions adopted by States and Regional Fisheries Management Organisations (RFMO) to implement the<a href = "http://www.fao.org/treaties/results/details/en/c/TRE-000003/"
            target = "_blank" rel="noopener noreferrer"> Port State Measures Agreement to prevent, deter and eliminate Illegal, Unreported and Unregulated fishing</a>. It includes Country profiles and RFMOs profiles.<p></p>

            <h1>Country profiles</h1>
            Country profiles provide information on the country international and regional commitments, the "National Plan of Action to Prevent, Deter and Eliminate Illegal, Unreported and Unregulated Fishing (NPOA-IUU)" and the most relevant national legal documents. Legal provisions specifically implementing the PSMA are cited in full.
            
            <h1>RFMOs profiles</h1>
            RFMOs profiles provide access to basic information on the RFMO, and to its establishing Agreement or Statute. Legal provisions specifically implementing the PSMA are cited in full.

            <h1>Port-Lex search</h1>
            It is possible to filter the retrieved legal provisions by selecting specific parameters or to perform advanced searches, combining free text and parameters in different fields. Selected parameters are in OR within each field and in AND across the different fields. Profiles are downloadable and reflect the selection performed by the user.

           
            </div>

            <div className = "portlexDisclaimer">
            <h1>Disclaimer</h1>
            In Port-Lex, the key elements of each legal or policy document are cited either in English, French or Spanish. Many translations are unofficial and are intended solely to provide a broad understanding of policies, laws and regulations but are not intended to replace consultation of the official texts. While Port-Lex makes every effort to serve as a high quality, reliable source of information, no guarantee is given that the information provided in Port-Lex is correct, complete, and up-to-date. Information in the Port-Lex webpages does not imply the expression of any opinion whatsoever on the part of FAO concerning the legal or development status of any country, territory, city or area or of its authorities, or concerning the delimitation of its frontiers or boundaries.

            </div>


            {
                /* <div className="countryListHeader">
                                    <div className="countryListHeader_title">Country Profiles</div>
                                    
                                    <div className="countryList">
                                        <div className="row">
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>A</h6>
                                            <ul>
                                                {CountriesLIst_A}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>B</h6>
                                            <ul>
                                                {CountriesLIst_B}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>C</h6>
                                            <ul>
                                                {CountriesLIst_C}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>D</h6>
                                            <ul>
                                                {CountriesLIst_D}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>E</h6>
                                            <ul>
                                                {CountriesLIst_E}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>F</h6>
                                            <ul>
                                                {CountriesLIst_F}
                                            </ul>
                                        </div>
                                        </div>

                                        <div className="row">
                                       
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>G</h6>
                                            <ul>
                                                {CountriesLIst_G}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>H</h6>
                                            <ul>
                                                {CountriesLIst_H}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>I</h6>
                                            <ul>
                                                {CountriesLIst_I}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>J</h6>
                                            <ul>
                                                {CountriesLIst_J}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>K</h6>
                                            <ul>
                                                {CountriesLIst_K}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>L</h6>
                                            <ul>
                                                {CountriesLIst_L}
                                            </ul>
                                        </div>

                                        </div>

                                        <div className="row">
                                      
                                      
                                      
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>M</h6>
                                            <ul>
                                                {CountriesLIst_M}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>N</h6>
                                            <ul>
                                                {CountriesLIst_N}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>O</h6>
                                            <ul>
                                                {CountriesLIst_O}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>P</h6>
                                            <ul>
                                                {CountriesLIst_P}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>Q</h6>
                                            <ul>
                                                {CountriesLIst_Q}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>R</h6>
                                            <ul>
                                                {CountriesLIst_R}
                                            </ul>
                                        </div>
                                        </div>

                                        <div className="row">
                                       
                                        </div>

                                        <div className="row">
                                      
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>S</h6>
                                            <ul>
                                                {CountriesLIst_S}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>T</h6>
                                            <ul>
                                                {CountriesLIst_T}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>U</h6>
                                            <ul>
                                                {CountriesLIst_U}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>V</h6>
                                            <ul>
                                                {CountriesLIst_V}
                                            </ul>
                                        </div>
                                        <div class="col s2" style={{ listStyleType: 'none', float: 'left', textAlign: 'left' }}><h6 style={{ fontWeight: 'bold' }}>Z</h6>
                                            <ul>
                                                {CountriesLIst_Z}
                                            </ul>
                                        </div>
                                     
                                        </div>

                                    


                                    </div>
                                </div> */
            } </div>

        )
    }
}

export default Home;