import React, {
    Component
} from 'react';
import GcsService from "./services/GcsService.js";
import PubQueryBuilder from './services/pub-query-builder/PubQueryBuilder.js';
import ResultList from './results/publications/ResultList.js';
import SortBy from './results/publications/SortBy.js';
import './results/publications/PubResultsWidget.css';

import URLQueryHelper from '../URLQueryHelper';
import Config from '../config';

import countries from '../json/new_portlex_countries.json';
import $ from 'jquery';




/**
 * Show the results widget for publications (facets, doc list, pagination...)
 * @prop {string} lang the language
 * @prop {function} onFetchStart callback called when the fetch starts
 * @prop {function} onFetchComplete callback called when the fetch receive the data
 *
 */
class PubResultsWidget extends Component {
    gcs = new GcsService();

    constructor(props) {

        super(props);
        console.warn(this, props);


        this.state = {
            selectedFacets: [],
            formData: {},
            gcsData: {},
            //default one
            sortOperator: "yearoftext",
            externalProcessed: false,
            all_queries: 'empty'
        }



    }

    componentDidMount() {
        console.warn('mounted pubresultswidget')



        // this.onSearch({"query":"El futuro de la alimentación y la agricultura: Tendencias y desafíos - PANORAMA GENERAL","agrovoc":null,"authoren":"","countries":null,"customtitle":"FAO Philippines Newsletter ","defaultlanguage":null,"isbn":"","issn":"","jobnumber":"","meeting":null,"meetingdocsymbol":"","serie":null,"seriesdetail":"","yearfrom":"","yearto":""});
        // this.onSearch({"query":"fao","agrovoc":null,"authoren":"","countries":null,"customtitle":null,"defaultlanguage":null,"isbn":"","issn":"","jobnumber":"","meeting":null,"meetingdocsymbol":"","serie":null,"seriesdetail":"","yearfrom":"","yearto":""});
        let query = URLQueryHelper.getUrlQuery();

        if (query !== null) {
            let jquery = JSON.parse(atob(query.split('#')[0]));
            console.log(jquery)

            
            this.setState({ formData: jquery }, this.onSearch(jquery));
            // this.state = { ...jquery };
            console.log(this, this.state)
            this.state.all_queries = jquery;


        }
        console.warn(this)
            //pere

        let TOMprofile = this.props.TOM;
        let SearchCompare = this.props.querySearchCompare;


     
        if (TOMprofile !== null && TOMprofile !== '') {
            this.fetchData(TOMprofile);
            //TOMprofile = '';
        }
        if ((query === null || query === '') && (SearchCompare === null || SearchCompare === '') && (TOMprofile === null || TOMprofile === '')) {
            //  this.fegtchData("");
            console.warn('empty query, applied when we click on AdvSearch link')
            setTimeout(function() {

                // $('.searchbox .tabs .tab').eq(0).find('span').trigger('click')
                $('.busy-load-container').hide();
            }, 1000)

            $('.searchbox .tabs .tab').eq(1).css('opacity',0)
            //$('.searchbox .tabs .tab').eq(0).find('span').trigger('click')
            return false;
        } else {
            console.info('NOT empty query, SOME PARAM ON THE QUERY from pubresultswidget')
            $('.searchbox .tabs .tab').eq(1).css('opacity',1);
            (async() => {
                //gives zero
                
                console.log(this.state);

                var q_country = this.state.all_queries.countries;
                var q_country = 'AUS';

                var reqMR = {
                    "query": "* country:(\"" + q_country + "\")",
                    "requestOptions": {
                        "searchApplicationId": "searchapplications/1be285f8874b8c6ba8027fcdb39b28ed"
                    }
                };
                const responseMR = await fetch(Config.apis.gcs, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(reqMR) });
                console.warn(responseMR)
                const json = await responseMR.json();
                console.log(json)
                this.country_info_json = json;

                // all of the script.... 

            })();
        }

        // var q_country=this.state.formData.countries[0].value;
        





        //this
        //         state:
        // externalProcessed: false
        // formData:
        // countries: Array(1)
        // 0: {label: 'Angola', value: 'AGO'}


    }

    /**
     * event fired when the user click on search btn
     *
     * @param {object} formData form's data 
     */
    onSearch = (formData) => {
        if (!formData) {
            return;
        }
        formData.allacquaticspecies=[];
        
if ($('.allacquaticspecies input').eq(0).prop('checked'))
{
    formData.allacquaticspecies=['Yes'];

    if ($('.allacquaticspecies input').eq(1).prop('checked'))
    formData.allacquaticspecies.push('No');


    //we have to force not to leave both empty...?


}
else
{
    if ($('.allacquaticspecies input').eq(1).prop('checked'))
    formData.allacquaticspecies=['No'];


}

if ($('.allacquaticspecies input').eq(0).prop('checked')===false &&  $('.allacquaticspecies input').eq(1).prop('checked')===false)
{
 //   formData.allacquaticspecies=['Yes','No'];
}
        //Yes
        //$('.allacquaticspecies input').eq(0).prop('checked')
        console.log('search');
        
        console.log(formData)

        this.setState({ formData: formData, selectedFacets: [] });


        this.fetchData(formData);

    }


    onFormUpdate = (formData) => {
        if (!formData) {
            return;
        }
        
        this.setState({ formData, selectedFacets: [] });


    }
    componentDidUpdate() {

        console.warn(this, this.comparing)

        if (this.props.className.indexOf('comparing')> -1) {
            console.warn('comparing from searchApp.js')
            this.state.comparing = true;
        } else {
            this.state.comparing = false;
        }

        console.log(this.state.comparing, this.country_info_json)
        console.warn('did update, 3 times. WHY????')
        if ($('.no_data')) {
            console.warn('no_data present')
            $('.no-records.test').css('color', 'red')
        }

    }

    /**
     * fetch the data from the server and show the result
     *
     * @param {object} formData form's data 
     * @param {array} selectedFacets the selected facets
     */
    //fetchData(formData, selectedFacets = [], sortOption = "relevance", startRecord = 0) {
    fetchData(formData, selectedFacets = [], sortBy, startRecord = 0) {

        let query;

console.info(formData)

      
// resultFormat: "full"
// sortOperator: "relevance"

        // formData.sortorder = this.state.sortOperator;
        // console.info(formData)
        

        if (!sortBy) {
           // var sort_operator = this.state.sortOperator;
           var sort_operator= formData.sortorder;
        } else {
            var sort_operator = sortBy;
        }

        this.props.resultState.sortOrder=sort_operator;
        console.log(this,sort_operator,sortBy)
        //static createRequestData = (formData, selectedFacets, sortOption, startRecord = 0) => {

        //in query we have what we need for exec_ajax
        query = PubQueryBuilder.createRequestData(formData, selectedFacets, sort_operator, startRecord)
     //   query=obj.query;

        console.log(formData)
            //working
            
        if (!query) {

            $('.busy-load-container').hide();
            return;
        }
        
        // $('.result-list .results_div .parent-item-container').remove();

        this.props.onFetchStart();

        this.gcs.query = formData;
        this.gcs.getList(query, (data) => {



            
            //console.warn('did update, 3 times. WHY????====> because updated setState...?')
            this.setState({ gcsData: data });
            this.props.onFetchComplete();
            
            if (sort_operator==='yearoftext')
            {
                $('.sortby-date').addClass('sort-active')
            }
            if (sort_operator==='relevance')
            {
                $('.sortby-relevance').addClass('sort-active')
            }
            

        });
    }


    /**
     * onclick event for the facet
     *
     * @param {string} operatorName the operator
     * @param {string} fullItem the value for the facet
     * @param {string} descItem the description of the item
     */
    onFacetClick(operatorName, fullItem, descItem) {
        if (!operatorName || !fullItem || !this.state.formData) {
            return;
        }

        let selectedFacets = this.state.selectedFacets.concat([{ operatorName, fullItem, descItem }]);

        console.log(JSON.stringify(this.state))
        this.fetchData(this.state.formData, selectedFacets, this.state.sortOperator);

        this.setState({ selectedFacets });

    }

    onPageClick(startRecord) {
        if (!startRecord && startRecord !== 0) {
            return;
        }

        let selectedFacets = (this.state.selectedFacets === undefined) ? [] : this.state.selectedFacets;

        console.warn(this)

        this.fetchData(this.state.formData, selectedFacets, this.state.sortOperator, startRecord);

        // this.setState({selectedFacets});

    }


    onSortClick(sortBy) {
        if (!sortBy) {
            return;
        }

        console.log(sortBy)
        let selectedFacets = (this.state.selectedFacets === undefined) ? [] : this.state.selectedFacets;
        // let selectedFacets= (this.state.selectedFacets === undefined) ? [] : this.state.selectedFacets;
        
        //this.setState({ sortOperator: sortBy });

        this.fetchData(this.state.formData, selectedFacets, sortBy, 0);
        //this.state.sortOperator, 0);



    }


    /**
     * click event for the facets on the filter nav
     *
     * @param {object} facetData the facet data
     */
    onFilterClick = (facetData) => {
        let filteredSelectedFacets;

        filteredSelectedFacets = this.state.selectedFacets.filter((e) => (JSON.stringify(e) !== JSON.stringify(facetData)));
        console.log(this)

        this.fetchData(this.state.formData, filteredSelectedFacets, this.state.sortOperator);

        this.setState({ selectedFacets: filteredSelectedFacets });
    }



    fetchFaolexCountries() {
        console.log('countries executed 4 times!')

        /* return fetch(Config.apis.countries_desc)
         .then(response => response.json())
         .then(data => {
             let options=[];
             for (let key in data) {
 
                     if(options[data[key]["Country_ISO_Code"]] === undefined){
 
                         options[data[key]["Country_ISO_Code"]] = {};
 
                     }
                     options[data[key]["Country_ISO_Code"]]["en"] = data[key]["en"];
 
             }
             this.faolex_countries.push(options);
     }); */

        //FF
        // try {
        //     var request = new XMLHttpRequest();
        //     request.open('GET', Config.apis.countries_desc, false);  // `false` makes the request synchronous
        //     request.send(null);

        //     if (request.status === 200) {
        //var data = JSON.parse(request.responseText);
        var data = countries;

        let options = [];
        for (let key in data) {

            // if (options[data[key]["iso3"]] === undefined) {

            //     options[data[key]["iso3"]] = {};

            // }
            // options[data[key]["iso3"]]["en"] = data[key]["en"];
            if (options[data[key]["country_code"]] === undefined) {


                options[data[key]["country_code"]] = {};


            }
            options[data[key]["country_code"]]["country_code"] = data[key]["country_code"];
            options[data[key]["country_code"]]["iso3"] = data[key]["iso3"];
            options[data[key]["country_code"]]["en"] = data[key]["en"];

        }


        return options;
        // }
        // } catch {
        //     console.log("Error in loading countries");
        //     return;
        // }



    }
    static getUrlParam(parameter, defaultvalue) {

        console.log(arguments)
        var urlparameter = defaultvalue;
        if (window.location.href.indexOf(parameter)> -1) {
            urlparameter = PubResultsWidget.getUrlVars()[parameter];
        }
        return urlparameter;
    }

    static getUrlVars() {
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
            console.log(arguments)

            vars[key] = value;
        });
        return vars;
    }

    render() {



        console.warn('rendering pubresultswidget')
            // let query = URLQueryHelper.getUrlQuery();
            //     console.log(query)              
            // if (query !== null) {
            //     let jquery = JSON.parse(atob(query.split('#')[0]));

        //     console.log(jquery)


        // }
        console.info(this.state, this.state.comparing);

        var query_obj = this.gcs.query;

        if (this.props.externalSearch && !this.state.externalProcessed) {
            this.setState({ formData: this.props.externalFormData, externalProcessed: true })
                //this.setState({ externalProcessed: true })            
        }
        let recordsLength = this.state.gcsData.length;
        var totalRecordsRetrieved = this.state.gcsData.length;
        // let recordsLength = ((this.state.gcsData || {}).results || []).length;
        if (recordsLength === 0) {
            
        }
        
        

        // let totalRecordsRetrieved = (this.state.gcsData.resultCountExact === undefined) ? this.state.gcsData.resultCountEstimate : this.state.gcsData.resultCountExact;
        // console.log(totalRecordsRetrieved+' totalRecordsRetrieved');
        // let start = ((this.state.gcsData === undefined) ? 0 : this.state.gcsData.start);
        // let end = (this.state.gcsData.pagination === undefined) ? 0 : this.state.gcsData.pagination.next;
        let faolex_countries = this.fetchFaolexCountries();


        console.warn(this);
        console.log(this.state.formData)
        var new_formData = this.state.all_queries;
        if (query_obj) {
            console.warn(new_formData, query_obj)


        }

        // This solution is bad, should be used a general state manager to sync some shared properties, 
        // but I had to adopt it as we had some issues with state managers

         //sortOperator: 'relevance',
         
        if ((this.props.resultState.sortOrder === 'yearoftext' && this.state.sortOperator !== 'yearoftext') ||
            (this.props.resultState.sortOrder !== 'typeOfMeasure' && this.state.sortOperator === 'typeOfMeasure')) {
                
            this.onSortClick(this.props.resultState.sortOrder);
        }

        return ( <div id = "content" className= "row">
            <div className='no_results'>No information for your request</div>
            {/* <div id = "rightcolumn_old" className= "rightcolumn-internal results_left col s3">
            <div id = "c611061"
            className= "csc-default"> {
                (recordsLength> 0) ? ( <div className= "refine_filter_container">


                    <SortBy lang = { this.props.lang }
                    currentOperator = { this.state.sortOperator }
                    onClick = {
                        (onSortClick) =>
                        this.onSortClick(onSortClick)
                    }
                    advSearchOperator = { this.props.resultState.sortorder }
                    /> </div>) : (<div className="col s12 no_data"></div> )
            } 
            <div className= "facet-nav sidebox"> {
           
            } </div> </div>

            </div>  */}
            <div id = "maincontent"
            className= "main-internal selectionShareable col s12 results_right">
       
            { /* </div> */ }

            <div className= "csc-default">
            <div className= "row refine_filter_container_row">
            {
                (recordsLength> 0) ? ( <div className="col s3 refine_filter_container">


                    <SortBy lang = { this.props.lang }
                    currentOperator = { this.props.resultState.sortorder }
                    onClick = {
                        (onSortClick) =>
                        this.onSortClick(onSortClick)
                    }
                    advSearchOperator = { this.props.resultState.sortorder }
                    /> </div>) : (<div className="col s12 no_data"></div> )
            } 
              {/* <div className= "allresults-count col s10"> <span class= "counts"> </span> records </div>  */}
              <div className="col s6 query_results_count_wrapper">
                    <div className="query_results_count"><span></span> records</div>
                    <div className="query_results_count_filtered">Visualizing <span className="counts"></span> filtered records</div>
                </div>
              <div className= "col s3"> 
            <i className= "material-icons dp48 print_pdf"> picture_as_pdf </i>
            </div>

            

            </div> 
       
            
            <div id = "allresults"
            class= "testing"> { /* <div className="no-records"></div> */ } <ResultList from_this = { this }
            test = 'this is a test2'
            this_query = { query_obj }
            comparing = { this.state.comparing }
            formData = { this.state }
            lang = { 'en' }
            data = { this.state.gcsData.results }
            faolex_countries = { faolex_countries }
            resultState = { this.props.resultState }
            /> <div id = "preview-pdf"> </div> </div> <div style = {
                { clear: "both" } }> </div> </div>

            </div> {
                /* <aside id="rightcolumn" className="rightcolumn-internal">
                                    <div id="c611061" className="csc-default">
                                        <div className="facet-nav sidebox">
                                            <FacetWidget
                                                selectedFacets={this.state.selectedFacets}
                                                lang={this.props.lang}
                                                data={this.state.gcsData.facetResults}
                                                totalCount={(this.state.gcsData.resultCountExact === undefined) ? this.state.gcsData.resultCountEstimate : this.state.gcsData.resultCountExact}
                                                onFacetClick={(operatorName, fullItem, descItem) => this.onFacetClick(operatorName, fullItem, descItem)}
                                                faolex_countries={faolex_countries}
                                            />
                                        </div>
                                    </div>
                                </aside> */
            } { /* <div className="clear"></div> */ } </div>
        );
    }
}

export default PubResultsWidget;