import React, {
    Component
} from 'react';
import {
    FormattedMessage
} from 'react-intl';
import $ from 'jquery';


/**
 * Show the sortby component
 * @prop {string} lang the language
 *
 */
export default class SortBy extends Component {
    // TODO: implement  SortBy //

    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidUpdate() 
    {
      
      var this_app={};
      console.log(this_app);
      
   
      //var $ = require( "jquery" );
    }
    
    onClick(e, sortOperator) {

        e.preventDefault();

        if (!this.props.onClick) {
            return;
        }
        $('.sort-active').removeClass('sort-active');
        if (sortOperator==='yearoftext')
        {
            $('.sortby-date').addClass('sort-active') 
        }
        if (sortOperator==='relevance')
        {
            $('.sortby-relevance').addClass('sort-active') 
        }

        this.props.onClick(sortOperator);

    }

    render() {
 

        return (
            
            <div className="sortby-nav">
                <div className="sortby-nav-title">Sort by</div>
                <div className="row sortby_list">
                <div className="sortby-relevance col s7" rel="date:D:L:d1" onClick={(e) => this.onClick(e, "relevance")}>
                       <span className="sortby_title_container"> <FormattedMessage id="SearchApp.labels.sort_by_relevance" />
                       {/* <i class="material-icons">import_export</i> */}
                       </span>
                       
                       
                        </div>


                        <div className="sortby-date col s5" rel="date:D:L:d1" onClick={(e) => this.onClick(e, "yearoftext")}>
                    <span className="sortby_title_container"> <FormattedMessage id="SearchApp.labels.sort_by_date" />
                    {/* <i class="material-icons">date_range</i> */}
                    </span>
                    </div>

           

                </div>
            </div>
        );
    }
}





