const dev = {
    apis: {
        series: "/documents/common/proxygsa/{lang}/?action=autocomplete&domain=series&term={query}&field=name",
        agrovoc: "/documents/common/proxygsa/{lang}/?action=autocomplete&domain=agrovoc&term={query}&field=name",
        meeting: "/documents/common/proxygsa/{lang}/?action=autocomplete&domain=meeting&term={query}&field=name",
        countries: "/fileadmin/templates/faolex-new/js/new_portlex_countries.json",
        rfmo: "/json/RFMO_new.json",
        typeoflegalinst: "/json/typeoflegalinstr.json",
        vesselbyflag: "/json/vesselbyflag.json",
        typeofmeasure: "/json/typeofmeasure.json",
        species: "/json/species.json",
        languages: "/fileadmin/templates/gcs/js/languages.min.js",
        gcs: "https://fao-faolex-dev.appspot.com/api/query",
        countries_desc: "/json/new_portlex_countries.json"
    },
    urls: {
        urlgsaDetails: "/publications/card/",
    },
    filterConfig: [],
    global_vars: {}



    /*
    facetDefaultSettings:[{
      defaultOperator: "seriesid",
      hideFacet: false, 
      values: ["http://aims%2Efao%2Eorg/aos/series/c_202", "http://aims%2Efao%2Eorg/aos/series/c_1008", "urn:fdroa:programme:series:c_6ebe94b2-7931-4194-9fbf-2698a22efcec","http://aims%2Efao%2Eorg/aos/series/c_200","http://aims%2Efao%2Eo…498a-bc37-3eca203fbbc3", "http://aims%2Efao%2Eorg/aos/series/c_1319713174442", "http://aims%2Efao%2Eorg/aos/series/c_380","http://aims%2Efao%2Eorg/aos/series/c_950"]
    }]
    */

};

const prod = {
    apis: {
        series: "/documents/common/proxygsa/{lang}/?action=autocomplete&domain=series&term={query}&field=name",
        agrovoc: "/documents/common/proxygsa/{lang}/?action=autocomplete&domain=agrovoc&term={query}&field=name",
        meeting: "/documents/common/proxygsa/{lang}/?action=autocomplete&domain=meeting&term={query}&field=name",
        countries: "/documents/common/proxygsa/{lang}/?domain=geo%3Aself_governing",
        rfmo: "/json/RFMO_new.json",
        typeoflegalinst: "/json/typeoflegalinstr.json",
        vesselbyflag: "/json/vesselbyflag.json",
        typeofmeasure: "/json/typeofmeasure.json",
        species: "/json/species.json",
        languages: "/fileadmin/templates/gcs/js/languages.min.js",
        gcs: "https://fao-faolex-dev.appspot.com/api/query",
        countries_desc: "/new_portlex_countries.json",
    },
    urls: {
        urlgsaDetails: "/publications/card/",
    },
    filterConfig: [],
    global_vars: {}



};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod' ?
    prod :
    dev;

export default {
    // Add common config values here
    // MAX_ATTACHMENT_SIZE: 5000000,
    ...config,
    PwsSearchApplicationId: "searchapplications/1be285f8874b8c6ba8027fcdb39b28ed"


};