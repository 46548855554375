import React, {
    Component
    
} from 'react';
import {
    FormattedMessage
} from 'react-intl';
 import parse from 'html-react-parser';
import ExpandCollapse from './ExpandCollapse';
import Config from '../../../config';
//import { maxHeaderSize } from 'http';
//import { fireEvent } from 'react-testing-library';
import countryNames from '../../../json/portlex.json';



/**
 * Show an item for the result list
 * @prop {string} lang the language
 *
 */
export default class ResultItem extends Component {


    faolex_countries;

    constructor(props) {
        super(props);
    
       
        this.state = {};
        this.faolex_countries = props.faolex_countries;
        
      //  this.this_query=this.props.this_query;
        
        this.formData=props.formData.formData;
    }

    /**
     * map the doc fields from an array of objects to an objects (key -> value). For better performance and easy to use
     *
     * @param {array(object)} fields the doc fields from the server
     * @returns {object} the fields in the format {key: value}
     */
    mapFields = (fields) => {
        let r = {};

        for (let i = fields.length - 1, f; i >= 0; i--) {
            f = fields[i];
            // if (f.name=='typeOfMeasure')
            // {
              
            //     if (f.textValues)
            //     {
            //         if (f.textValues.values.length>1)
            //         {
            //             console.log(f.textValues)
            //             r[f.name]=f.textValues.values.join(' , ');
            //         }
            //         else 
            //         {
            //             r[f.name]=f.textValues.values;
            //         }
                    
            //     }
            // }
            // else
            // {
                r[f.name] = (f.textValues || f.integerValues).values;
          //  }
                
        }
        console.log("fields mapped")
console.log(r);
        return r;
    }


    /**
     * maps the localizedTranslationURLs to an array
     *
     * @param {object} fields the item's fields from the mapFields()
     * @returns {array(string)} the array of urls
     */
    mapLocalizedTranslationURL = (fields) => {
        let r = [];

        //for (var k in fields) {
        for (var k = 0; k < fields.length; k++) {
            var x = fields[k];

            if (x.indexOf('localizedTranslationURL') === 0) {
                for (var lu in fields[k]) {

                    //let value = fields[k][lu];

                    //let codeL = value.substring(value.length - 2, value.length).toLowerCase();
                    let otl = fields[k][lu];
                    otl = otl.split('/');
                    r.push({
                        // url: Config.urls.urlgsaDetails + lang + '/c/' + otl[otl.length - 1],
                        url: Config.urls.urlgsaDetails + 'en/c/' + otl[otl.length - 1],
                        // lang: codeL,
                        lang: 'en',
                    })
                }
            }
        }

        return r;
    }



    /**
     * capitalize the first letter of a string
     *
     * @param {string} s the string 
     * @returns {string} the string with the uppercase on the first letter
     */
    capitalizeFirstLetter = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }


    /**
     * trims a string and puts the dots
     *
     * @param {string} value the val to trim
     * @param {number} length max char 
     * @returns {string} string trimmed
     */
    wordTrim(value, length) {
        var overflowSuffix = '...';
        if (value.length <= length) return value;
        var strAry = value.split(' ');
        var retLen = strAry[0].length;
        for (var i = 1; i < strAry.length; i++) {
            if (retLen === length || retLen + strAry[i].length + 1 > length) break;
            retLen += strAry[i].length + 1
        }
        return strAry.slice(0, i).join(' ') + (overflowSuffix || '');
    }

    /**
     * create the object with information to show in the template
     *
     * @param {object} fields the item's fields from the mapFields()
     * @param {string} lang the language
     * @returns {object} the object with the information
     */
    buildResultDocrep = (fields, lang) => {


        let r = {},
            capMylang;

        capMylang = this.capitalizeFirstLetter('en');

        if (!fields) {
            return r;
        }

        var lang='en';
     
        r.competenceArea = fields.competenceArea;


        r.countryCode = fields.countryCode;
        // added March 2023 to show competenceAreaRFMO and competence Area Country
        r.competenceRFMO = fields.competenceRfmo

        console.log(fields.competenceCountryCode)   


        for (let i = 0; i < fields.competenceCountryCode.length; i++) {
          countryNames.find(item =>item.country_code  === fields.competenceCountryCode[i]) !== undefined ? 
          r.competenceCountry !== undefined ?
            r.competenceCountry.push(countryNames.find(item =>item.country_code  === fields.competenceCountryCode[i]).en)
            : r.competenceCountry = [countryNames.find(item =>item.country_code  === fields.competenceCountryCode[i]).en] : 
            console.log(r.competenceCountry)
        } 
        console.log(r.competenceCountry);
            
         
        //r.competenceCountry = countryNames.filter(item => fields.competenceCountryCode[0] === item.iso3);
        
   
    

        // Country can be non valorized (in case of RFMO only agreement)
        if (fields.rfmo && fields.rfmo[0]!=='')
        {
            // r.rfmo = fields.rfmo[0]
            r.rfmo ="<span class='big'>"+fields.rfmo[0]+"</span>";
            //fields.countryCode;
        }
        else
        {
            
        if (this.faolex_countries !== undefined) 
        {
          //  console.warn(fields.countryCode,this.faolex_countries[fields.countryCode],lang,fields)
            
            if (fields.countryCode !== undefined ) 
            {
                //console.warn(this.faolex_countries)
                if (fields.countryCode.length === 1 && fields.countryCode[0] !=='')
                {
                    
                    //assuming territorialSubdivision is present when only a country
                    // if (fields.territorialSubdivision)
                    // {
                    //     if (fields.territorialSubdivision=='') 
                    //     {
                    //         fields.territorialSubdivision='Present territorialSubdivision field but empty'
                    //         //.toString().toUpperCase();
                    //     }
                    //    r.territorialSubdivision=fields.territorialSubdivision;
                    // }
                    fields.countryCode=fields.countryCode.toString().toUpperCase();
                    if (this.faolex_countries[fields.countryCode] !== undefined) 
                    {
                            r.iso3=this.faolex_countries[fields.countryCode].iso3;
                        //  console.info(this.faolex_countries[fields.countryCode])
                            //r.countryDesc = this.faolex_countries[fields.countryCode][lang];

                        //  r.countryDesc = "<span class='big'>"+this.faolex_countries[fields.countryCode][lang].toUpperCase()+"</span>";
                        r.countryDesc = "<span class='small'>"+this.faolex_countries[fields.countryCode][lang].toUpperCase()+'</span>';

                        //assuming territorialSubdivision is present when only a country
                        if (fields.territorialSubdivision)
                        {
                            if (fields.territorialSubdivision==='') 
                            {
                              //  r.countryDesc=r.countryDesc+' <span>Present territorialSubdivision field but empty</span>'
                          
                            }
                            else
                            {
                                r.countryDesc=r.countryDesc+' <span>'+fields.territorialSubdivision+'</span>'
                            }
                        
                        }
                            r.countryCode = fields.countryCode.toString().toUpperCase();
                    }
                }
                 else 
                 {
        
                     //if (fields.countryCode.length > 1) {
                    // console.info(fields.countryCode[0])
                    // console.warn(this.faolex_countries)
                    //r.countryDesc = this.faolex_countries[fields.countryCode[0]][lang];

                        //                     countries: Array(1)
                        // 0: {label: 'Albania', value: 'ALB'}
                    var faolex_c=this.props.faolex_countries;
               
                    
                    if (this.props.this_query.countries)
                    {
                    var c_arr=this.props.this_query.countries.map(d=>d.value.toUpperCase())
                    
                 
                    
                    var html='';
                 
                    for(var p in fields.countryIso)
                    {
                        var code=fields.countryCode[p].toUpperCase();
                        var iso=fields.countryIso[p].toUpperCase();
                        
                        if (c_arr.indexOf(iso)>-1)
                        {
                            //ANG,RUS
                            html+="<span class='big'>"+faolex_c[code][lang].toUpperCase()+"</span>";
                          //html+=faolex_c[code][lang].toUpperCase()+"  ";
                        }
                        else
                        {
                            
                            html+= "<span class='small'>"+faolex_c[code][lang].toUpperCase()+"</span>";
                          // html+=faolex_c[code][lang]+"  ";

                        }
                    }
                    
           
                
                    
                    r.countryDesc=html;
                 
                }
                }


            } 
        } 
    }

    if (!fields.mostRelevant)
    
    {
        r.mostRelevant='Not present';
    }
        else
        {
            
            if (fields.mostRelevant==='')
            {
                r.mostRelevant='No';
            }
            else
            {
                r.mostRelevant=fields.mostRelevant;
            }
        }
    
        // name: "inForce"
        // textValues: {values: ["Yes"]}
        if (this.props.this_query.typeofmeasure!==undefined || this.props.this_query.typeofmeasure.length>0)
        {
            var c_arr=this.props.this_query.typeofmeasure.map(d=>d.value)
        
            
            var html='';
            
            var l=fields.typeOfMeasure.map(function(d)
            {
           
            if (c_arr.indexOf(d)>-1)
            {
               html+="<span class='big'>"+d+"</span>";
               //html+=d.toUpperCase()+', ';
            }
            else
            {
                
                html+= "<span class='small'>"+d+"</span>";
               //html+= d+', ';

            }
        
        })

        r.typeOfMeasureDesc=html;
    }
    else
    {
        r.typeOfMeasureDesc=fields.typeOfMeasure[0];
    }
        
      //  r.typeOfMeasure=fields.typeOfMeasure;
        r.dateOfText = fields.dateOfText;
        r.faolexId = fields.faolexId;
        
        if (!fields.inForce)
        {
            console.warn(fields)
            debugger
        }
        if (fields.inForce !== undefined) {
            r.inForce = fields.inForce.includes('Yes') ? 'Yes' : 'No';

        }
        else
        {
            r.inForce = 'Not defined'
        }

        r.isisMfn = fields.isisMfn;

        if (fields.dateOfConsolidation)
        {
            if (fields.dateOfConsolidation[0]!=='')
            r.dateOfConsolidation=fields.dateOfConsolidation[0];
        }
        
        r.measureCitedInFull = fields.measureCitedInFull;
        r.otherReferenceHttp = fields.otherReferenceHttp;

        r.psmaId = fields.psmaId;
        r.recordLanguage = fields.recordLanguage;
        r.referenceOfMeasure = fields.referenceOfMeasure;
        r.speciesCode = fields.speciesCode;
        r.speciesEn = fields.speciesEn;
        r.speciesScientific = fields.speciesScientific;
        r.typeOfLegalInstrument = fields.typeOfLegalInstrument;
      

        if (fields.vesselByFlag !== undefined) {
            r.vesselByFlag = fields.vesselByFlag.join(", ");
        }
        
        
        if (!fields.allAquaticSpecies || fields.allAquaticSpecies[0]==='')
        {
            
            r.allAquaticSpecies=['not defined, faolexid is '+fields.faolexId];
            console.info(fields)
        }
        else
        {
            r.allAquaticSpecies = fields.allAquaticSpecies;
        }

        r.yearOfText = fields.yearOfText;
       
       // r.typeOfMeasure=fields.typeOfMeasure;
        


        return r;
    }

    normalizeUrl(url) {
        if (url !== undefined) {

            if (!url.includes("http://") && !url.includes("https://")) {

                return "https://" + url;

            } else {
                return url;
            }
        }
    }


    render() {
        let fields = {},
            tplInfo = {};
            
            

        fields = this.mapFields(this.props.data.metadata.fields);
        tplInfo = this.buildResultDocrep(fields, this.props.lang);
        console.log("fields");
console.log(tplInfo);
      if (tplInfo.territorialSubdivision)
      console.log(tplInfo.territorialSubdivision)
        // if (tplInfo.inForce=='Yes')
        // return false
        
        

        const resultFormat = (this.props.resultState.resultFormat === null
            || this.props.resultState === undefined)
            ? 'full' : this.props.resultState.resultFormat;

      //  console.log("Result: " + this.props.resultState.resultFormat);
        // if (!tplInfo.faolexId || !tplInfo)
        // {
        //   console.log('faolexId is UNDEFINED!! FOR ',tplInfo)  
        //   return (<div className="z-depth-1 parent-item-container row"><h4>FAOLEX ID is undefined here!</h4>
        //   <div>referenceOfMeasure is ... {tplInfo.referenceOfMeasure}</div></div>)
        // }
        // else
        // {
        return (
            <div className="z-depth-1 parent-item-container row">
                <table>
        <thead>
          <tr>
              <th>
             
                       {
         

                            tplInfo.iso3  && !tplInfo.rfmo ? <img alt="" src={"http://www.fao.org/assets/countries/flags/" + tplInfo.iso3.toLowerCase() + ".gif"}></img> : <span></span>

                        }
                        {/* {
                            tplInfo.rfmo &&
                            <span>RFMO name</span>
                        } */}
             
                        
                        </th>                        
              <th className="country_name">
                   {
                   tplInfo.countryDesc !== undefined && !tplInfo.rfmo ? parse(tplInfo.countryDesc)  : tplInfo.rfmo && parse(tplInfo.rfmo)  

                   
                   
                   }
                   {/* {
                   tplInfo.territorialSubdivision !== undefined && !tplInfo.rfmo && 
                   'Territorial subdivision: '+tplInfo.territorialSubdivision     
                   
                   
                   } */}
                   {
                    //    tplInfo.rfmo && parse(tplInfo.rfmo)  
                   }
                   
                  
                  
                       


              
                        <span className="counter">t</span>
                        </th>

              
          </tr>
        </thead>

        <tbody>
        <tr>        
              <td>Most relevant</td>
              <td>{
              tplInfo.mostRelevant
            }</td>
 </tr>
<tr>
            <td><FormattedMessage id="SearchApp.labels.referenceofmeasure" /></td>
            <td> {
            
                     tplInfo.faolexId &&
                            <a href={"http://www.fao.org/faolex/results/details/en/c/" + tplInfo.faolexId[0] + "/"} rel="noopener noreferrer" target="_blank" >
                                {tplInfo.referenceOfMeasure}</a>
                        }
                        {tplInfo.faolexId === false}
                         {/* &&
                            tplInfo.referenceOfMeasure[0]
                        } */}
                        </td>
            
          </tr>
         
          <tr>
            <td><FormattedMessage id="SearchApp.labels.measurecitedinfull" /></td>
            <td><div className="measure-cited-full">
                                < ExpandCollapse previewHeight="80px">
                                    {tplInfo.measureCitedInFull.map((e) => {
                                        return (<p>{e}</p>)
                                    })}
                                </ExpandCollapse>
                            </div>
            </td>
            
          </tr>
          <tr>
            <td><FormattedMessage id="SearchApp.labels.typeofmeasure" /></td>
            
             <td>{tplInfo.typeOfMeasureDesc !== undefined ? parse(tplInfo.typeOfMeasureDesc) : 'No information'}</td> 
            {/* <td>{tplInfo.typeOfMeasureDesc !== undefined ? tplInfo.typeOfMeasureDesc : 'No information'}</td> */}
            
            
          </tr>

          <tr>
            <td> <FormattedMessage id="SearchApp.labels.typeoflegalinstrument" /></td>
            <td>{tplInfo.typeOfLegalInstrument[0]}</td>

        </tr>
        <tr>
            <td><FormattedMessage id="SearchApp.labels.inforce" /></td>
            <td>{tplInfo.inForce}</td>

        </tr>
        {tplInfo.dateOfText !== undefined && tplInfo.dateOfText !=='' &&
         <tr>
            <td><FormattedMessage id="SearchApp.labels.dateoftext" /></td>
            <td>{tplInfo.dateOfText[0]} 
          {/* { 
        //    tplInfo.dateOfConsolidation !== undefined &&
        //     tplInfo.dateOfConsolidation !=='' &&
            tplInfo.dateOfConsolidation[0]
        } */}
            </td>

        </tr>
        }
         {
            tplInfo.dateOfConsolidation !== undefined &&
            tplInfo.dateOfConsolidation !=='' &&
        // tplInfo.dateOfConsolidation &&
        
            <tr>
            <td><FormattedMessage id="SearchApp.labels.dateOfConsolidation" /></td>
            
            <td> {tplInfo.dateOfConsolidation}</td>

        </tr>
        } 

        {tplInfo.otherReferenceHttp !== undefined &&
    <tr>
            <td><FormattedMessage id="SearchApp.labels.otherreference" /></td>
            <td> <a href={this.normalizeUrl(tplInfo.otherReferenceHttp[0])} target={"_blank"} >{tplInfo.otherReferenceHttp}</a></td>

        </tr>

        }
   
   {/* <FormattedMessage id="SearchApp.PubAdvSearch.form.fields.label.no" /> */}
         <tr>
            <td><FormattedMessage id="SearchApp.labels.allacquaticspecies" /></td>
            <td> 
                                {
                                 tplInfo.allAquaticSpecies[0]}</td>
            
          </tr>

          <tr>
            <td><FormattedMessage id="SearchApp.labels.vesselbyflag" /></td>
            <td>{tplInfo.vesselByFlag}</td>
            
          </tr>
             <tr>
            <td><FormattedMessage id="SearchApp.labels.competencearea" /></td>
            
            <td>{tplInfo.competenceArea !== undefined && tplInfo.competenceArea.length > 0 && tplInfo.competenceArea.map((item, index) =>
                     item + (index < tplInfo.competenceArea.length - 1 ? ", " : ""))}</td>
            
            
          </tr>
          {tplInfo.competenceRFMO !== undefined && tplInfo.competenceRFMO.length > 0 && tplInfo.competenceRFMO[0] !== undefined && 
          tplInfo.competenceRFMO[0] !== "" && 
          <tr>
            <td><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.competencerfmos"/></td>
            <td>{tplInfo.competenceRFMO !== undefined &&  tplInfo.competenceRFMO.length > 0 &&
            tplInfo.competenceRFMO.map((rfmo, ind) => rfmo + (ind < tplInfo.competenceRFMO.length - 1 ? ", " : ""))}</td>
          </tr>}
        
          {tplInfo.competenceCountry !== undefined && tplInfo.competenceCountry.length > 0 && tplInfo.competenceCountry[0] !== undefined && 
          tplInfo.competenceCountry[0] !== "" &&
          <tr>
            <td><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.competencecountries"/></td>
             <td>{tplInfo.competenceCountry.map((item, number) => item + (number < tplInfo.competenceCountry.length - 1 ? ", " : ""))}</td> 
              
          </tr> }

        
          
        </tbody>
      </table>
               
            </div >
        );
  // }
    }
}
