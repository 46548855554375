import React, { Component } from 'react';
// import {
//     FormattedMessage
// } from 'react-intl';
import Config from '../config';
// import this_app.remote_countries from '../json/geo_new_portlex_countries';
// import jsonRFMO from '../json/RFMO_new'

import $ from 'jquery';
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";

import M from 'materialize-css';



//require('../img/list_svg.js');
// require('./jquery.readmore.js');
// import readmore from './jquery.readmore.js';
require('./component.countryProfile.css');
 

var this_app={
    orderby:'yearoftext',
    records_count: 0,
    records: [],
    rows: [],
    simplified_records: [],
    response_params: {
        numRecords: null,
        counts: 0,
        numPages:0
    },
    first_query: true,
    countries:null,
    rfmos:null
};

this_app.fields_to_filter = [
       

    {

        title: 'Type of legal instrument',
        field: 'typeOfLegalInstrument',
        operatorName: 'typeoflegalinstrument',
        true_false_arr: [],
        values: []
    },
    {

        title: 'Type of measure',
        field: 'typeOfMeasure',
        operatorName: 'typeofmeasure',
        true_false_arr: [],
        values: []
    },
    // {

    //     title: 'Year of text',
    //     field: 'yearOfText',
    //     operatorName: 'yearoftext',
    //     true_false_arr: [],
    //     values: []
    // },
    {

        title: 'Country',
        //the field on the data results
        field: 'countryIso',
        //operatorName is used on facetResults
        operatorName: 'country',
        true_false_arr: [],
        values: []
    }
  
];

this_app.fields= [

    {
        pos: 0,
        title: 'Countries',

        //IN RECORD FIELD! (it changes depending on the feature!!)
        field: 'countryIso',

        //IN FACETS!
        operatorName: 'country',
        true_false_arr: []
    }, 
    {
        pos: 1,
        title: 'Territorial Subdivision',

        
        field: 'territorialSubdivision',

        //IN FACETS!
        operatorName: 'territorialSubdivision',
        true_false_arr: []
    }, 
    {
        pos: 2,
        title: 'Reference of measure',
        field: 'referenceOfMeasure',
        true_false_arr: []
    }, 
    {
        pos: 3,
        title: 'Measure cited in full',
        field: 'measureCitedInFull',
        true_false_arr: []
    },
    {
        pos: 4,
        title: 'Type of measure',
        field: 'typeOfMeasure',
        operatorName: 'typeOfMeasure',
        true_false_arr: []
    },
    {
        pos: 5,
        title: 'Type of legal instrument',
        field: 'typeOfLegalInstrument',
        true_false_arr: []
    }, 

    {
        pos: 6,
        title: 'Date of text',
        field: 'dateOfText'
    },
    {
        pos: 7,
        title: 'Last amended date',
        field: 'dateOfConsolidation'
    },
     {
        pos: 8,
        title: 'Other reference',
        field: 'otherReferenceHttp',
        operatorName: 'year',
        true_false_arr: []
    }
    
    , {
        pos: 9,
        title: 'All aquatic species',
        field: 'allAquaticSpecies',
        operatorName: 'allAquaticSpecies',
        true_false_arr: []
    }, {
        pos: 10,
        title: 'Vessel (by flag)',
        field: 'vesselByFlag',
        operatorName: 'vesselByFlag',
        true_false_arr: []
    },
    {
        pos: 11,
        title: 'Competence area',
        field: 'competenceArea',
        operatorName: 'competenceArea',
        true_false_arr: []
    },
 
    {
        pos: 12,
        title: 'In force',
        field: 'inForce',
        operatorName: 'inForce',
        true_false_arr: []
    }
    ,
    {
        pos: 13,
        title: 'faolexId',
        field: 'faolexId',
        operatorName: 'faolexId',
        true_false_arr: []
    },
    {
        pos: 14,
        title: 'Year',
        field: 'yearOfText',
        operatorName: 'yearoftext',
        true_false_arr: [],
        true_false_arr: []
    },
    {
        pos: 15,
        title: 'Most Relevant',
        field: 'mostRelevant',
        operatorName: 'mostrelevant',
        true_false_arr: [],
        true_false_arr: []
    }
    // {
    //     pos: 15,
    //     title: 'RFMO',
    //     field: 'rfmo',
    //     operatorName: 'rfmo'
        
    // }
    


];

var fields_arr = this_app.fields.map(d => d.field);
function create_facets() {
    var q_country=window.location.search.replace('?', '');
    var r = {
        //"query": PubQueryBuilder.createQueryParams(formData, selectedFacets),
        "query": "* country:(\"" + q_country + "\")  inforce:(Yes)",
        //https://developers.google.com/cloud-search/docs/reference/rest/v1/settings.searchapplications  ??
        //https://developers.google.com/cloud-search/docs/reference/rest/v1/query/search#google.apps.search.v1.QueryService.Search
        // "SourceCrowdingConfig":
        // {
        //     "numResults":200
        // },
        "pageSize": 100,

        "requestOptions": {


            "searchApplicationId": Config.PwsSearchApplicationId,
        },
        "facetOptions": [{
                "operatorName": "country",
                "numFacetBuckets": 100
            },
            //  {
            //     "operatorName": "rfmo",
            //     "numFacetBuckets": 100
            // },
            {
                "operatorName": "typeofmeasure",
                "numFacetBuckets": 100
            },
            {
                "operatorName": "typeoflegalinstrument",
                "numFacetBuckets": 100
            },
           

            {
                "operatorName": "allaquaticspecies",
                "numFacetBuckets": 100
            }, {
                "operatorName": "vesselbyflag",
                "numFacetBuckets": 100
            }, {
                "operatorName": "competencearea",
                "numFacetBuckets": 100
            }, {
                "operatorName": "yearoftext",
                "numFacetBuckets": 100
            }
        ],
        "queryInterpretationOptions": {
            "enableVerbatimMode": true
        }
        

    };
  
   console.warn(r)
   return $.ajax({
        type: "POST",
        url: Config.apis.gcs,
        contentType: "text/plain",
        data: JSON.stringify(r)
       
    })
 
 

} 

async function get_records () {
            
    //debugger
    this_app.records=[];
    var q_country=window.location.search.replace('?', '');
    
    var datastring = {
        // 
        "query": "* country:(\"" + q_country + "\") inforce:(Yes)",
        "pageSize":100,
        "requestOptions": {
            "searchApplicationId": "searchapplications/1be285f8874b8c6ba8027fcdb39b28ed"
        },
        "sortOptions" : {
            
            //"operatorName": "yearoftext",                    
            "operatorName":this_app.orderby,
            // "mostrelevant",                    
            "sortOrder": "DESCENDING"
        }
    };
    const responseMR = await fetch(Config.apis.gcs, { method: 'post', 
    headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(datastring) });
    
    var data=responseMR.json();
   
    return data;
    //return responseMR.json();


};

function associated_data(data) {

    //var data=this_app.simplified_records
    console.warn(data,data[0])

    var new_f_arr = [];
    //fields_to_filter??
    var fields_arr = this_app.fields.map(d => d.field)
    for (var x in data) {
        var p2=parseInt(x)
        var o = data[p2];
        o.index = p2;
        console.log(o, index)
        var feature_fields = o.metadata.fields;

        var mapped_feature_fields = o.metadata.fields.filter(d => fields_arr.indexOf(d.name) > -1);
        var sorted_mapped_feature_fields = []
        
        for (var p in fields_arr) {
            for (var s in mapped_feature_fields) {
                if (mapped_feature_fields[s].name === fields_arr[p])
                    sorted_mapped_feature_fields.push(mapped_feature_fields[s])
            }
        }
        //those pushed !== fields_arr
        
        console.info(sorted_mapped_feature_fields,fields_arr)
      




        var f_list = sorted_mapped_feature_fields.map(d => d.name);
        var new_o = { index: parseInt(p), info: [] };
        
        for (var index in sorted_mapped_feature_fields) {

            if (fields_arr.indexOf(sorted_mapped_feature_fields[index].name) > -1) {

                var f = sorted_mapped_feature_fields[index];


                var new_f = {};


                var pos = f_list.indexOf(f.name);


                if (pos > -1) {

                    new_f.field = f.name;


                    if (f.integerValues) {


                        new_f.type = 'integer';

                        new_f.data = f.integerValues;
                    }
                    if (f.textValues) {

                        if (f.name !== 'abstract') {
                            new_f.type = 'text';

                            if (f.textValues.values[0].indexOf('#') > -1) {
                                // if (f.name == 'geographicalArea')
                                //DUPLICATED values for geographicalArea in GCS!!
                                

                                new_f.data = {};
                                var temp_arr = [];
                                var temp_arr_html = [];

                                f.textValues.values.forEach(function(d) {
                                    if (temp_arr.indexOf(d.split('#')[0]) === -1) {
                                        temp_arr.push(d.split('#')[0]);
                                        return d.split('#')[0];
                                    }

                                });

                                new_f.data.values = temp_arr;

                            } else {
                                new_f.data = f.textValues;

                            }




                        }


                    }

                    //<div>Type of text: ' + o.typeOfTextEn + '</div><div>Year: ' + o.year + '</div><div><a target="_blank" href="http://extwprlegs1.fao.org/docs/pdf/' + o.title + '">Document</a></div>';

                }
                new_o.info.push(new_f);
            }
        }
        new_f_arr.push(new_o);
        new_o.index = p;
        this_app.simplified_records.push(new_o);

    }

    console.log(this_app.simplified_records)
}
function exec_filter() {

    console.log(this_app.records_joined)
    for (var x in this_app.filters) {

        //we add here the ones we want to hide!!!
        this_app.filters[x].true_false_arr = []
    }

    var t_f_obj_arr = [];

    console.info(this_app.simplified_records)
    for (var p in this_app.simplified_records) {
        var o = this_app.simplified_records[p];
        o.index = parseInt(p)
            //console.log(o)
        if (p === 4) {
            console.log(o)


        }
        var t_f_obj = { id: p, arr: [] };

        var filters_arr = o.info.map(d => d.field);

        for (var x in this_app.filters) {

            var this_filter = this_app.filters[x];
            if (this_filter.field !== 'abstract') {


                var f = o.info.filter(d => d.field === this_filter.field)[0];


                var f_data = f.data.values;

                //   console.log(filters[x].values,f_data[0])
                console.log(this_filter)
                    //array indexof...

                if (this_filter.values.length === 0) {


                    if (f_data.indexOf(this_filter.values[0]) > -1) {
                        // this_filter.true_false_arr.push('true')
                        
                        this_filter.true_false_arr.push(o.index)
                    } else {

                    }
                } else {


                    //the values of the record contains a value to be hidden??
                    var found = f_data.some(r => this_filter.values.indexOf(r) >= 0);

                    //we found the value in the array of what we wish to hide!
                    if (found) {

                        console.log('YES found ' + o.index + ' ??')
                        this_filter.true_false_arr.push(o.index)
                    }


                }
            }
        }
    }


    // var some_missing = [];
    // for (var i = 0; i < this_app.simplified_records.length; i++) {
    //     for (var p in this_app.filters) {

    //         var t_fs = this_app.filters[p].true_false_arr;
    //         //if (arrs[p].true_false_arr.some(r => this_filter.values.indexOf(r) >= 0);)
    //         if (t_fs.length > 0) {

    //             if (t_fs.indexOf(i) > -1) {
    //                 some_missing.push(i)
    //             }
    //         }
    //     }
    //     // var found = f_data.some(r => this_filter.values.indexOf(r) >= 0);
    // }

    // console.log(some_missing)

    var all_true_false_arr = [];

    console.log(JSON.stringify(this_app.filters))
    for (var x in this_app.filters) {


        //     console.log(this_app.filters[x])
        if (this_app.filters[x].true_false_arr.length > 0) {

            all_true_false_arr.push.apply(all_true_false_arr, this_app.filters[x].true_false_arr);


        }




    }

    console.log(all_true_false_arr);

    var n = all_true_false_arr.reduce(function(memo, feature) {

        var pos = memo['arr'].indexOf(feature);
        if (pos < 0) {
            memo.arr_obj.push({ val: feature, counts: 1 })
                // memo.arr_obj.filter(d=>d.val===feature)[0].counts++

            memo.arr.push(feature)
        } else {

            memo.arr_obj[pos].counts++;
        }
        return memo;

    }, {
        arr: [],
        arr_obj: []
    });

    var f = this_app.filters.filter(d => d.true_false_arr.length > 0);
    var in_all = n.arr_obj.filter(d => d.counts === f.length).map(d => d.val);

    if (f.length===0)
    {
        console.log('no filter, we show all')
        this_app.rows.removeClass('hidden')
        $('.query_results_count_filtered').hide();
    }
    else
    {

        if (in_all.length === 0) 
        {

            console.log('noone to hide, we show all')
            this_app.rows.addClass('hidden')
            // if (all_true_false_arr.length==0)
            // {
            //     this_app.rows.removeClass('hidden')
            // }
            // else
            // {
                
            // }
                //this_app.rows.removeClass('hidden')
        } else {
            this_app.rows.addClass('hidden')
            
            // setTimeout(function() {

            var new_i=0;
                this_app.rows.each(function(i, d) {
                    //$(this).removeClass('hidden')


                    //????'
                    if (in_all.indexOf(i) > -1) {
                        console.warn(new_i+1)
                        new_i++;
                        $(this).find('.badge').text(new_i);
                        $(this).removeClass('hidden')

                    }
                })


        // }, 1500)
        }
      //  $('.query_results_filtered_container').show();
        $('.query_results_count_filtered').show();
        $('.query_results_count_filtered span').text(in_all.length);
        
    }
  
   

    console.warn(JSON.stringify(this_app.filters))
}
function exec_ajax(i, datastring) {

    //i being >1
    datastring.start = (i - 1) * 100;

    
  
    $.ajax({
        type: "POST",
        url: Config.apis.gcs,
        contentType: "text/plain",
        data: JSON.stringify(datastring),
        success: function(data) {
            this_app.records.push(data.results)
            console.log(i,this_app.response_params.numPages)
            if (i === this_app.response_params.numPages) {

              
                console.log(this_app.records)
                this_app.records_joined = [].concat.apply([], this_app.records);
                associated_data(this_app.records_joined)
                fill_elem_results(this_app.records_joined)
                
            
        }

        }
    })





};

function fill_elem_results(data)
{
       
//var data=this_app.simplified_records;
      var all_html='';
      var counter=1;
      for (var p in data)
      {
        if (data[p])
        {
        var o=data[p];

    
        console.warn(o)
        var table_html='<div class="z-depth-1 parent-item-container row"><table><thead></thead><tbody>'
        
        var feature_fields = o.metadata.fields;

        //DATA DOES NOT FOLLOW A LOGIC ON GCS!!! and there can be missing fields!
        //var fields_arr = this_app.fields.map(d => d.field);
        
        var mapped_feature_fields = o.metadata.fields.filter(d => fields_arr.indexOf(d.name) > -1);
        var sorted_mapped_feature_fields = []

      
        for (var p in fields_arr) {
            for (var s in mapped_feature_fields) {
                if (mapped_feature_fields[s].name === fields_arr[p])
                    sorted_mapped_feature_fields.push(mapped_feature_fields[s])
            }
        }

        if (sorted_mapped_feature_fields.length!==fields_arr.length)
        {
            
         
            var arr=sorted_mapped_feature_fields.map(d=>d.name)
            for (var p in fields_arr) {

                if (arr.indexOf(fields_arr[p])===-1)
                {
                    var missing_f=this_app.fields.filter(d=>d.field===fields_arr[p])[0];
                    console.info(missing_f)
                    sorted_mapped_feature_fields.splice(missing_f.pos,0,{
                        name:missing_f.field,
                        textValues:{values:['No information available in the record']},
                        no_info:true
                    })
                    
                }
            }
        }

        var f_list = sorted_mapped_feature_fields.map(d => d.name);
        var new_o = { index: this_app.records_count, info: [] };


        var iso3_codes=this_app.remote_countries.map(function(d)
        {
            if (d.iso3)
            {
                return d.iso3
            }
            else
            {
                
                return d.country_code
            }
        })
            //d=>d.iso3);
 
        for (var index in sorted_mapped_feature_fields) {

            if (fields_arr.indexOf(sorted_mapped_feature_fields[index].name) > -1) {

                var f = sorted_mapped_feature_fields[index];
            //  if (f.name=='territorialSubdivision')
            
                if (f.name!=='faolexId')
                var new_f = {};


                var pos = f_list.indexOf(f.name);

                
                        if (pos > -1) 
                        {
                            if (f.name!=='faolexId')
                            {

                           
                            // if (!this_app.fields[pos])
                            
                            // var title=this_app.fields[pos].title;
                            
                            
                            f.title=this_app.fields[pos].title;

                                        new_f.field = f.name;
                                        
                                                if (f.integerValues) {


                                                    new_f.type = 'integer';

                                                    new_f.data = f.integerValues;

                                                    var t = f.integerValues.values.map(d => '<span>' + d + '</span>').join('');
                                                }
                                    if (f.textValues) 
                                    {
                                        if (f.name!=='faolexId')
                                        {                                                    
                                            new_f.type = 'text';
                                            //multiple values
                                        
                                                new_f.data = f.textValues;
                                            
                                                if (f.name==='countryIso')
                                                {
                                                    

                                             
                                                    if (new_f.data.values && new_f.data.values.length>1)
                                                    {
                                                    
                                                        
                                                        var t=new_f.data.values.map(function(d)
                                                        {
                                                            
                                                            var pos=iso3_codes.indexOf(d);
                                                            console.log(pos,this_app.remote_countries[pos])
                                                            return this_app.remote_countries[pos].en;
                                                        }).join(' ; ')
                                                    }
                                                    else
                                                    {
                                                        if (!new_f.data.values)
                                                        debugger
                                                        var pos=iso3_codes.indexOf(new_f.data.values[0]);
                                                        if(!this_app.remote_countries[pos].en)
                                                        debugger

                                                        var t= this_app.remote_countries[pos].en;
                                                    }
                                                    
                                    
                                                //     console.log(this_app.remote_countries)
                                                //    if (f.textValues.values.length>1)
                                                //    {
                                                
                                                //    }
                                                //     for (var i = 0; i < this_app.remote_countries.length; i++) {
                                                //         if (this_app.remote_countries[i].iso3 === f.textValues.values[0]) 
                                                //         {
                                                //             var t = this_app.remote_countries[i].en;
                                                //         }
                                                //     }
                                                }
                                                else
                                                {
                                                    
                                                
                                                        if (f.name === 'referenceOfMeasure') 
                                                        {
                                                            var id_pos = f_list.indexOf('faolexId');
                                                            
                                                            // faolexId"
                                                            // textValues: {values: ["LEX-FAOC174674"]}
                                                            // values: ["LEX-FAOC174674"]
                                                            if (id_pos>-1)
                                                            {
                                                                if (!f.no_info)
                                                                var t = '<div class="title"><a href="https://www.fao.org/faolex/results/details/en/c/' + sorted_mapped_feature_fields[id_pos].textValues.values[0] + '" rel="" target="_blank">' + f.textValues.values[0]+ '</a></div>'
                                                                else
                                                                var t = '<div class="title"> '+ f.textValues.values[0]+ '</div>'
                                                            }
                                                            else
                                                            {
                                                                //var t='No faolexId available';
                                                                var t='<div class="title">'+f.textValues.values[0]+'</div>';
                                                            }
                                                            
                                                        } else 
                                                        {

                                                            if (f.name === 'measureCitedInFull') {
                                                                var t = '<div class="measureCitedInFull">'
                                                                for (var p in  f.textValues.values) 
                                                                {
                                                                    var d=f.textValues.values[p];
                                                                    t+= '<span>' + d + '</span>';
                                                                }
                                                                t+='</div>';
                                                                
                                                                
                                                            }
                                                        
                                                            if (f.name!=='faolexId')
                                                            {
                                                                
                                                            
                                                            // if (f.name === 'abstract') {
                                                            //     var t = '<div class="abstract">' + f.textValues.values.join(',') + '</div>';
                                                            // } else {


                                                        // if (f.textValues.values[0].indexOf('#') > -1) 
                                                        
                                                                var info = f.textValues.values;
                                                                

                                                                if (info.length === 1) 
                                                                {

                                                                   
                                                                    if (f.name === 'otherReferenceHttp') 
                                                                    {
                                                                        if (info[0]==='')
                                                                        {
                                                                            var url='';
                                                                        }
                                                                        else

                                                                        {
                                                                            if (info[0].indexOf('http')===-1)
                                                                            {
                                                                                var url='https://'+info[0];
                                                                            }
                                                                            else
                                                                            {
                                                                                var url=info[0];
                                                                            }
                                                                        }

                                                                        if (!f.no_info)
                                                                        var t='<span  class="sel"><a href="'+url+'" target="_blank">'+url+'</a></span>';
                                                                        else
                                                                        var t='<span  class="sel">'+url+'</span>';

                                                                    }
                                                                    else
                                                                            {
                                                                                if (f.name === 'territorialSubdivision')
                                                                                {
                                                                                    
                                                                                    if (info[0]!=='') 
                                                                                    {
                                                                                        
                                                                                    var t= '<span class="sel">'+info[0]+'</span>';

                                                                                    }
                                                                                    else
                                                                                    {
                                                                                        var t='';
                                                                                    }
                                                                                }
                                                                                else
                                                                                {

                                                                                    if (f.name==='dateOfConsolidation')
                                                                                    {
                                                                                        //do not plot it
                                                                                        if (info[0]!=='')
                                                                                        var t= '<span>' + info[0] + '</span>';
                                                                                        else
                                                                                        var t='';
                                                                                    }
                                                                                    else
                                                                                    {
                                                                                        if (f.name==='mostRelevant')
                                                                                        {
                                                                                            if (info[0]==='' || info[0]==='No')
                                                                                            var t = '<span class="sel">No</span>';
                                                                                            else
                                                                                            var t = '<span class="sel">Yes</span>';
                                                                                        }
                                                                                        else
                                                                                        {
                                                                                            var t = '<span class="sel">' + info[0] + '</span>';
                                                                                        }
                                                                                    
                                                                                    }
                                                                                }
                                                                                
                                                                            }
                                                                        

                                                                } else {

                                                                    if (info.length> 1) 
                                                                    {
                                                                    var t = info.map(function(d) {
                                                                        if (f.name === 'otherReferenceHttp') 
                                                                        {
                                                                            if (!f.no_info)
                                                                            return '<span  class="sel"><a href="'+info[0]+'" target="_blank">'+info[0]+'</a></span>';
                                                                        else
                                                                        return '<span  class="sel">'+info[0]+'</span>';

                                                                            
                                                                        }
                                                                        else
                                                                        {
                                                                            
                                                                            // if (f.name === 'territorialSubdivision')
                                                                            // {
                                                                              
                                                                            // if (info[0]!=='') 
                                                                            // return '<span class="sel">'+info[0]+'</span>';
                                                                            // }

                                                                            // else
                                                                            // {
                                                                                return '<span>' + d + '</span>';
                                                                                
                                                                            //}
                                                                        }
                                                                            


                                                                    })
                                                                    .join(' - ')
                                                                }
                                                                }

                                                            
                                                        }
                                                            


                                                        }
                                            }
                                   } 
                                }
                                
                                
                                else {
                                   console.warn('not in list '+f.name)
                                    
                                }
                                if (f.name!=='faolexId' || f.name!=='yearoftext')
                                {
                                    // if (f.name === 'territorialSubdivision')
                                    
                                    // if (f.name === 'dateOfConsolidation' && t=='')    
                                    // table_html+=''
                                    // else              
                                    // table_html+='<tr><td class="td_title">' + f.title + '</td><td>' + t + '</td></tr>';

                                   

                                    if (f.name === 'territorialSubdivision' && t==='')    
                                    {
                                        table_html+=''

                                       
                                    }
                                    
                                    else              
                                    {
                                        
                                        if (f.name === 'dateOfConsolidation' && t==='')   
                                        {
                                            table_html+=''
                                        }
                                        
                                        else
                                        {
                                            if (f.name === 'countryIso')
                                            //<span class="col s11">' + t + '</span><span data-badge-caption="" class="my_blue badge">'+counter+'</span>
                                            table_html+='<tr><td class="td_title">' + f.title + '</td><td><span>'+t+'</span></td></tr>';
                                            else
                                            table_html+='<tr><td class="td_title">' + f.title + '</td><td>' + t + '</td></tr>';
                                        }
                                        
                                    }
                                    

                                // if (f.name === 'territorialSubdivision' && info[0]!=='')    
                                // table_html+=''
                                // else                                                                                                                                                                                                                                                                                     
                                // table_html+='<td class="td_title">' + f.title + '</td><td>' + t + '</td></tr>';
                               }
                            }
                        }

                            
                            
                            //html += '<div><a target="_blank" href="http://extwprlegs1.fao.org/docs/pdf/' + o.title + '">' + o.title + '</a></div></div>';

                }

                new_o.info.push(new_f);
            }
            table_html+='</tbody></table></div>';
            all_html+=table_html;
        }
    counter++;
    }
    if (all_html==='')
    {
        var html='<h4>No records</h4>'
    }
    $('.result-list').html(all_html);
} 


function search()
{
    this_app.simplified_records=[];
    get_records().then(function(data)
        {
            
            console.log(data)
            
            create_facets().then(function(facets_all_data)
            {
                $('.query_results_count_wrapper').show();
                var facets_data=facets_all_data.facetResults;
                
                
                var o=this_app.fields_to_filter.map(d=>d.operatorName).reverse();
                var facets_data_sorted=[];

                for (var p in o)
                {
                    //ex:typeofmeasure
                    var name=o[p];
                    facets_data_sorted.push(facets_data.filter(d=>d.operatorName===name)[0])

                }
                console.warn(facets_data_sorted)
              //  var facets_data_sorted=facets_data.filter(d=>o.indexOf(d.operatorName)>-1)

                $('.facet-nav .content').empty();
                    //$('body').busyLoad("hide");
                    //var iso3_codes=this_app.remote_countries.map(d=>d.iso3);
                    var iso3_codes=this_app.remote_countries.map(function(d)
                    {
                        if (d.iso3)
                        {
                            return d.iso3
                        }
                        else
                        {
                            
                            return d.country_code
                        }
                    })

                    for (var p in facets_data_sorted) {
                        
                        var o = facets_data_sorted[p];
                        
                        var field_in_data = this_app.fields_to_filter.filter(d => d.operatorName === o.operatorName)[0];
                        if (field_in_data) {

                            var html = '<ul field="' + field_in_data.field + '" class="collapsible ' + o.operatorName + ' valign-wrapper"> <li> <div class="collapsible-header valign-wrapper"><span class="col s10">' + field_in_data.title + '</span><span class="col s2"><i class="material-icons up_down">keyboard_arrow_down</i></span></div> <div class="collapsible-body"> <ul>';
                            for (var x in o.buckets) {
                                var obj = o.buckets[x];
                                if (obj.value.stringValue) {
                                    if (obj.value.stringValue.includes('#')) {
                                        //html += '<li>' + obj.value.stringValue.split('#')[0] + ' counts ' + obj.count + '</li>'
                                        html += '<li class="row valign-wrapper"><span class="col s10">' + obj.value.stringValue.split('#')[0] + '</span><span data-badge-caption="" class="col s2 my_blue badge"> ' + obj.count + '</span></li>';
                                    } else {
                                        if (o.operatorName==='country')
                                        {
                                            
                                            var pos=iso3_codes.indexOf(obj.value.stringValue);
                                            var t= this_app.remote_countries[pos].en;
                                            html += '<li class="row valign-wrapper"><span iso3="' + obj.value.stringValue + '" class="col s10">' + t+ '</span><span data-badge-caption="" class="col s2 my_blue badge"> ' + obj.count + '</span></li>';
                                        }
                                        else

                                        {
                                            html += '<li class="row valign-wrapper"><span class="col s10">' + obj.value.stringValue + '</span><span data-badge-caption="" class="col s2 my_blue badge"> ' + obj.count + '</span></li>';
                                        }
                                        //html += '<li>' + obj.value.stringValue + ' counts ' + obj.count + '</li>';
                                        
                                    }
                                } else {

                                    html += '<li class="row valign-wrapper"><span class="col s10">' + obj.value.integerValue + '</span><span data-badge-caption="" class="col s2 my_blue badge"> ' + obj.count + '</span></li>';

                                }
                                // html+='<li>'+html+'</li>'
                            }
                            html += '</ul> </div> </li> </ul>';
                            console.info(html);
                            $('.facet-nav .content').append(html);
                        }
                    }
            }).then(function()
            {
                if ($('.facet-nav .collapsible').length>0)
                {
                    $('.facet-nav').show();
                   var elems = document.querySelectorAll('.facet-nav .collapsible');
    
                    console.log(elems.length+' collapsibles from component.countryprofile.js')
                    
                    var instances = M.Collapsible.init(elems);

                    $('.facet-nav .collapsible-header').each(function()
                    {
                        $(this).trigger('click')
                    })
                    var filters = [];
                    $('.facet-nav .collapsible-body').on('click', function(e) {
        
                        if (this_app.rows.length === 0) {
                            this_app.rows = $('.result-list .parent-item-container');
                        }
        
                        console.log($(e.target))
                        if ($(e.target).hasClass('valign-wrapper') || $(e.target).parents().closest('.valign-wrapper').length > 0) {
                            //$(e.target).hasClass('valign-wrapper') ||
                            if ($(e.target).hasClass('valign-wrapper'))
                                var row = $(e.target);
                            else
                                var row = $(e.target).parents().closest('.row.valign-wrapper');
        
                            
                            //countryEn
                            var field = $(this).parents().closest('.collapsible').attr('field');

                            if (field === 'countryIso') {

                                var val = row.find('.s10').attr('iso3');
                                console.warn(val)
                            } else {
                                var val = row.find('.s10').text();
                            }
        
                            var f = filters.filter(d => d.field === field)[0];
                            
                            console.log(this_app.fields_to_filter, field, f)
                            if (!f) {
                                var field = this_app.fields_to_filter.filter(d => d.field == field)[0];
        
                                var cloned_field = Object.assign({}, field);
                                cloned_field.values = [val]
        
                                filters.push(cloned_field)
                            } else {
                                if (row.hasClass('inactive')) {
        
                                    var pos = f.values.indexOf(val);
                                    
                                    f.values.splice(pos, 1);
                                    
                                } else {
                                    //filters.filter(d => d.field == field)[0]
                                    
                                    f.values.push(val)
                                }
        
                            }
        
                            row.toggleClass('inactive');
        
                            console.warn(filters);
        
                            this_app.filters = filters;
                            console.warn(this_app.filters)
                            exec_filter()
                        } else {
        
                        }
        
                    })


                }
            })
            
            this_app.records.push.apply(this_app.records, data.results);
            
        
       
            
            if (data.resultCountExact)
            {
                var counts=parseInt(data.resultCountExact);
            }
            else
            {
                if (data.resultCountEstimate)
                var counts=parseInt(data.resultCountEstimate);
            }
            

            $('.query_results_count span').text(counts);
            
           

            if (counts>0)
            {
                $('.facet-nav').show();
                this_app.response_params.numPages =
                Math.ceil(counts / 100);


                if (this_app.response_params.numPages>1)
                {
                   // alert(this_app.response_params.numPages)
                    var q_country=window.location.search.replace('?', '');
                    var datastring = {
                        "query": "* country:(\"" + q_country + "\") inforce:(Yes)",
                        "pageSize":100,
                        "requestOptions": {
                            "searchApplicationId": "searchapplications/1be285f8874b8c6ba8027fcdb39b28ed"
                        },
                        "sortOptions" : {
                
                            "operatorName": "yearoftext",                    
                            "sortOrder": "DESCENDING"
                        }
                    };

                    
                    for (var i = 2; i <= parseInt(this_app.response_params.numPages); i++) {
                        
                        exec_ajax(i, datastring)
            
                    }
                }
                else
                {
                    associated_data(this_app.records)
                    fill_elem_results(data.results)
                }
            }    
            else
            
            {
                $('.facet-nav').hide();
                $('query_results_count span').text(counts);
                console.warn('treaties_div to be hidden')
                //$('.treaties_div').hide();
                
                
            }
         
        })
}

class CountryProfile extends Component {

  

    constructor(props) {
        super(props);
       
        
        var $ = require( "jquery" );
  

        $('body').on('click',function(e)
        {
            //alert('clicked')
            
            console.warn(e.target)
            var target=$(e.target);
             //not proper way but first time on radio was not changing...
             //$('#order_form input').on('click',function()
             if (target.is('input'))
             {                        
                 this_app.orderby=$(e.target).prop('value');
                 $('#order_form').find('input:checked').each(function()
                 {
                     
                     if ($(this).attr('value')!==this_app.orderby)
                     {
                        
                         $(this).prop('checked',false)
                     }
                     else
                     {
                         $(this).prop('checked',true)
                     }
                     
                 })
                
                 search();
            }

            if (target.hasClass('download_pdf'))
            {
                //not optimal...
                var pdfMake=window.pdfMake;
                pdfMake.tableLayouts = {
                    exampleLayout: {
                        hLineWidth: function (i, node) {
                            if (i===3)
                            console.warn(node)
                            if (i===0)
                            {
                            return 0.1
                            }
                            else
                            {
                                if (i===node.table.body.length)
                                return 0.1
                                else
                            return 0
                            }
                        
                        // return (i === node.table.headerRows) ? 4 : 0.1;
                        },
                        vLineWidth: function (i) {
                        return 1;
                        },
                        hLineColor:'#b3afb7',
                        vLineColor:'#b3afb7',
                        paddingLeft: function (i) {
                        return i === 0 ? 4 : 8;
                        },
                        paddingRight: function (i, node) {
                        return i === 0 ? 4 : 8;
                        }
                    }
                    };
                    pdfMake.fonts = {
        
                    // download default Roboto font from cdnjs.com
                    Roboto: {
                        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
                    },
                    OpenSans:
                    {
                        normal:'https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0B4gaVQUwaEQbjA.woff',
                        OpenSansBolded:'https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0B4gaVQUwaEQbjA.woff',
                        OpenSansItalic:'https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVQUwaEQbjB_mQ.woff'
                    }
                };
                    var json_pdf={
                        pageMargins: [40, 10, 40, 60],
                        footer: function(currentPage, pageCount) {
                        return {
                        table: {
                            //layout: 'headerLineOnly',
                            widths: '*',
                            body: [
                                [{
                                    border: [false, true, false, false],
                                    text: "Page " + currentPage.toString() + ' of ' + pageCount,
                                    alignment: 'right',
                                    style: 'normalText',
                                    //[left, top, right, bottom] 
                                    margin: [0, 20, 50, 0],
            
                                }]
                            ]
                                },
                                layout: 'noBorders'
                            };
                        },
                        styles:  {
                            portlex: { color: '#737f91', "fontSize": 10, "margin": [0, 8, 0, 5] },
                            date: { "fontSize": 8, "margin": [0, 15, 0, 5] },
                            country: {
                                fontSize: 16,
                                alignment:'center',
                                OpenSansBolded:true
                                // bold: true
                            },
                            header: {
                                fontSize: 11,
                                OpenSansBolded:true,
                                // bold: true,
                                // margin: [left, top, right, bottom]
                                margin: [0, 10, 0, 0],
                                //darker blue
                                color: '#4e4d4f'
                    
                    
                                
                                    // bold: true
                            },
                            link: {
                                fontSize: 8,
                                margin: [0, 5, 0, 0],
                                color: '#002a66',
                                OpenSansItalic: true
                            },
                        
                            table_style: {
                                
                                margin: [0, 5, 0, 5]
                    
                    
                            },
                            measurecitedinfull: {
                                // margin: [left, top, right, bottom]
                                fontSize: 8,
                                margin: [0, 3, 0, 3],
                                "font": "OpenSans",
                                
                                color: '#4e4d4f'
                            },
                            td_title: {
                                // margin: [left, top, right, bottom]
                                fontSize: 9,
                                margin: [0, 0, 0, 0],
                                "font": "OpenSans",
                                //darker
                                color: '#4e4d4f'
                            },
                            
                    
                    
                        },
                        defaultStyle: {
                            // margin: [left, top, right, bottom]
                            fontSize: 8,
                            margin: [0, 0, 0, 5],
                            "font": "OpenSans",
                            //greyish
                            color: '#4e4d4f'
                        },
                        content:[]
                    };
                
                
            
                    function toDataURL(url, callback) {
                        console.log(url)
                        var xhr = new XMLHttpRequest();
                        xhr.onload = function() {
                            var reader = new FileReader();
                            reader.onloadend = function() {
                                callback(reader.result);
                            }
                            reader.readAsDataURL(xhr.response);
                        };
                        xhr.open('GET', url);
                        xhr.responseType = 'blob';
                        xhr.send();
                    }

                    
                    
                    json_pdf.content.push({ "text": 'PORTLEX', style: 'portlex', alignment: 'center' })
                    json_pdf.content.push({text:$('.c-right-item').text(),style:'country'});
                    
                    json_pdf.content.push({text:'International commitments',style:'header'});
                    $('.treaties >div a:visible').each(function()
                    {
                        json_pdf.content.push({
                            style: 'link',
                            text: $(this).text(),
                            link: $(this).attr('href')
                        })
                    });
        
        
                    json_pdf.content.push({text:'National Plan Of Action',style:'header'});
                    if ($('.collapsible.national_plans .collapsible-body a').length>0)
                    {
        
                    
                    $('.collapsible.national_plans .collapsible-body a').each(function()
                    {
                        json_pdf.content.push({
                            style: 'link',
                            text: $(this).text(),
                            link: $(this).attr('href')
                        })
                    });
                    }
                    else
                    {
                        json_pdf.content.push('No national plants')
                    }
        
                    json_pdf.content.push({text:'Most relevant legislation',style:'header'});
                    if ($('.collapsible.most_relevant .collapsible-body a').length>0)
                    {
        
                    $('.collapsible.most_relevant .collapsible-body a').each(function()
                    {
                        json_pdf.content.push({
                            style: 'link',
                            text: $(this).text(),
                            link: $(this).attr('href')
                        })
                    });
        
                    }
                    else
                    {
                        json_pdf.content.push('No national plans')
                    }
                    
                    json_pdf.content.push({text:$('.result-list table:visible').length+' records',style:'header'});
                    $('.result-list table:visible').each(function(i,d)
                    {
                        var table_info = {
                        style: 'table_style',
                        layout:'exampleLayout',
                        table: {
                            body:[],
                            // headerRows: 1,
                            // widths: ['*', '*'],
                            widths: ['30%', '70%'],
                            
                        },
                        //layout: 'lightHorizontalLines',
                        // layout: {
                        //     fillColor: function(rowIndex, node, columnIndex) {
                        //         return (rowIndex % 2 === 0) ? '#cec9c9' : null;
                        //     }
                        // }
                        
                    }
    
                
                    var th=$(this).find('thead')    
                
                    $(this).find('tbody tr').each(function(i,d)
                    {
                        var  o=[];
                        var $tr=$(d);
                        
                        $(d).find('td').each(function(i2,d2)
                        {
                            var $td=$(d2);
        
                            if (i2===0)
                            {
                                o.push({text:$(d2).text(),style:'td_title'})
                            }
                            
                            if (i2===1)
                            {
                                //we are on data, not on label
                                if (i===2)
                                {
                                    //second i, second tr (measurecitedinfull)
                                    
                                    var o2=[]
                                    //measurecitedinfull
                                    $td.find('span').each(function(i3,d3)
                                    {
                                        var t=$(d3).text();
                                        o2.push({text:t,style:'measurecitedinfull'})
                                        
                                    
                                    })
                                    
                                    o.push(o2)
        
                                }
                                else
                                {
                                    if (i===6)
                                    {
                                        //otherReferenceHttp
        
                                        o.push({
                                            style: 'link',
                                            text: $td.text(),
                                            link: $td.find('a').attr('href')
                                        })
        
                                    }
                                    else
                                    {
                                        if (i===1)
                                        {
                                            var link=$td.find('a').attr('href')
                                            console.log(link)
                                            
            
                                            o.push({
                                                style: 'link',
                                                text: $td.text(),
                                                link: link
                                            })
                                            //o.push($td.text())
                                        }
                                        else
                                        {
                                            o.push($td.text())
                                        }
                                        
                                    }
                                    
                                }
                                //if ($('.react-expand-collapse__content').not('.react-expand-collapse--expanded'))
                            // o.content=$(d2).text();
                                
                                
                                table_info.table.body.push(o)
                            }
                            
                        })
                        
                    
                    })
        
                    json_pdf.content.push(table_info);
                
    
                });
        
        
                var e = [
        
                    {
                        columns: [{
                                svg: window.fao_svg,
                                //svg: zm
                                width: 140,
                                height: 45
                                    // height: 50
                            },
            
                            {
                                "text": 'Created on ' + new Date().toLocaleDateString("en-EU"),
                                style: 'date',
                                alignment: 'right'
                            }
            
                        ]
                    }
                ];
                for (var p in e) {
        
                    json_pdf.content.unshift(e[p])
                }
                console.log(JSON.stringify(json_pdf))
                var country_name=$('.c-right-item').text().replace('.',' ')
                var c=country_name.split(' ');
                if (c.length>0)
                {
                    
                    //  pdfMake.createPdf(json_pdf).download(country_name);
                    pdfMake.createPdf(json_pdf).download(c.join('_'));
                }
                else
                {
                    pdfMake.createPdf(json_pdf).download(country_name);    
                }
            }    
            //end  if (target.hasClass('download_pdf'))
                                
        });

        $('.facet-nav').hide();
        let self = this;
 
        this.testCountryProfile =
        {
            "metadata": {
                "objectType": "document",
                "fields": [
                    {
                        "name": "header",
                        "textValues": {
                            "values": [
                                {
                                    "name": "PSMA",
                                    "link": "http://www.fao.org/treaties/results/details/en/c/TRE-000003/",
                                },
                                {
                                    "name": "UN Convention on the Law of the Sea (UNCLOS)",
                                    "link": "https://treaties.un.org/Pages/ViewDetailsIII.aspx?src=TREATY&mtdsg_no=XXI-6&chapter=21&Temp=mtdsg3&clang=_en"
                                },
                                {
                                    "name": "UN Fish Stocks Agreement (UNFSA)",
                                    "link": "https://treaties.un.org/Pages/ViewDetails.aspx?src=TREATY&mtdsg_no=XXI-7&chapter=21&clang=_en"
                                },
                                {
                                    "name": "FAO Compliance Agreement",
                                    "link": "http://www.fao.org/treaties/results/details/en/c/TRE-000023/"
                                }

                            ]
                        }
                    },

                ]
            },
            "title": "CountryProfileHeader"
        };

       
        this.countries = [];
        this.records_result=null;
        this.state = {
            value: "",
            isLoaded: false,
            itemsMR: [],
            itemsNP: [],
            itemPC: false,
            countryName: '',
            countryISO3: window.location.search.replace('?', ''),
            TOMprofile: [],
            typeofmeasure: [],
            remote_countries:[],
            remote_rfmos:[],
            remote_internationalCommittments: [],
        };
        this.TOMprofile = '';
        this.isPartecipant = null;


        this.search = this.search.bind(this);
        this.reset = this.reset.bind(this);
        
        this.loading = true;
    }


    componentDidUpdate(prevProps, prevState) {
        console.warn('comonentupdated more than once...')

        var arr=['rfmo','national_plans','most_relevant'];
        for (var p in arr)
        {
            console.log(arr[p])
            console.warn($('.collapsible.'+arr[p]))
            var l=$('.collapsible.'+arr[p]).find('.collapsible-body span').length;
            console.warn(l)
            $('.collapsible.'+arr[p]).find('.badge').attr('data-badge-caption',l);
        }

    
        // var elems = document.querySelectorAll('.stats_collection .collapsible');
        var elems = document.querySelectorAll('.collapsible');
        
        console.log(elems.length+' collapsibles from component.countryprofile.js')
        
        var instances = M.Collapsible.init(elems);

        // $('.download_pdf').click(function()
        //                 {
        //                     alert('NEW download country pdf')

        //                 })
    }
    
    async componentDidMount() {

       


        var $ = require( "jquery" );
        M.AutoInit();
        

        fetch('https://faolex.fao.org/docs/json/PortlexCP.json')

        .then(response => response.json())
        .then(json => {
            this_app.remote_countries=json.document.filter(d=>d.country_code);
          
          this.setState({ remote_countries: json.document.filter(d=>d.country_code) });
          this.setState({ remote_internationalCommittments: json.document });
          console.log("jsoooooooon" + json)
          console.log(this.state.remote_internationalCommittments);
          var x = this_app.remote_countries;
          var countryName;
          for (var i = 0; i < x.length; i++) {
              if (x[i].iso3)
              {
              if (x[i].iso3 === this.state.countryISO3) {
                  countryName = x[i].en;
                  this.setState({ countryName: countryName }, function () {
                      console.log(this.state.countryName);
                  });
  
              }
          }
          else
  
          {
              if (x[i].country_code === this.state.countryISO3) {
                  countryName = x[i].en;
                  this.setState({ countryName: countryName }, function () {
                      console.log(this.state.countryName);
                  });
  
              }
          }
  
  
          }

          search();

          var elems = document.querySelectorAll('.collapsible');
        
          console.log(elems.length+' collapsibles from component.countryprofile.js')
          
           
                var instances = M.Collapsible.init(elems);
                setTimeout(function()
                {
                    $('.busy-load-container').remove();
                },1500)
                
                $('.sidenav_react_buttons .collapsible-header.active').removeClass('active');
                $('.sidenav_react_buttons .collapsible-header.cp').addClass('active');                                
                        
                var _this=this;
                
        
          
            
            
        
       
          
            
                this.countries.push(this.state.countryISO3);
        });

        // var countries_dataFromApi =  response.json();
        // this.setState({countries:countries_dataFromApi.document});

        fetch('https://faolex.fao.org/docs/json/RFMO.json')

        .then(response => response.json())
        .then(json => {
            
            console.log(json.document)
            this_app.rfmos=json.document.filter(d=>d.code);
          this.setState({ remote_rfmos: json.document.filter(d=>d.code) });
        });


       

       


     


        // ###################  PARTECIPANT COUNTRY QUERY ################################ 
        const partecipantCountryService = async () => {
            var reqPC = {
                "query": "* treatyId:TRE-000003",
                "requestOptions": {
                    "searchApplicationId": "searchapplications/1be285f8874b8c6b2c3ab405344c95ee"
                },
                "facetOptions": [
                    { "operatorName": "pariso", "numFacetBuckets": 100 },
                    { "operatorName": "typeofinstrumenten", "numFacetBuckets": 100 },
                    { "operatorName": "subjecten", "numFacetBuckets": 100 },
                    { "operatorName": "yeardateadopted", "numFacetBuckets": 100 },
                    { "operatorName": "yearentryintoforce", "numFacetBuckets": 100 },
                    { "operatorName": "treatytypeen", "numFacetBuckets": 100 },
                    { "operatorName": "clspariso", "numFacetBuckets": 100 }
                ],
                "sortOptions": {
                    "operatorName": "yeardateadopted",
                    "sortOrder": "DESCENDING"
                },
                "start": 0
            }

            const responseMR = await fetch(Config.apis.gcs, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(reqPC) });
            const json = await responseMR.json();
            console.log("this is the json")
            console.log(json)
            
            
            var x = json.results[0].metadata.fields;
            for (var i = 0; i < x.length; i++) {
                if (x[i].name === "parCode") {
                    var parCode_arr = x[i].textValues.values;
                    // for (var j = 0; j < xx.length; j++) {
                    //     if (xx[j] === this.state.countryISO3) {

                    //         this.isPartecipant = true;
                    //     }
                    // }
                }

                if (x[i].name === "parIso") {
                    var parIso_arr = x[i].textValues.values;
                    // for (var j = 0; j < xx.length; j++) {
                    //     if (xx[j] === this.state.countryISO3) {

                    //         this.isPartecipant = true;
                    //     }
                    // }
                }
              
            }
            console.warn(parIso_arr,parCode_arr)
            console.info(this_app.remote_countries)
            
            var european_iso_arr=this_app.remote_countries.filter(d=>d.iso3==='EUR')[0].members;
            console.log(european_iso_arr)
            // || european_iso_arr.indexOf(this.state.countryISO3)>-1
            if (parCode_arr.indexOf(this.state.countryISO3)>-1 || parIso_arr.indexOf(this.state.countryISO3)>-1 || european_iso_arr.indexOf(this.state.countryISO3)>-1)
            {
                console.log(this.state.countryISO3+' is participant')
                this.state.countryISO3 !== "BRA" ||  this.state.countryISO3 !== "SAM" ?
                this.isPartecipant = true : this.isPartecipant = false;
            }
            else
            {
                console.log(this.state.countryISO3+' is not participant')
                this.isPartecipant = false;
            }
            this.loading = false;

        }
        //#############################################################################

     
        // ################### MOST RELEVANT QUERY ################################ 
        // [“ organization code “ from the “regional/international organization” validation list AND KW “Port state measures”], so for instance the cgi query could be”
        //rfmo:(CCSBT) 
        //       EO:("ccamlr") AND EK:("port State measures" )
        //
        //EO:(\"ccamlr\")
        
        const mostRelevantService = async () => {
            
            if (this.state.countryISO3==='EUR')
            {
                var reqMR = {
                    "query": "repealed:(NOT REPEALED) AND organization:(\"European Union\") keyword:(962)  subjectselection:(FI)  typeoftext:(L | R)",
                    "requestOptions": {
                        "searchApplicationId": "searchapplications/1be285f8874b8c6b772c6e64850b1bb5"
                    },
                    "pageSize":100
                };
            }
            else
            {

            
            var reqMR = {
                "query": "repealed:(NOT REPEALED) AND country:(\"" + this.state.countryISO3 + "\")  keyword:(962)  subjectselection:(FI)  typeoftext:(L | R)",
                "requestOptions": {
                    "searchApplicationId": "searchapplications/1be285f8874b8c6b772c6e64850b1bb5"
                }
            };
        }
            
            const responseMR = await fetch(Config.apis.gcs, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(reqMR) });
            const json = await responseMR.json();
            
            this.setState({
                itemsMR: json,
                // itemsNP: json
            })

            
            console.log(json);
            const country_mostRelevantService = json;

            
  
        }
        //#############################################################################


        // ################### NATIONAL PLAN QUERY ################################ 
        const nationalPlaneService = async () => {
         //   var c_q="country:(\"" + this.state.countryISO3 + "\")  keyword:(962) title:(\"prevenir\" | \"prévenir\" | \"prevent\")  subjectselection:(FI)  typeoftext:(P)"
        //  if (this.state.countryISO3=='EUR')
        //  {
             
        //         var reqNP = {
                  
        //           "query": "organization:(\"European Union\")  keyword:(962) title:(prevenir | prévenir | prevent)  subjectselection:(FI)  typeoftext:(P)",
        //           "pageSize":100,
        //             "requestOptions": {
        //                 "searchApplicationId": "searchapplications/1be285f8874b8c6b772c6e64850b1bb5"
        //             }
        //         };
        //  }
        //  else
        //  {
            var reqNP = {
              
              "query": "country:(" + this.state.countryISO3 + ")  keyword:(962) title:(prevenir | prévenir | prevent)  subjectselection:(FI)  typeoftext:(P)",
              "pageSize":100,
                "requestOptions": {
                    "searchApplicationId": "searchapplications/1be285f8874b8c6b772c6e64850b1bb5"
                }
            };
        //}
            const responseMR = await fetch(Config.apis.gcs, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(reqNP) });
            const json = await responseMR.json();
            this.setState({
                itemsNP: json
            })
            console.log(json);
        }
        //#############################################################################


   
        partecipantCountryService();
        mostRelevantService();
        nationalPlaneService();
        //this.stats=
       
        
        
     // this.create_results_records(this.records_result);
      
        

        var acc = document.getElementsByClassName("accordionProfile");

        for (var i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.display === "block") {
                    panel.style.display = "none";
                } else {
                    panel.style.display = "block";
                    if (document.getElementsByClassName('divRouting')[0]) {
                        var y = document.getElementsByClassName('divRouting')[0].offsetHeight;
                        document.getElementsByClassName('footer')[0].style.top = y + 100 + 'px';
                    }
                }
            });
        }

        if (document.getElementsByClassName('divRouting')[0]) {
            var y = document.getElementsByClassName('divRouting')[0].offsetHeight;
            document.getElementsByClassName('footer')[0].style.top = y + 100 + 'px';
        }
    }

    createMostRelevant(param) {
        var MostRelevant = [];
        const mr = param;
        if (mr.results) {
            if (mr.results.length > 0) {
                for (var i = 0; i < mr.results.length; i++) {
                    if (mr.results[i].metadata.fields.length > 0) {
                        var _tempMR = mr.results[i].metadata.fields
                        var title;
                        var link;
                        for (var j = 0; j < _tempMR.length; j++) {

                            if (_tempMR[j].name === "titleOfText") {
                                title = _tempMR[j].textValues.values
                            }
                            if (_tempMR[j].name === "faolexId") {
                                link = _tempMR[j].textValues.values[0]
                            }
                            if (title && link) {
                                MostRelevant.push({
                                    title: title,
                                    link: link
                                })
                                title = '';
                                link = '';
                            }
                        }
                    }
                }
                console.info(MostRelevant)
                return MostRelevant;
            }
        }
    }

   

    createNationalPlane(param) {
        var NationalPlane = [];
        const np = param;
        if (np.results) {
            if (np.results.length > 0) {
                console.log(JSON.stringify(np.results))
                for (var i = 0; i < np.results.length; i++) {
                    if (np.results[i].metadata.fields.length > 0) {
                        var _tempMR = np.results[i].metadata.fields;
                        
                        var title;
                        var link;
                        for (var j = 0; j < _tempMR.length; j++) {

                            if (_tempMR[j].name === "titleOfText") {
                                title = _tempMR[j].textValues.values
                            }
                            if (_tempMR[j].name === "faolexId") {
                                link = _tempMR[j].textValues.values[0]
                            }
                            if (title && link) {
                                NationalPlane.push({
                                    title: title,
                                    link: link
                                })
                                title = '';
                                link = '';
                            }
                        }
                    }
                }
                return NationalPlane;
            }
        }
    }

    createHeaderCountryprofile(param) {
        var _tempX = {};
        
        var x = param.metadata.fields;
       
        for (var i = 0; i < x.length; i++) {
            if (x[i].name === 'countryIso') {
                _tempX.countryIso = x[i].textValues.values[0];
            }
            if (x[i].name === 'countryDesc') {
                _tempX.countryDesc = x[i].textValues.values[0];
            }
            if (x[i].name === 'header') {
                var _t = x[i];
                _tempX.header = [];
                for (var j = 0; j < _t.textValues.values.length; j++) {

                    _tempX.header.push(_t.textValues.values[j]);
                }
            }
        }
        return _tempX;
    }

    search() {
        var typeOfMeasures = [];
        var param = this.state.typeofmeasure;
        if (param) {
            for (var i = 0; i < param.length; i++) {

                typeOfMeasures.push(param[i].value);
            }
            var _tempQuery = {
                countries: [{
                    label: this.state.countryName,
                    value: this.state.countryISO3
                }],
                typeofmeasure: []
            }
            for (var j = 0; j < typeOfMeasures.length; j++) {
                var x = {
                    label: typeOfMeasures[j],
                    value: typeOfMeasures[j]
                }
                _tempQuery.typeofmeasure.push(x)
            }



            this.TOMprofile = _tempQuery

            console.log('query executed only when from countryprofile , provisions by type measure', _tempQuery)
            
            console.log("this is the tom search : " + this.TOMprofile);
            
            window.location = "/advSearch?query=" + btoa(JSON.stringify(this.TOMprofile)) + "#R1";

        }
        // this.onSearchTOM();
        return;

    }

    reset() {
        this.setState({
            typeofmeasure: []
        })
    }

    onSearchTOM() {
        this.props.onSearchTOM(this.TOMprofile)
    }

    loadGenericApi = (api) => {

        return (inputValue, callback) => {
            let filterRegex = new RegExp(".*(\\b" + inputValue + ").*", "i");

            if (this.countries.length > 0) {
                this.typeoflegalinst = api.map(e => ({
                    label: e['en'],
                    value: e.id
                }))

                callback(
                    this.typeoflegalinst.filter(e => {
                        return e.label.match(filterRegex) !== null;
                    }));

                return;
            }


            // fetch(api)
            //     .then(response => response.json())
            //     .then(data => {
            callback(
                //this.typeoflegalinst = data.map(e => ({
                this.typeoflegalinst = api.map(e => ({
                    label: e['en'],
                    value: e.id
                }))
            );

            //});
        }
    }

    onChange = (name, value) => {
        var _tempTOM = [];
        for (var i = 0; i < value.length; i++) {
            console.log("invoked with: " + name + " - " + value[i].value)
            _tempTOM.push({
                label: value[i].label,
                value: value[i].value
            });

        }
        this.setState({ TOMprofile: [] });
        this.setState({ typeofmeasure: _tempTOM });
    }



    render() 
    {
        //var json_pdf=this.json_pdf;
         console.warn('rendering...3 times??')
         if (this.state.remote_rfmos.length===0) return null;
         if (this.state.remote_countries.length===0) return null;

        var country = this.state.countryISO3;
        console.log(this);
        
       
        var isPartecipant = this.isPartecipant;
        let tplInfo = {};
       
      tplInfo = this.createHeaderCountryprofile(this.testCountryProfile);
      console.log("to cancel here");
      console.log(tplInfo.header)

      // new variable created in March 2023 to fix the list of international committments

      var newIntComm = 
     /*  this.state.countryISO3 === "BRA"  &&
      remote_internationalCommittments.name === "PSMA" ?

      tplInfo.header.slice(1).map(function (item) {
     
        return (
            
           
         
            <div className={item.name === 'PSMA'?'psma':'treaties_div'} key={item.name}>
           
                <a style={{ fontWeight: "bold", display: 'block' }}
                href={item.link} target='_blank' rel="noopener noreferrer">{item.name}</a>
            </div> 
            
        );
    }) : this.state.countryISO3 === "SAM"  &&
    tplInfo.header[0].name === "PSMA" ?
      tplInfo.header.slice(1).map(function (item) {
     
        return (
            
           
          
            <div className={item.name === 'PSMA'?'psma':'treaties_div'} key={item.name}>
                <a style={{ fontWeight: "bold", display: 'block' }}
                href={item.link} target='_blank' rel="noopener noreferrer">{item.name}</a>
            </div> 
            
        );
    }) :  */
    
    this.state.remote_internationalCommittments.filter(item => item.iso3 === this.state.countryISO3)[0].international_commitments
    .map(function (int) {
     
        return (
            
           
         
            <div className={int.name_en === 'PSMA'?'psma':'treaties_div'} key={int.name_en}>
                <a style={{ fontWeight: "bold", display: 'block' }}
                href={int.url} target='_blank' rel="noopener noreferrer">{int.name_en}</a>
            </div> 
            
        );
    }) ; 
    console.log("list to check with Aleeeee");
     console.log(newIntComm);





















      var listItems = 
      this.state.countryISO3 === "BRA"  &&
      tplInfo.header[0].name === "PSMA" ?

      tplInfo.header.slice(1).map(function (item) {
     
        return (
            
           
            //<div className={item.name === 'PSMA'?'psma':''} key={item.name}>
            <div className={item.name === 'PSMA'?'psma':'treaties_div'} key={item.name}>
                {/* <a style={item.name === 'PSMA' && isPartecipant === false ? { display: 'none' } : { fontWeight: "bold", display: 'block' }}  */}
                <a style={{ fontWeight: "bold", display: 'block' }}
                href={item.link} target='_blank' rel="noopener noreferrer">{item.name}</a>
            </div> 
            
        );
    }) : this.state.countryISO3 === "SAM"  &&
    tplInfo.header[0].name === "PSMA" ?
      tplInfo.header.slice(1).map(function (item) {
     
        return (
            
           
            //<div className={item.name === 'PSMA'?'psma':''} key={item.name}>
            <div className={item.name === 'PSMA'?'psma':'treaties_div'} key={item.name}>
                {/* <a style={item.name === 'PSMA' && isPartecipant === false ? { display: 'none' } : { fontWeight: "bold", display: 'block' }}  */}
                <a style={{ fontWeight: "bold", display: 'block' }}
                href={item.link} target='_blank' rel="noopener noreferrer">{item.name}</a>
            </div> 
            
        );
    }) : 
    
    tplInfo.header.map(function (item) {
     
        return (
            
           
            //<div className={item.name === 'PSMA'?'psma':''} key={item.name}>
            <div className={item.name === 'PSMA'?'psma':'treaties_div'} key={item.name}>
                {/* <a style={item.name === 'PSMA' && isPartecipant === false ? { display: 'none' } : { fontWeight: "bold", display: 'block' }}  */}
                <a style={{ fontWeight: "bold", display: 'block' }}
                href={item.link} target='_blank' rel="noopener noreferrer">{item.name}</a>
            </div> 
            
        );
    }) ;
    console.log("list to check with Alessandra");
     console.log(listItems);
    setTimeout(function()
    {
        if (isPartecipant)
        {
            console.log('is participant')
            console.log(isPartecipant)
            $('.psma').show().find('a').text('Agreement on Port State Measures (PSMA)')
        }
        else
        {
            console.log('not participant')
            $('.psma').hide()
        }
    },500)
      
    //   console.log(tplInfo)
    //     var listItems = tplInfo.header.map(function (item) {
            
    //         // if (item.name==='PSMA')
    //         //     {
    //         //         debugger
    //         //      var name='Agreement on Port State Measures (PSMA)'   
    //         //     }
    //         //     else

    //         //     {
    //         //         var name=item.name;
    //         //     }
    //         return (
                
                
    //             <div key={item.name}>
    //                 <a style={item.name === 'PSMA' && isPartecipant === false ? { display: 'none' } : 
    //                 { fontWeight: "bold", display: 'block' }} href={item.link} target='_blank' rel="noopener noreferrer" >{
    //                     item.name === 'PSMA' ? 'Agreement' : item.name
    //                 }</a>
    //             </div>
                
    //         );
    //     });

       

        var conRFM = () => {

            var processed = [];
            var list = [];

            
            
            var country_code=this.state.remote_countries.filter(
                function(d)
                {
                    if (d.iso3)
                    {
                        if (d.iso3==country)
                        return d
                    }
                    else
                    {
                        if (d.country_code==country)
                        return d
                    }
                })[0].country_code;
                //d=>d.iso3==country)[0].country_code;


                this.state.remote_rfmos.map(function (item, index) {
                for (var i = 0; i < item.countries.length; i++) {
                    if (item.countries[i] === country || item.countries[i] === country_code) {
                        list.push(item)
                        //processed.push(item.id)
                        //if (country === this_app.remote_countries[i].iso3 || country === this_app.remote_countries[i].country_code) 
                        // if (!processed.includes(item.id)) {
                        //     processed.push(item.id)
                        //     list.push(item)

                        // }
                    }

                }

            })

            return list
        }

        /*      var CountriesRFMO = jsonRFMO.map(function (item, index) {
                  var processed = [];
                  for (var i = 0; i < item.countries.length; i++) {
                      if (item.countries[i] === country) {
                          return (
                              <div key={i} >
                                  <a href={"RFMOprofile?" + item.id} target='_blank' rel="noopener noreferrer" >{item.en}</a>
                              </div>
                          )
                      }
      
                  }
                  return null
              })
              */
        var _this=this;
     
        var CountriesRFMO = conRFM().map(function (item, index) {
            
           
            return (
                
                <span key={index} >
                    <a href={"RFMOprofile?" + item.code} target='_blank' rel="noopener noreferrer" >{item.en}</a>
                </span>
            )

        })


        var MostRelevant = this.createMostRelevant(this.state.itemsMR);
        
      
        var listMostRelevant = MostRelevant ? MostRelevant.map(function (item, index) {
      
            return (

                <span key={index} >
                <a href={'http://www.fao.org/faolex/results/details/en/c/' + item.link} target='_blank' rel="noopener noreferrer" >{item.title}</a>
            </span>
                // <div key={index}>
                //     <a style={{ fontWeight: "bold" }} href={'http://www.fao.org/faolex/results/details/en/c/' + item.link} target='_blank' rel="noopener noreferrer">{item.title}</a>
                // </div>
            );
        }) : '';
        
        console.info(listMostRelevant)
        var NationalPlane = this.createNationalPlane(this.state.itemsNP);
        
        console.log(NationalPlane)
        var listNationalPlane = NationalPlane ? NationalPlane.map(function (item, index) {
            return (
                <span key={index} >
                <a href={'http://www.fao.org/faolex/results/details/en/c/' + item.link} target='_blank' rel="noopener noreferrer" >{item.title}</a>
            </span>
                // <div key={index}>
                //     <a style={{ fontWeight: "bold" }} href={'http://www.fao.org/faolex/results/details/en/c/' + item.link} target='_blank' rel="noopener noreferrer">{item.title}</a>
                // </div>
            );
        }) : '';

   

        
        // let  present_stats = this.stats;
        // if (present_stats)
        //  {
        
        // var statsComponent=<CountryProfileStats stats={this.stats}/> 
        
        
        
        // var elems = document.querySelectorAll('.stats_collection.collapsible');
        // if (elems && elems.length>0)
        // {
        
     
        //  }
        // }
        // else
        // {
        //     return <div>Loading...</div>;
        // }
    
        
        //var statsComponent=<CountryProfileStats stats={[{}]}/> 
        

// console.warn(this.json_pdf)
        return (

            <div className="parent-item">

                <div className="parent-item row header-parent-item">

                    <div className="c-left-item col">
                        {/* {this.state.countryISO3 !== undefined && */}
                            <img alt=""  src={"https://www.fao.org/assets/countries/flags/" + this.state.countryISO3.toLowerCase() + ".gif"}></img>
                        {/* } */}
                        </div>
                    <div className="c-right-item col s10">
                        <span>{this.state.countryName}</span>
                    </div>
                </div>


                <div className="treaties">
                {newIntComm !== undefined && newIntComm.length > 0 && <span className="treaties_title">International commitments</span>}
                    {newIntComm}
                </div>


                <div className="row country_collapsibles_wrapper">
                        <ul className="col s4 collapsible rfmo">
                                <li>
                                <div class="valign-wrapper collapsible-header">
                                    <span className='col s9'>Membership to RFMOs</span>
                                <span className="col s2"><span class="new badge" data-badge-caption=""></span>
                                    
                                    </span>
                                    <span className="col s1"><i class="material-icons">keyboard_arrow_down</i></span>
                                    </div>
                                <div class="collapsible-body">
                                {CountriesRFMO}
                                </div>
                                </li>
                                </ul> 
                            <ul className="col s4 collapsible national_plans">
                                <li>
                                <div class="valign-wrapper collapsible-header">
                                    <span className='col s9'>National Plan Of Action</span>
                                <span className="col s2"><span class="new badge" data-badge-caption=""></span>
                                    
                                    </span>
                                    <span className="col s1"><i class="material-icons">keyboard_arrow_down</i></span>
                                    </div>
                                <div class="collapsible-body">
                                {listNationalPlane}
                                </div>
                                </li>
                                </ul>
                                            {/* <button className="accordionProfile" >Most relevant legislation</button> */}
                                            {/* <div className="panelProfile most_relevant">
                                                {listMostRelevant}
                                            </div> */}

                            <ul className="col s4 collapsible most_relevant">
                                <li>
                                <div className="valign-wrapper collapsible-header">
                                    <span className='col s9'>Most relevant legislation</span>
                                <span className="col s2"><span class="new badge" data-badge-caption=""></span>
                                    
                                    </span>
                                    <span className="col s1"><i class="material-icons">keyboard_arrow_down</i></span>
                                    </div>
                                <div class="collapsible-body">
                                {listMostRelevant}
                                </div>
                                </li>
                    </ul>
                </div>
       
    <div className='row valign-wrapper download_order'>
    <div className='col s3'>
                <div className="download_pdf btn btn-small">Download PDF</div>
                </div>

                <div className='col s9'>
                    <div>Records ordered by </div>
                    <div>
                    <form id="order_form" action="#">
    
    <label id="test1">
        <input id="test1" class="with-gap" name="group1" value="yearoftext" type="radio" checked />
        <span>Year of text</span>
    </label>
    
    
    <label id="test2">
        <input id="test2" class="with-gap" name="group2" value="mostrelevant" type="radio" />
        <span>Relevance</span>
    </label>

</form>
                </div>
    </div>
                </div>
                <div className="psm_title">Provisions on PSM</div>
                <div className="query_results_count_wrapper">
                    <div className="query_results_count"><span></span> records</div>
                    <div className="query_results_count_filtered">Visualizing <span className="counts"></span> filtered records</div>
                    
                </div>
                <div class='result-list'>
                </div>

             





                <div style={{ height: '200px' }}>

                </div>
                {/* {((this.loading) ? <FaoBlockUi /> : "")} */}
            </div>
        )

    }
}

export default CountryProfile;
