import React, { Component } from 'react';
import Config from '../config';

import $ from 'jquery';
import M from 'materialize-css';
require('./component.RFMOprofile.css');

var this_app = {
    orderby:'yearoftext',
    records_count: 0,
    records: [],
    rows: [],
    simplified_records: [],
    response_params: {
        numRecords: null,
        counts: 0,
        numPages: 0
    },
    first_query: true
};
this_app.fields_to_filter = [
    //     {

    //     title: 'Country',
    //     //the field on the data results
    //     field: 'countryIso',
    //     //operatorName is used on facetResults
    //     operatorName: 'country',
    //     true_false_arr: [],
    //     values: []
    // },
    {

        title: 'Type of legal instrument',
        field: 'typeOfLegalInstrument',
        operatorName: 'typeoflegalinstrument',
        true_false_arr: [],
        values: []
    },
    {

        title: 'Type of measure',
        field: 'typeOfMeasure',
        operatorName: 'typeofmeasure',
        true_false_arr: [],
        values: []
    },
    {

        title: 'Year',
        field: 'yearOfText',
        operatorName: 'yearoftext',
        true_false_arr: [],
        values: []
    }
];
this_app.fields = [

    // {
    //     pos: 0,
    //     title: 'Countries',

    //     //IN RECORD FIELD!
    //     field: 'countryIso',

    //     //IN FACETS!
    //     operatorName: 'countries',
    //     true_false_arr: []
    // }, 
    {
        pos: 0,
        title: 'RFMO',
        //  field: 'organizationCode',
        field: 'rfmo',
        true_false_arr: []
    },
    {
        pos: 1,
        title: 'Reference of measure',
        field: 'referenceOfMeasure',
        true_false_arr: []
    },
    {
        pos: 2,
        title: 'Measure cited in full',
        field: 'measureCitedInFull',
        true_false_arr: []
    },
    {
        pos: 3,
        title: 'Type of legal instrument',
        field: 'typeOfLegalInstrument',
        true_false_arr: []
    },

    {
        pos: 4,
        title: 'Date of text',
        field: 'dateOfText'
    }, {
        pos: 5,
        title: 'Other reference',
        field: 'otherReferenceHttp',
        operatorName: 'year',
        true_false_arr: []
    }

    , {
        pos: 6,
        title: 'All aquatic species',
        field: 'allAquaticSpecies',
        operatorName: 'allAquaticSpecies',
        true_false_arr: []
    }, {
        pos: 7,
        title: 'Vessel (by flag)',
        field: 'vesselByFlag',
        operatorName: 'vesselByFlag',
        true_false_arr: []
    },
    {
        pos: 8,
        title: 'Type of measure',
        field: 'typeOfMeasure',
        operatorName: 'typeOfMeasure',
        true_false_arr: []
    },
    {
        pos: 9,
        title: 'In force',
        field: 'inForce',
        operatorName: 'inForce',
        true_false_arr: []
    },
    {
        pos: 10,
        title: 'faolexId',
        field: 'faolexId',
        operatorName: 'faolexId',
        true_false_arr: []
    }, {
        pos: 11,
        title: 'Year',
        field: 'yearOfText',
        operatorName: 'yearoftext',
        true_false_arr: []
    },
    {
        pos: 12,
        title: 'Most Relevant',
        field: 'mostRelevant',
        operatorName: 'mostrelevant',
        true_false_arr: []
        
    }


]
var fields_arr = this_app.fields.map(d => d.field);

var q_rfmo = window.location.search.replace('?', '');

function create_facets() {
    var q_rfmo = window.location.search.replace('?', '');
    var r = {
        //"query": PubQueryBuilder.createQueryParams(formData, selectedFacets),
        "query": "* rfmo:(\"" + q_rfmo + "\")  inforce:(Yes)",
        //https://developers.google.com/cloud-search/docs/reference/rest/v1/settings.searchapplications  ??
        //https://developers.google.com/cloud-search/docs/reference/rest/v1/query/search#google.apps.search.v1.QueryService.Search
        // "SourceCrowdingConfig":
        // {
        //     "numResults":200
        // },
        "pageSize": 100,
       
        "requestOptions": {
          

            "searchApplicationId": Config.PwsSearchApplicationId
        },
        "facetOptions": [{
                "operatorName": "country",
                "numFacetBuckets": 200
            }, {
                "operatorName": "rfmo",
                "numFacetBuckets": 100
            },
            {
                "operatorName": "typeofmeasure",
                "numFacetBuckets": 100
            },

            {
                "operatorName": "typeoflegalinstrument",
                "numFacetBuckets": 100
            }, {
                "operatorName": "allaquaticspecies",
                "numFacetBuckets": 100
            }, {
                "operatorName": "vesselbyflag",
                "numFacetBuckets": 100
            }, {
                "operatorName": "competencearea",
                "numFacetBuckets": 100
            }, {
                "operatorName": "yearoftext",
                "numFacetBuckets": 100
            }
        ],
        "queryInterpretationOptions": {
            "enableVerbatimMode": true
        }


    };

    return $.ajax({
        type: "POST",
        url: Config.apis.gcs,
        contentType: "text/plain",
        data: JSON.stringify(r)

    })



}


function fill_elem_results(data) {


    var all_html = '';

    for (var p in data) {
        if (data[p]) {
            var o = data[p];


            console.warn(o)
            var table_html = '<div class="z-depth-1 parent-item-container row"><table><thead></thead><tbody>'

            var feature_fields = o.metadata.fields;

            //DATA DOES NOT FOLLOW A LOGIC ON GCS!!!

            var mapped_feature_fields = o.metadata.fields.filter(d => fields_arr.indexOf(d.name) > -1);
            var sorted_mapped_feature_fields = []

            for (var p in fields_arr) {
                for (var s in mapped_feature_fields) {
                    if (mapped_feature_fields[s].name === fields_arr[p])
                        sorted_mapped_feature_fields.push(mapped_feature_fields[s])
                }
            }
            if (sorted_mapped_feature_fields.length !== fields_arr.length) {


                var arr = sorted_mapped_feature_fields.map(d => d.name)
                for (var p in fields_arr) {

                    if (arr.indexOf(fields_arr[p]) === -1) {
                        var missing_f = this_app.fields.filter(d => d.field === fields_arr[p])[0];
                        console.info(missing_f)
                        sorted_mapped_feature_fields.splice(missing_f.pos, 0, {
                            name: missing_f.field,
                            textValues: { values: ['No information available in the record'] },
                            no_info: true
                        })

                    }
                }
            }

            var f_list = sorted_mapped_feature_fields.map(d => d.name);
            var new_o = { index: this_app.records_count, info: [] };
            //var iso3_codes = countriesJSON.map(d => d.iso3);
            var iso3_codes = this_app.countries.map(d => d.iso3);
            

            for (var index in sorted_mapped_feature_fields) {

                if (fields_arr.indexOf(sorted_mapped_feature_fields[index].name) > -1) {

                    var f = sorted_mapped_feature_fields[index];


                    var new_f = {};


                    var pos = f_list.indexOf(f.name);


                    if (pos > -1) {
                        console.log(f.name)
                        if (f.name !== 'faolexId' || f.name !== 'yearOfText') {
                            table_html += '<tr>';
                            // if (!this_app.fields[pos])

                            // var title=this_app.fields[pos].title;


                            f.title = this_app.fields[pos].title;

                            new_f.field = f.name;

                            if (f.integerValues) {


                                new_f.type = 'integer';

                                new_f.data = f.integerValues;

                                var t = f.integerValues.values.map(d => '<span>' + d + '</span>').join('');
                            }
                            if (f.textValues) {

                                new_f.type = 'text';
                                //multiple values

                                new_f.data = f.textValues;

                                if (f.name === 'countryIso') {

                                    if (new_f.data.values.length > 1) {
                                        var t = new_f.data.values.map(function(d) {
                                            var pos = iso3_codes.indexOf(d);
                                            return this.state.countries[pos].en;
                                        }).join(' / ')
                                    } else {

                                        var pos = iso3_codes.indexOf(new_f.data.values[0]);
                                        var t = this.state.countries[pos].en;
                                    }
                                } else {

                                    if (f.name === 'referenceOfMeasure') {
                                        var id_pos = f_list.indexOf('faolexId');
                                        // faolexId"
                                        // textValues: {values: ["LEX-FAOC174674"]}
                                        // values: ["LEX-FAOC174674"]
                                        if (id_pos > -1) {
                                            if (!f.no_info)
                                                var t = '<div class="title"><a href="https://www.fao.org/faolex/results/details/en/c/' + sorted_mapped_feature_fields[id_pos].textValues.values[0] + '" rel="" target="_blank">' + f.textValues.values[0] + '</a></div>'
                                            else
                                                var t = '<div class="title"> ' + f.textValues.values[0] + '</div>'
                                        } else {
                                            //var t='No faolexId available';
                                            var t = '<div class="title">' + f.textValues.values[0] + '</div>';
                                        }

                                    } else {

                                        if (f.name === 'measureCitedInFull') {
                                            var t = '<div class="measureCitedInFull">'
                                            for (var p in f.textValues.values) {
                                                var d = f.textValues.values[p];
                                                t += '<span>' + d + '</span>';
                                            }
                                            t += '</div>';


                                        } else {
                                            if (f.name === 'rfmo') {
                                                // console.log(this.state.RFMOacronym);
                                                
                                                console.log(f);
                                                console.info(this_app.rfmos)
                                               // console.warn(jsonRFMO);
                                                //var t = jsonRFMO.filter(d => d.code == f.textValues.values[0])[0].en;
                                                var t = this_app.rfmos.filter(d => (d.code && d.code === f.textValues.values[0]))[0].en;
                                                //console.log(jsonRFMO,f,this)
                                            } else {
                                                if (f.name !== 'faolexId') {


                                                    // if (f.name === 'abstract') {
                                                    //     var t = '<div class="abstract">' + f.textValues.values.join(',') + '</div>';
                                                    // } else {


                                                    // if (f.textValues.values[0].indexOf('#') > -1) 

                                                    var info = f.textValues.values;
                                                    if (info.length === 1) {
                                                        if (f.name === 'otherReferenceHttp') {
                                                            if (!f.no_info) {

                                                                if (info[0].indexOf('http') === -1)
                                                                    var t = '<span  class="sel"><a href="http://' + info[0] + '" target="_blank">http://' + info[0] + '</a></span>';
                                                                else
                                                                    var t = '<span  class="sel"><a href="' + info[0] + '" target="_blank">' + info[0] + '</a></span>';
                                                            } else {
                                                                var t = '<span  class="sel">' + info[0] + '</span>';
                                                            }

                                                        } else {
                                                            var t = '<span class="sel">' + info[0] + '</span>';
                                                        }


                                                    } else {
                                                        var t = info.map(function(d) {
                                                                if (f.name === 'otherReferenceHttp') {
                                                                    if (!f.no_info)
                                                                        return '<span  class="small"><a href="' + info[0] + '" target="_blank">' + info[0] + '</a></span>';
                                                                    else
                                                                        return '<span  class="small">' + info[0] + '</span>';
                                                                } else {
                                                                    return '<span class="small">' + d + '</span>';
                                                                }



                                                            })
                                                            .join(' / ')
                                                    }

                                                }
                                                else

                                                {
                                                    
                                                }
                                            }
                                        }



                                    }
                                }
                                if (f.name !== 'faolexId')
                                table_html += '<td class="td_title">' + f.title + '</td><td>' + t + '</td></tr>';
                            }


                        } else {
                            debugger
                            console.warn('not in list ' + f.name)

                        }


                    }



                    //html += '<div><a target="_blank" href="http://extwprlegs1.fao.org/docs/pdf/' + o.title + '">' + o.title + '</a></div></div>';

                }

                new_o.info.push(new_f);
            }
            table_html += '</tbody></table></div>';
            all_html += table_html;
        }
    }
    if (all_html === '') {
        var html = '<h4>No records</h4>'
    }
    $('.result-list').html(all_html);





}

async function get_records() {
  
    this_app.records = [];
    var datastring = {
        //"query": "* competencerfmoop:(\"" + q_rfmo+ "\") inforce:(Yes)",
        "query": "* rfmo:(\"" + q_rfmo + "\") inforce:(Yes)",

        //not working!
        "sortOptions":
        {
            "operatorName":this_app.orderby,
            "sortOrder": "DESCENDING"
        },
        "pageSize": 100,
        "requestOptions": {
            "searchApplicationId": "searchapplications/1be285f8874b8c6ba8027fcdb39b28ed"
        }


    };
    const responseMR = await fetch(Config.apis.gcs, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(datastring) });

    var data = responseMR.json();
    console.info(data)

    this_app.records.push(data.results)
    return data;

};


function search(state)
{
    
    this_app.simplified_records=[];
    get_records().then(function(data) {
        create_facets().then(function(facets_all_data) {
            $('.query_results_count_wrapper').show();
            var facets_data = facets_all_data.facetResults;
            
            
            console.log(this_app.countries)
            console.log(facets_data)

            $('.facet-nav .content').empty();
            //$('body').busyLoad("hide");
            var iso3_codes = this_app.countries.map(d => d.iso3);
            

            for (var p in facets_data) {

                var o = facets_data[p];
                var field_in_data = this_app.fields_to_filter.filter(d => d.operatorName === o.operatorName)[0];

                console.log(field_in_data)
                if (field_in_data) {

                    var html = '<ul field="' + field_in_data.field + '" class="collapsible ' + o.operatorName + '"> <li> <div class="collapsible-header valign-wrapper"><span class="col s10">' + field_in_data.title + '</span><span class="col s2"><i class="material-icons up_down">keyboard_arrow_down</i></span></div> <div class="collapsible-body"> <ul>';
                    for (var x in o.buckets) {
                        var obj = o.buckets[x];
                        if (obj.value.stringValue) {
                            if (obj.value.stringValue.includes('#')) {
                                //html += '<li>' + obj.value.stringValue.split('#')[0] + ' counts ' + obj.count + '</li>'
                                html += '<li class="row valign-wrapper"><span class="col s10">' + obj.value.stringValue.split('#')[0] + '</span><span data-badge-caption="" class="col s2 my_blue badge"> ' + obj.count + '</span></li>';
                            } else {
                                if (o.operatorName === 'country') {
                                    var pos = iso3_codes.indexOf(obj.value.stringValue);
                                    var t = this.state.countries[pos].en;
                                    html += '<li class="row valign-wrapper"><span class="col s10">' + t + '</span><span data-badge-caption="" class="col s2 my_blue badge"> ' + obj.count + '</span></li>';
                                } else

                                {
                                    html += '<li class="row valign-wrapper"><span class="col s10">' + obj.value.stringValue + '</span><span data-badge-caption="" class="col s2 my_blue badge"> ' + obj.count + '</span></li>';
                                }
                                //html += '<li>' + obj.value.stringValue + ' counts ' + obj.count + '</li>';

                            }
                        } else {

                            html += '<li class="row valign-wrapper"><span class="col s10">' + obj.value.integerValue + '</span><span data-badge-caption="" class="col s2 my_blue badge"> ' + obj.count + '</span></li>';

                        }
                        // html+='<li>'+html+'</li>'
                    }
                    html += '</ul> </div> </li> </ul>';
                    
                    console.info(html);
                    console.warn($('.facet-nav .content').length)
                    $('.facet-nav .content').append(html);
                }
            }
        }).then(function() {
            if ($('.facet-nav .collapsible').length > 0) {
                $('.facet-nav').show();
                var elems = document.querySelectorAll('.facet-nav .collapsible');

                console.log(elems.length + ' collapsibles from component.countryprofile.js')

                var instances = M.Collapsible.init(elems);

                $('.facet-nav .collapsible-header').each(function() {
                    $(this).trigger('click')
                })

                var filters = [];
                $('.facet-nav .collapsible-body').on('click', function(e) {

                    if (this_app.rows.length === 0) {
                        this_app.rows = $('.result-list .parent-item-container');
                    }

                    console.log($(e.target))
                    if ($(e.target).hasClass('valign-wrapper') || $(e.target).parents().closest('.valign-wrapper').length > 0) {
                        //$(e.target).hasClass('valign-wrapper') ||
                        if ($(e.target).hasClass('valign-wrapper'))
                            var row = $(e.target);
                        else
                            var row = $(e.target).parents().closest('.row.valign-wrapper');

                        console.log(row.find('.s10'))

                        var val = row.find('.s10').text();
                        //countryEn
                        var field = $(this).parents().closest('.collapsible').attr('field');

                        var f = filters.filter(d => d.field === field)[0];
                        //debugger
                        console.log(this_app.fields_to_filter, field, f)
                        if (!f) {
                            var field = this_app.fields_to_filter.filter(d => d.field === field)[0];

                            var cloned_field = Object.assign({}, field);
                            cloned_field.values = [val]

                            filters.push(cloned_field)
                        } else {
                            if (row.hasClass('inactive')) {

                                var pos = f.values.indexOf(val);
                                console.log(pos);
                                f.values.splice(pos, 1);
                                console.log(f)
                            } else {
                                //filters.filter(d => d.field == field)[0]
                                console.warn(f)
                                f.values.push(val)
                            }

                        }

                        row.toggleClass('inactive');

                        console.warn(filters);
                        /*
                        field: "yearOfText"
                        operatorName: "yearoftext"
                        title: "Year"
                        true_false_arr: []
                        values: ['2019']
                        */

                        this_app.filters = filters;
                        console.warn(this_app.filters)
                        exec_filter()
                    } else {

                    }

                })
            }
            
        })
    console.log(data)
        //this_app.records.push(data.results)
    this_app.records.push.apply(this_app.records, data.results);

    console.log(this_app.records)

    if (data.resultCountExact) {
        var counts = parseInt(data.resultCountExact);
    } else {
        if (data.resultCountEstimate)
            var counts = parseInt(data.resultCountEstimate);
    }


    $('.query_results_count span').text(counts);
    $('.query_results_count').show();
    if (counts > 0) {
        this_app.response_params.numPages =
            Math.ceil(counts / 100);


        if (this_app.response_params.numPages > 1) {
            // alert(this_app.response_params.numPages)
            var q_country = window.location.search.replace('?', '');
            var datastring = {
                "query": "* competencerfmoop:(\"" + q_rfmo + "\") inforce:(Yes)",
                "pageSize": 100,
                "requestOptions": {
                    "searchApplicationId": "searchapplications/1be285f8874b8c6ba8027fcdb39b28ed"
                }
            };


            for (var i = 2; i <= parseInt(this_app.response_params.numPages); i++) {

                exec_ajax(i, datastring)

            }
        } else {
            associated_data(this_app.records)
            fill_elem_results(data.results);
            //this_app.records_joined=data.results;

        }
    } else

    {
        $('.facet-nav').hide();
        $('query_results_count span').text(counts);
    }
})
}

var q_rfmo = window.location.search.replace('?', '');


function associated_data(data) {

    console.warn(data)

    var new_f_arr = [];
    var fields_arr = this_app.fields.map(d => d.field);
    console.warn(fields_arr)
    for (var p in data) {
        var o = data[p];
        if (o) {
            o.index = parseInt(p);
            console.log(o, index)
            var feature_fields = o.metadata.fields;

            var mapped_feature_fields = o.metadata.fields.filter(d => fields_arr.indexOf(d.name) > -1);
            var sorted_mapped_feature_fields = []

            for (var p in fields_arr) {
                for (var s in mapped_feature_fields) {
                    if (mapped_feature_fields[s].name === fields_arr[p])
                        sorted_mapped_feature_fields.push(mapped_feature_fields[s])
                }
            }




            var f_list = sorted_mapped_feature_fields.map(d => d.name);
            var new_o = { index: parseInt(p), info: [] };
            for (var index in sorted_mapped_feature_fields) {

                if (fields_arr.indexOf(sorted_mapped_feature_fields[index].name) > -1) {

                    var f = sorted_mapped_feature_fields[index];


                    var new_f = {};


                    var pos = f_list.indexOf(f.name);


                    if (pos > -1) {

                        new_f.field = f.name;


                        if (f.integerValues) {


                            new_f.type = 'integer';

                            new_f.data = f.integerValues;
                        }
                        if (f.textValues) {

                            if (f.name !== 'abstract') {
                                new_f.type = 'text';

                                if (f.textValues.values[0].indexOf('#') > -1) {
                                    // if (f.name == 'geographicalArea')
                                    //DUPLICATED values for geographicalArea in GCS!!
                                    //     debugger

                                    new_f.data = {};
                                    var temp_arr = [];
                                    var temp_arr_html = [];

                                    f.textValues.values.forEach(function(d) {
                                        if (temp_arr.indexOf(d.split('#')[0]) === -1) {
                                            temp_arr.push(d.split('#')[0]);
                                            return d.split('#')[0];
                                        }

                                    });

                                    new_f.data.values = temp_arr;

                                } else {
                                    new_f.data = f.textValues;

                                }




                            }


                        }

                        //<div>Type of text: ' + o.typeOfTextEn + '</div><div>Year: ' + o.year + '</div><div><a target="_blank" href="http://extwprlegs1.fao.org/docs/pdf/' + o.title + '">Document</a></div>';

                    }
                    new_o.info.push(new_f);
                }
            }
            new_f_arr.push(new_o);
            new_o.index = p;
            this_app.simplified_records.push(new_o);

        }
    }

    console.log(this_app.simplified_records)
}

function exec_filter() {

    console.log(this_app.records_joined)
    for (var x in this_app.filters) {

        //we add here the ones we want to hide!!!
        this_app.filters[x].true_false_arr = []
    }

    var t_f_obj_arr = [];

    console.info(this_app.simplified_records)
    for (var p in this_app.simplified_records) {
        var o = this_app.simplified_records[p];
        o.index = parseInt(p)
            //console.log(o)
        if (p === 0) {
            console.log(o)
                //   debugger

        }
        var t_f_obj = { id: p, arr: [] };

        var filters_arr = o.info.map(d => d.field);

        for (var x in this_app.filters) {

            var this_filter = this_app.filters[x];
            if (this_filter.field !== 'abstract') {


                var f = o.info.filter(d => d.field === this_filter.field)[0];


                var f_data = f.data.values;

                //   console.log(filters[x].values,f_data[0])
                console.log(this_filter)
                    //array indexof...

                if (this_filter.values.length === 0) {


                    if (f_data.indexOf(this_filter.values[0]) > -1) {
                        // this_filter.true_false_arr.push('true')
                        debugger
                        this_filter.true_false_arr.push(o.index)
                    } else {

                    }
                } else {


                    //the values of the record contains a value to be hidden??
                    var found = f_data.some(r => this_filter.values.indexOf(r) >= 0);

                    //we found the value in the array of what we wish to hide!
                    if (found) {

                        console.log('YES found ' + o.index + ' ??')
                        this_filter.true_false_arr.push(o.index)
                    }


                }
            }
        }
    }


    var all_true_false_arr = [];

    console.log(JSON.stringify(this_app.filters))
    for (var x in this_app.filters) {


        //     console.log(this_app.filters[x])
        if (this_app.filters[x].true_false_arr.length > 0) {

            all_true_false_arr.push.apply(all_true_false_arr, this_app.filters[x].true_false_arr);


        }




    }

    console.log(all_true_false_arr);

    var n = all_true_false_arr.reduce(function(memo, feature) {

        var pos = memo['arr'].indexOf(feature);
        if (pos < 0) {
            memo.arr_obj.push({ val: feature, counts: 1 })
                // memo.arr_obj.filter(d=>d.val===feature)[0].counts++

            memo.arr.push(feature)
        } else {

            memo.arr_obj[pos].counts++;
        }
        return memo;

    }, {
        arr: [],
        arr_obj: []
    });

    var f = this_app.filters.filter(d => d.true_false_arr.length > 0);
    var in_all = n.arr_obj.filter(d => d.counts === f.length).map(d => d.val);
    if (in_all.length === 0) {
        if (all_true_false_arr.length === 0) {
            this_app.rows.removeClass('hidden')
        } else {
            this_app.rows.addClass('hidden')
        }
        console.log('noone')

        //this_app.rows.removeClass('hidden')
    } else {
        this_app.rows.addClass('hidden')
        setTimeout(function() {


            this_app.rows.each(function(i, d) {
                //$(this).removeClass('hidden')


                //????'
                if (in_all.indexOf(i) > -1) {
                    console.log('show ' + i)
                    $(this).removeClass('hidden')

                }
            })


        }, 1500)
    }
    if (in_all.length === 0) {
        // $('.query_results_count_filtered').hide();

        $('.query_results_count_filtered span.counts').text(this_app.simplified_records.length)

    } else {
        //$('.query_results_count_filtered').hide();
        $('.query_results_count_filtered span.counts').text(in_all.length)
    }
    $('.query_results_count_filtered').show();
    var temp_counts = in_all.length;

    if (temp_counts < 0)
        temp_counts = 0;

    //  $('.result-temp-counts').empty().text
    //  alert('Showing ' + temp_counts + ' records of ' + $('.item').length)
    console.warn(in_all)
        //$('.result-list .item').show()

    console.warn(JSON.stringify(this_app.filters))
}

function exec_ajax(i, datastring) {

    //i being >1
    datastring.start = (i - 1) * 100;



    $.ajax({
        type: "POST",
        url: Config.apis.gcs,
        contentType: "text/plain",
        data: JSON.stringify(datastring),
        success: function(data) {
            this_app.records.push(data.results)
            if (i === this_app.response_params.numPages) {


                console.log(this_app.records)
                this_app.records_joined = [].concat.apply([], this_app.records);
                associated_data(this_app.records_joined)
                fill_elem_results(this_app.records_joined)


            }

        }
    })
}

class RFMOprofile extends Component {

    constructor(props) {
        super(props);
        this.RFMO = [];
        // query.keyword = '(962)';
        // query.subjectselection = '(FI)';
        // query.typeoftext = '(L | R)';
        this.state = {
            //added by Pere
            keyword: '(962)',
            subjectselection: '(FI)',
            typeoftext: '(L | R)',
            rfmos:[],
            countries:[],
            value: "",
            isLoaded: false,
            itemsMR: [],
            RFMOname: '',
            RFMOacronym: window.location.search.replace('?', ''),
            typeofmeasure: [],
        };
        this.TOMprofile = '';


    }

    

    async componentDidMount() 
    {
        
        fetch('https://faolex.fao.org/docs/json/PortlexCP.json')

        .then(response => response.json())
        .then(json => {
            this_app.countries=json.document.filter(d=>d.country_code);
          this.setState({ countries: json.document.filter(d=>d.country_code) });

          fetch('https://faolex.fao.org/docs/json/RFMO.json')

                .then(response => response.json())
                .then(json => {
                    
                    console.log(json.document)
                    this_app.rfmos=json.document.filter(d=>d.code);
                this.setState({ rfmos: json.document.filter(d=>d.code) });
                
                setTimeout(function()
                {
                    search(this.state);
                },1000)
                
                    

                    this.RFMO.push(this.state.RFMOacronym);
                    var $ = require("jquery");
                    $('.facet-nav').hide();
                    var elems = document.querySelectorAll('.collapsible');
                    console.log(elems.length + ' collapsibles from component.rfmoprofile.js')
                    var instances = M.Collapsible.init(elems);

                    //not proper way but first time on radio was not changing...
                    $('#order_form input').on('click',function()
                    {                        
                        this_app.orderby=$(this).prop('value');
                        $('#order_form').find('input:checked').each(function()
                        {
                            
                            if ($(this).attr('value')!==this_app.orderby)
                            {
                            
                                $(this).prop('checked',false)
                            }
                            else
                            {
                                $(this).prop('checked',true)
                            }
                            
                        })
                    
                        search();
                    
                    })

                    var pdfMake = window.pdfMake;
                    pdfMake.tableLayouts = {
                        exampleLayout: {
                            hLineWidth: function(i, node) {
                            
                                    
                                if (i === 0) {
                                    return 0.01
                                } else {
                                    if (i === node.table.body.length)
                                        return 0.01
                                    else
                                        return 0
                                }

                                // return (i === node.table.headerRows) ? 4 : 0.1;
                            },
                            vLineWidth: function(i) {
                                return 1;
                            },
                            hLineColor: '#e1dee3',
                            vLineColor: '#e1dee3',
                            paddingLeft: function(i) {
                                return i === 0 ? 4 : 8;
                            },
                            paddingRight: function(i, node) {
                                return i === 0 ? 4 : 8;
                            }
                        }
                    };
                    var json_pdf = {
                        pageMargins: [40, 10, 40, 60],
                        footer: function(currentPage, pageCount) {
                            return {
                                table: {
                                    //layout: 'headerLineOnly',
                                    widths: '*',
                                    body: [
                                        [{
                                            border: [false, true, false, false],
                                            text: "Page " + currentPage.toString() + ' of ' + pageCount,
                                            alignment: 'right',
                                            style: 'normalText',
                                            //[left, top, right, bottom] 
                                            margin: [0, 20, 50, 0],

                                        }]
                                    ]
                                },
                                layout: 'noBorders'
                            };
                        },
                        styles: {
                            portlex: { color: '#737f91', "fontSize": 10,  "margin": [0, 8, 0, 5] },
                            date: { "fontSize": 8, "margin": [0, 15, 0, 5] },
                            country: {
                                fontSize: 21,
                                alignment: 'center',
                                OpenSansBolded:true
                            },
                            header: {
                                fontSize: 15,
                                OpenSansBolded:true,
                                // margin: [left, top, right, bottom]
                                margin: [0, 10, 0, 0],
                                //darker blue
                                color: '#69686e'


                                // decoration: 'underline'
                                    // bold: true
                            },

                            link: {
                                fontSize: 8,
                                margin: [0, 5, 0, 0],
                                color: '#002a66',
                                OpenSansItalic: true
                            },

                            table_style: {

                                margin: [0, 5, 0, 5]


                            },
                            measurecitedinfull: {
                                // margin: [left, top, right, bottom]
                                fontSize: 8,
                                margin: [0, 3, 0, 3],
                                
                                //greyish
                                color: '#69686e'
                            },
                            td_title: {
                                // margin: [left, top, right, bottom]
                                fontSize: 8,
                                margin: [0, 0, 0, 0],
                                
                                //darker
                                color: '#423f4d'
                            },
                            defaultStyleBolded: {
                                color: '#0c0d0d',
                                OpenSansBolded:true
                            }



                        },
                        defaultStyle: {
                            // margin: [left, top, right, bottom]
                            fontSize: 8,
                            margin: [0, 0, 0, 5],
                            "font": "OpenSans",
                            //greyish
                            color: '#69686e'
                        },
                        content: []
                    };
                    var pdfMake = window.pdfMake;
                    pdfMake.fonts = {

                        // download default Roboto font from cdnjs.com
                        Roboto: {
                            normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                            bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                            italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                            bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
                        },
                        OpenSans:
                        {
                            normal:'https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0B4gaVQUwaEQbjA.woff',
                            OpenSansBolded:'https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0B4gaVQUwaEQbjA.woff',
                            OpenSansItalic:'https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVQUwaEQbjB_mQ.woff'
                        }
                    };
                    setTimeout(function() {
                        $('.busy-load-container').hide();
                        //$('.collapsible-header').eq(0).trigger('click');

                        
                    // $('.download_pdf').unbind('click');
                    $('.download_pdf').click(function() {
                        console.log('download country pdf')
                        json_pdf.content=[];
                        json_pdf.content.push({ "text": 'PORTLEX', style: 'portlex', alignment: 'center' })

                        json_pdf.content.push({ text: $('.rfmo_name').text(), style: 'country' });


                                var basic_info_container = $('.basic_info')
                                var link = basic_info_container.find('a').attr('href')
                                //[left, top, right, bottom] 
                                json_pdf.content.push({ text: ' ', margin: [0, 10, 0, 0] });
                                json_pdf.content.push({ text: [{ text: ' ', margin: [0, 10, 0, 10] },{ style: 'link', text: 'BASIC INFORMATION WEBSITE', link: link }] });
                
                
                                var basic_info_container = $('.agreement_info');
                                var link = basic_info_container.find('a').attr('href');
                                var text = basic_info_container.find('a').text().toUpperCase();
                                json_pdf.content.push({ text: ' ', margin: [0, 5, 0, 0] });
                                json_pdf.content.push({ text: 'Agreement/Convention establishing the RFMO' }) 
                                json_pdf.content.push({ style: 'link', text: text, link: link });

                        json_pdf.content.push({ text: 'Countries', style: 'header' });
                        if ($('.collapsible.countries .collapsible-body a').length > 0) {
                        
                        var x=[];
                            $('.collapsible.countries .collapsible-body a').each(function()
                            {
                                x.push( {
                                    text:$(this).text().trim(),
                                    link: 'https://portlex.review.fao.org/' + $(this).attr('href'),
                                    style:'link'
                                    },{text:' / '})
                            })
                                    console.info(x)
                                    //json_pdf.content.push({text:[{text:q.label+'  ',style:'search_label'},all]});

                                    json_pdf.content.push({text:x})
                                    console.info(json_pdf.content)


                        } else {
                            json_pdf.content.push('Missing countries')
                        }

                        json_pdf.content.push({ text: 'Most relevant legislation', style: 'header' });
                        if ($('.collapsible.most_relevant_legislation .collapsible-body a').length > 0) {
                            $('.collapsible.most_relevant_legislation .collapsible-body a').each(function() {
                                json_pdf.content.push({
                                    style: 'link',
                                    text: $(this).text(),
                                    link: $(this).attr('href')
                                })
                            });



                        } else {
                            json_pdf.content.push('Missing most relevant legislation')
                        }

                        json_pdf.content.push({ text: $('.result-list table:visible').length + ' records', style: 'header' });

                        $('.result-list table:visible').each(function(i, d) {
                            var table_info = {
                                style: 'table_style',
                                layout: 'exampleLayout',
                                table: {
                                    body: [],

                                    widths: ['30%', '70%'],

                                },


                            }


                            var th = $(this).find('thead')





                            $(this).find('tbody tr').each(function(i, d) {
                                var o = [];
                                var $tr = $(d);

                                $(d).find('td').each(function(i2, d2) {
                                    var $td = $(d2);

                                    if (i2 === 0) {
                                        o.push({ text: $(d2).text() })
                                    }

                                    if (i2 === 1) {

                                        if (i === 2) {

                                            var o2 = []
                                                //measurecitedinfull
                                            $td.find('span').each(function(i3, d3) {
                                                var t = $(d3).text();
                                                o2.push({ text: t, style: 'measurecitedinfull' })


                                            })

                                            o.push(o2)

                                        } else {
                                            if (i===1 || i === 5) {
                                                //otherReferenceHttp

                                                o.push({
                                                    style: 'link',
                                                    text: $td.text(),
                                                    link: $td.find('a').attr('href')
                                                })

                                            } else {
                                                if (i===0)
                                                {
                                                o.push({
                                                    style: 'defaultStyleBolded',
                                                    text: $td.text().toUpperCase()
                                                
                                                })
                                                }
                                                else
                                                {
                                                o.push($td.text())
                                                }
                                                
                                                

                                            }

                                        }
                                        //if ($('.react-expand-collapse__content').not('.react-expand-collapse--expanded'))
                                        // o.content=$(d2).text();


                                        table_info.table.body.push(o)
                                    }

                                })


                            })

                            json_pdf.content.push(table_info);


                        })
                        var e = [

                            {
                                columns: [{
                                        svg: window.fao_svg,
                                        //svg: zm
                                        width: 140,
                                        height: 45
                                            // height: 50
                                    },

                                    {
                                        "text": 'Created on ' + new Date().toLocaleDateString("en-EU"),
                                        style: 'date',
                                        alignment: 'right'
                                    }

                                ]
                            }
                        ];
                        for (var p in e) {

                            json_pdf.content.unshift(e[p])
                        }

                        var q_rfmo = window.location.search.replace('?', '');
                        pdfMake.createPdf(json_pdf).download(q_rfmo);


                    }) 
                    }, 1000)


                    $('.sidenav_react_buttons .collapsible-header.active').removeClass('active');
                    $('.sidenav_react_buttons .collapsible-header.rfmo').addClass('active');

                    //var x = jsonRFMO;
                    var x = this.state.rfmos;
                    console.log(x)
                    var RFMOname;
                    for (var i = 0; i < x.length; i++) {
                        if (x[i].code === this.state.RFMOacronym) {
                            RFMOname = x[i].en;
                            this.setState({ RFMOname: RFMOname }, function() {
                                console.log(this.state.RFMOname);
                            });

                        }
                    }

                    // ################### MOST RELEVANT QUERY ################################ 
                    const mostRelevantService = async() => {
                        console.log('created on dropdown RFMO main page')
                            //added by pere


                        var reqMR = {

                            "query": "repealed:(NOT REPEALED) AND organizationcode:(\"" + this.state.RFMOacronym + "\")  keyword:(962)  subjectselection:(FI)  typeoftext:(L | R | M)",


                            "requestOptions": {
                                "searchApplicationId": "searchapplications/1be285f8874b8c6b772c6e64850b1bb5"
                            }
                        };
                        const responseMR = await fetch(Config.apis.gcs, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(reqMR) });
                        const json = await responseMR.json();
                        console.warn(json)

                        this.setState({
                            itemsMR: json
                        })
                    // console.log(json);

                    }
                    //#############################################################################



                mostRelevantService();

                var acc = document.getElementsByClassName("accordionProfile");

                for (var i = 0; i < acc.length; i++) {
                    acc[i].addEventListener("click", function() {
                        this.classList.toggle("active");
                        var panel = this.nextElementSibling;
                        if (panel.style.display === "block") {
                            panel.style.display = "none";
                        } else {
                            panel.style.display = "block";
                            if (document.getElementsByClassName('divRouting')[0]) {
                                var y = document.getElementsByClassName('divRouting')[0].offsetHeight;
                                document.getElementsByClassName('footer')[0].style.top = y + 100 + 'px';
                            }
                        }
                    });
                }

                if (document.getElementsByClassName('divRouting')[0]) {
                    var y = document.getElementsByClassName('divRouting')[0].offsetHeight;
                    document.getElementsByClassName('footer')[0].style.top = y + 100 + 'px';
                }
                //end then()
            });
        })
                

        
    }
    //end of componentDidMount() 
    createMostRelevant(param) {

        console.log(param)

        var MostRelevant = [];
        const mr = param;

        if (mr.results) {
            if (mr.results.length > 0) {

                // MostRelevant=mr.results.map()
                var arr_f = ['titleOfText', 'faolexId'];

                for (var i = 0; i < mr.results.length; i++) {
                    if (mr.results[i] && mr.results[i].metadata.fields.length > 0) {

                        //  debugger
                        var fields = mr.results[i].metadata.fields.filter(d => arr_f.indexOf(d.name) > -1);

                        if (fields.length === 2) {
                            MostRelevant.push({
                                title: fields.filter(d => d.name === 'titleOfText')[0].textValues.values[0],
                                link: fields.filter(d => d.name === 'faolexId')[0].textValues.values[0]
                            })
                        } else {
                            alert('missing something')
                            
                        }

                        var _tempMR = mr.results[i].metadata.fields;
                        var title;
                        var link;

                    }
                }

                console.log(MostRelevant)
                return MostRelevant;
            }
        }
    }


    search_no() {
        var typeOfMeasures = [];
        var param = this.state.typeofmeasure;
        if (param) {
            for (var i = 0; i < param.length; i++) {

                typeOfMeasures.push(param[i].value);
            }
            var _tempQuery = {
                // keyword:'(962)',
                // subjectselection:'(FI)',
                // typeoftext:'(L | R)',
                rfmos: [{
                    label: this.state.RFMOname,
                    value: this.state.RFMOacronym
                }],
                typeofmeasure: []
            }
            console.log(_tempQuery)
            console.warn(JSON.stringify(typeOfMeasures))


            for (var j = 0; j < typeOfMeasures.length; j++) {
                console.log(typeOfMeasures[j])
                var x = {
                    label: typeOfMeasures[j],
                    value: typeOfMeasures[j]
                }
                _tempQuery.typeofmeasure.push(x)
            }
            console.warn(this)
            console.log(this.state)

            //* rfmo:(CCSBT)"
            /*
            RFMOacronym: "CCSBT"
            RFMOname: "Commission for the Conservation of Southern Bluefin Tuna (CCSBT)"
            isLoaded: false
            itemsMR:
            pagination: {current_page: 1, pages: {…}}
            resultCountEstimate: "0"
            resultCounts: {sourceResultCounts: Array(1)}
            start: 0
            ...
            */
            console.warn(_tempQuery)
            this.TOMprofile = _tempQuery;
            console.info(this.TOMprofile)
            var query = JSON.stringify(this.TOMprofile);

            console.log(btoa(query))
            window.location = "/advSearch?query=" + btoa(query) + "#R1";




        }
        //this.onSearchTOM();
        return;

    }

    reset() {
        this.setState({
            typeofmeasure: []
        })
    }

    onSearchTOM() {
        this.props.onSearchTOM(this.TOMprofile)
    }

    loadGenericApi = (api) => {

        return (inputValue, callback) => {
            let filterRegex = new RegExp(".*(\\b" + inputValue + ").*", "i");

            if (this.RFMO.length > 0) {
                this.typeoflegalinst = api.map(e => ({
                        label: e['en'],
                        value: e.id
                    }))
                    // callback(this.typeoflegalinst.filter(e => {
                callback(
                    this.typeoflegalinst.filter(e => {
                        return e.label.match(filterRegex) !== null;
                    }));

                return;
            }


            // fetch(api)
            //     .then(response => response.json())
            //     .then(data => {
            callback(
                //this.typeoflegalinst = data.map(e => ({
                this.typeoflegalinst = api.map(e => ({
                    label: e['en'],
                    value: e.id
                }))
            );

            //});
        }
    }

    onChange = (name, value) => {
        var _tempTOM = [];

        console.info(name, value)
        for (var i = 0; i < value.length; i++) {
            console.log("invoked with: " + name + " - " + value[i].value)
            _tempTOM.push({
                label: value[i].label,
                value: value[i].value
            });

        }

        this.setState({ TOMprofile: [] });
        this.setState({
            [name]: _tempTOM
        });
        console.warn(this.state)

    }



    render() {
        var rfmo = this.state.RFMOacronym;
        if (this.state.rfmos.length===0) return null;
        if (this.state.countries.length===0) return null;
        
 
        //var BasicInformation = jsonRFMO.map(function(item, index) {
            var BasicInformation = this.state.rfmos.map(function(item, index) {
            if (item.code === rfmo) {
                if (item.BasicInformation) {
                    var link = item.BasicInformation;
                    return ( <div key = { index }>


                        <div><a href={link} target="_blank" rel="noopener noreferrer">Basic information website</a> </div>
                         </div>
                    )



                } else {
                    return 'no info'
                }
            }
            return (null)
        })

       // var OfficialWebsiteHyperlink = jsonRFMO.map(function(item, index) {
        var OfficialWebsiteHyperlink = this.state.rfmos.map(function(item, index) {
            if (item.code === rfmo) {
                if (item.OfficialWebsiteHyperlink) {

                    var link = item.OfficialWebsiteHyperlink;
                    var name=item.OfficialWebsiteName;
                    return ( <div key = { index }>


                        <div className = 'title'> Agreement / Convention establishing the RFMO </div>
                        {/* <div>{name}</div> */}
                        <div><a href={link} target="_blank" rel="noopener noreferrer">{name}</a> </div> 
                        </div>
                    )



                } else {
                    return 'no info'
                }
            }
            return (null)

        })

      console.log(this.state)
      

        //var CountriesRFMO = jsonRFMO.map(function(item, index) {
        let countries= this.state.countries;  
        let portlex_iso3=countries.map(d=>d.iso3);
            var CountriesRFMO = this.state.rfmos.map(function(item, index) {
            if (item.code === rfmo) {

                
                console.warn(item)
                return (
                    item.countries_iso.map(function(country, key) {

                        //for (var i = 0; i < countries.length; i++) {
                          //  if (country === countries[i].iso3 || country === countries[i].country_code) {
                            if (portlex_iso3.indexOf(item.countries_iso[key])>-1)
                            {
                                return ( <div key = { key }>
                                    <a href = { "CountryProfile?" + country }
                                    target = '_blank'
                                    rel = "noopener noreferrer"> { item.countries_en[key] } </a> 
                                    </div>
                                )
                            }
                            else
                            {
                                return ( <div key = { key }>
                                    <a
                                    href="javascript:void(0);"> { item.countries_en[key] } </a> 
                                    </div>
                                )
                            }
                          //  }
                       // }
                       // return null
                    })
                )
            }
            return null;


        });

        var MostRelevant = this.createMostRelevant(this.state.itemsMR);
        var listMostRelevant = MostRelevant ? MostRelevant.map(function(item, index) {
            return ( <div key = { index }>
                <a style = {
                    { fontWeight: "bold" }
                }
                href = { 'http://www.fao.org/faolex/results/details/en/c/' + item.link }
                target = '_blank'
                rel = "noopener noreferrer"> { item.title } </a> 
                </div>
            );
        }) : '';





        return (

            <div className = "parent-item">

            <div className = "parent-item">

            <div class = "rfmo_name">

            
            <h5> { this.state.RFMOname } </h5>
            </div>

            </div> 
            <div className = "rfmo_collapsibles">
            
            <div class = "basic_info"> { BasicInformation } </div> 
            <div class = "agreement_info"> { OfficialWebsiteHyperlink } </div> {
         
        }



        
        <ul className = "collapsible countries">
            
            <li>
            
            <div class = "collapsible-header">
            
            <span className = 'col s11 title'> MEMBERS </span>

        
        <span className = "col s1 valign-wrapper"> < i class = "material-icons"> keyboard_arrow_down </i></span>
            
            </div> 
        <div class = "collapsible-body">

            { CountriesRFMO }

        </div> </li> 
            </ul>

        
        <ul className = "collapsible most_relevant_legislation">
            
            <li>
            
            <div class = "collapsible-header">
            
            <span className = 'col s11 title'> Most Relevant Legislation </span>

        
        <span className = "col s1 valign-wrapper"> < i class = "material-icons"> keyboard_arrow_down </i></span>
            
            </div> <div class = "collapsible-body">

            { listMostRelevant }

        </div> </li> </ul> </div>

            
            
            
            <div className='row valign-wrapper download_order'>
                <div className='col s3'>
                    <div className="download_pdf btn btn-small">Download PDF</div>
                </div>

                <div className='col s9'>
                    <div>Records ordered by </div>
                    <div>
                <form id="order_form" action="#">
    
                    <label id="test1">
                        <input id="test1" class="with-gap" name="group1" value="yearoftext" type="radio" checked />
                        <span>Year of text</span>
                    </label>
                    
                    
                    <label id="test2">
                        <input id="test2" class="with-gap" name="group2" value="mostrelevant" type="radio" />
                        <span>Relevance</span>
                    </label>
                
                </form>
                </div>
    </div>
                </div>
            <div className = "query_results_count_wrapper">
            <div className="psm_title">Provisions on PSM</div>
            <div className = "query_results_count"> < span> </span> records</div>
            <div className = "query_results_count_filtered"> Visualizing < span className = "counts"> </span> filtered records</div>
            </div> <div class = 'result-list'>

            </div>


        <div style = {
                { height: '200px' }
            }>

            </div>

        </div>
    )
}
}

export default RFMOprofile;