import React, {
    Component,
} from 'react';
import AsyncSelect from 'react-select/lib/Async';
import {
    FormattedMessage
} from 'react-intl';



/**
 * customization of react-select for FAO documentation here: https://react-select.com/home
 * @prop {function} loadOptions Function that returns a promise, which is the set of options to be used once the promise resolves.
 * @prop {function} onChange callback receiving the selected values
 * @prop {string} name the name of the property
 */
class FaoSelect extends Component {
   

    constructor(props) {
        super(props);
        
        switch (props.name)
        {
            case 'countrySearch': this.modif_placeholder="Select 2 or more countries"; break;
            case 'rfmoSearch': this.modif_placeholder="Select 2 or more RFMOs";break;
            case 'Search': this.modif_placeholder="Select up to X measures";break;
            default:this.modif_placeholder="Select some options"; break;
        }
      
        
        // console.info(props)
        this.state = {
            value: this.props.value
        };
    }

    /**
     * creates the style object for react-select
     *
     * @returns {object} the style object
     */
    customStyles = () => ({
        // menu: (provided, state) => ({
        //     ...provided,
        //     width: state.selectProps.width,
        //     borderBottom: '1px dotted pink',
        //     color: state.selectProps.menuColor,
        //     padding: 20,
        //   }),
          option: (provided, state) => ({
            ...provided,
            borderBottom: '1px solid grey',
            // maxHeight:'20px',
            // maxWidth:'100px',
             cursor: 'pointer',
            // '&:hover': { color:'red',backgroundColor: '#c916c3' },
            // color: 'white',
            // '&:hover': { color: 'white',backgroundColor: '#0f6cac' },
            // fontSize:'0.8rem',
            // // normal state
            // color: state.isSelected ? '#c916c3' : '#0e3c63',
            // backgroundColor: state.isFocused ? '#0f6cac' : 'white',
            // backgroundColor:'white',
            padding: 8,
            textAlign: "left",
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
            cursor:'pointer'
          }),
      
          input: (provided, state) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorSeparator: state => ({
            display: 'none',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
          }),
          placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: '#607d8b',
                fontSize:'0.9rem'
                // transform: 'translateY(0%)',
                // top:'none'
            }
        },
        control: (provided,state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '30px',
            height: '30px',
            boxShadow: state.isFocused ? null : null,
            // maxHeight:'20px',
            // maxWidth:'100px',
    // provided.maxHeight= "35px";
            // if (this.props.width) {
            //     provided.width = this.props.width;
            // }
            // return provided;
        })
    })


    /**
     * handle the change of the input and call the callback function of the parent
     *
     * @param {object} selectedOption the selected option 
     */
    handleChange = (selectedOption) => {
        //alert('handleChange')
        this.setState({
            value: selectedOption
        });
        this.props.onSelect(this.props.name, selectedOption);
    }


    /**
     * handle the change of the value property from the parent
     *
     * @param {object} prevProps the previous props object
     */
    componentDidUpdate = (prevProps) => {
       // var $ = require( "jquery" );
        
        let value = "";

        if (this.props.value !== prevProps.value) {
            if (!this.props.value) {
                value = "";
            } else {
                value = this.props.value
            }

            this.setState({
                value: value
            });
        }
    }

    render() {
  
        return ( <FormattedMessage id = "SearchApp.form.fields.faoSelect.placeholder" > 
        {
                placeholder =>
                <AsyncSelect
                classNamePrefix="my_faoSelect"
                //isMulti={true}
                isMulti
                cacheOptions
                defaultOptions
               // placeholder = { this.props.placeholder || placeholder }
               placeholder={this.modif_placeholder || this.props.placeholder}
                className="fao_select_container"
                styles = { this.customStyles() }
                loadOptions = { this.props.loadOptions }
                onChange = { this.handleChange }
                value = { this.state.value }
                />
        } 
            </FormattedMessage>
        );
    }
}

export default FaoSelect;