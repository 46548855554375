import React, {
    Component
} from 'react';
//import FaoAutosuggest from '../fields/FaoAutosuggest.js';

import FaoSelect from '../UI-FAO/fields/FaoSelect.js';
import FaoInputText from '../UI-FAO/fields/FaoInputText';
import Config from '../config.js';
import {
    injectIntl,
    FormattedMessage
} from 'react-intl';


import FaoMultiCheckbox from '../UI-FAO/shared/FaoMultiCheckbox.js';
import FaoCheckbox from '../UI-FAO/shared/FaoCheckbox';
import FaoSingleSelect from '../UI-FAO/shared/FaoSingleSelect';
import URLQueryHelper from '../URLQueryHelper.js';

// import countriesLOCAL from '../json/geo_new_portlex_countries.json';
import typeoflegalinstrLOCAL from '../json/typeoflegalinstr.json';
import vesselbyflagLOCAL from '../json/vesselbyflag.json';
import typeofmeasureLOCAL from '../json/typeofmeasure.json';
// import rfmoLOCAL from '../json/RFMO_new.json';
import speciesLOCAL from '../json/species.json';


/**
 * shows the fields of the advanced searc
 * @prop {string} lang the language
 * @prop {function} toggleFn parent function to call on toggle btn click 
 * @prop {function} onReset callback for reset btn
 * @prop {function} onSearch callback for search btn
 *
 */
class PubAdvSearch extends Component {



    sortOptions = [
        { value: 'relevance', label: 'By relevance' },
        { value: 'yearoftext', label: 'By year' },
    ];

    formattingOptions = [
        { value: 'full', label: 'Full' },
        { value: 'short', label: 'Short' },
    ];
// width: "75px", 

    dateInputStyle = { height:'25px',color:'#0f6cac',width:'45px',display:'inline-block',fontSize:'0.9rem',marginRight: "5px" }
    countries = [];
    compCountries = [];
    languages = [];
    rfmos = [];
    competenceAreaOptions = [];
    vesselbyflag = [];
    competencecountries = [];
    competencerfmos = [];
    competenceAreaGeneral = [];
    species = [];

    initialState = {
        //added by Pere
        // keyword:'(962)',
        // subjectselection:'(FI)',
        // typeoftext:'(L | R)',
        // query:"",
        countries: [],
        compCountries: [],
        rfmos: [],
        competenceAreaOptions: [],
        remote_countries: [],
        remote_compCountries : [],
        remote_rfmos: [],
        remote_competenceAreaGeneral: [],
      

        yearfrom: "",
        yearto: "",
        reference: "",
        typeoflegalinst: [],
        vesselbyflag: [],
        competencecountries: [],
        competencerfmos: [],
        competenceAreaGeneral: [],
        typeofmeasure: [],
        mostrelevant: "",
        allacquaticspecies: "",
        inforce:"Yes",
        //inforce: "No", //it seems to be correctly added on advanced Search, but not on initial search
        species: [],
        // BELOW PROPERTIES INTERFERE WITH THE GLOBAL FUNCTIONS
        resultformat: this.formattingOptions[0].value,
        // sortorder: this.sortOptions[0].value,

        //by default it is yearoftext
       sortorder: this.sortOptions[1].value,
        externalQueryProcessed: false

    };


    constructor(props) {
        
        super(props);
        console.log(this,props)
       if (props.className.indexOf('comparing'))
       {
           console.warn('comparing from advSearch.js')
       }
        
        this.state = { ...this.initialState };

        let query = URLQueryHelper.getUrlQuery();
        console.warn(query)
     

        if (query !== null) {
            let queryState = PubAdvSearch.jsonCopy(this.initialState);
            console.warn(queryState)
            let jquery = JSON.parse(atob(query.split('#')[0]));
            console.info('jquery '+JSON.stringify(jquery),'query '+query,'initialState'+JSON.stringify(this.initialState) )
            console.warn('only executed when querying from main page!')
            for (let val in jquery) {
                console.log(val,jquery,jquery[val])
                queryState[val] = jquery[val];
            }
            console.log(queryState)
            
            this.state = { ...queryState };
            this.state.comparing=false;
            console.warn(this.state)



        }
    }

    async componentDidMount() {        

        
        
        let url='https://faolex.fao.org/docs/json/PortlexCP.json';
        const response = await fetch(url)
        let dataFromApi = await response.json();
        this.setState({remote_countries:dataFromApi.document.filter(d=>d.iso3)});
        this.setState({remote_compCountries:dataFromApi.document.filter(d=>d.country_code)});
      
            let url2='https://faolex.fao.org/docs/json/RFMO.json';
            const response2 = await fetch(url2)
            let dataFromApi2 = await response2.json();
            this.setState({remote_rfmos:dataFromApi2.document.filter(d=>d.code)});
       
            this.setState({remote_competenceAreaGeneral:[
            
                {"code": "All waters", "en": "All waters"},
                {"code": "EU waters", "en": "EU waters"},
                {"code": "High seas", "en": "High seas"},
                {"code": "National waters", "en": "National waters"},
                {"code": "non-EU waters", "en": "non-EU waters"},
                {"code": "non-national waters", "en": "non-national waters"},
            ]});
               

    }

    static jsonCopy(src) {
        return JSON.parse(JSON.stringify(src));
    }



    /**
     * handle the change of the input and call the callback function of the parent
     *
     * @param {string} name name of the input
     * @param {string|object} value value of the input
     */
    onChange = (name, value) => {
        
        console.log("invoked with: " + name + " - " + JSON.stringify(value))
     
        this.setState({ [name]: value });
        console.log(this)
    }


    async onImmediateChange(name, value) {
        await this.setState({ [name]: value });
    }

    /**
     * loads the countries from the service
     *
     * @param {string} inputValue the value typed by the user
     * @param {function} callback callback to return the data to FaoSelect
     */
    loadCountries = (inputValue, callback) => {
        let filterRegex = new RegExp(".*(\\b" + inputValue + ").*", "i");

        if (this.countries.length > 0) {

            
           // console.log(this.countries)
            callback(this.countries.filter(e => {
                
               /*  console.warn(e) */
                return e.label.match(filterRegex) !== null;
            }));

            return;
        }


        //fetch(Config.apis.countries.replace("{lang}", 'en'))
        //.then(response => response.json())
        //.then(datcountriesLOCALa => {
        if (this.state.remote_countries.length > 0) { //FF
            callback(
                //this.countries = data.map(e => ({
                this.countries = this.state.remote_countries.map(e => ({
                    label: e['en'],
                    //we have to use iso3 for the queries!
                    value: e.iso3
                  //value: e.country_code
                }))
            );
        } //FF
        //});
    }


    /**
 * loads the rfmo from the json
 *
 * @param {string} inputValue the value typed by the user
 * @param {function} callback callback to return the data to FaoSelect
 */
    loadRfmos = (inputValue, callback) => {
        let filterRegex = new RegExp(".*(\\b" + inputValue + ").*", "i");

        if (this.state.remote_rfmos.length > 0) {
            this.rfmos = this.state.remote_rfmos.map(e => ({
                label: e['en'],
                value: e.code
            }))
            // callback(this.rfmos.filter(e => {
            callback(this.rfmos.filter(e => {
                return e.label.match(filterRegex) !== null;
            }));

            return;
        }
    }

      /**
     * loads the countries from the service for competence Area per country using a different parameter to filter than Iso3
     *
     * @param {string} inputValue the value typed by the user
     * @param {function} callback callback to return the data to FaoSelect
     */
      loadCompetenceAreaCountries = (inputValue, callback) => {
        let filterRegex = new RegExp(".*(\\b" + inputValue + ").*", "i");


        if (this.compCountries.length > 0) {

            
           // console.log(this.countries)
            callback(this.compCountries.filter(e => {
                
               /*  console.warn(e) */
                return e.label.match(filterRegex) !== null;
            }));

            return;
        }
        //fetch(Config.apis.countries.replace("{lang}", 'en'))
        //.then(response => response.json())
        //.then(datcountriesLOCALa => {
        if (this.state.remote_countries.length > 0) { //FF
            callback(
                //this.countries = data.map(e => ({
                this.compCountries = this.state.remote_countries.map(e => ({
                    label: e['en'],
                    //we have to use iso3 for the queries!
                    value: e.country_code
                  //value: e.country_code
                }))
            );
        } //FF
        //});
    }

    /**
 * loads the competenceAreaGeneral from the json
 *
 * @param {string} inputValue the value typed by the user
 * @param {function} callback callback to return the data to FaoSelect
 */
    loadCompetenceAreaGeneral = (inputValue, callback) => {
        callback([ {label: "All waters", value: "All waters"},
        {label: "EU waters", value: "EU waters"},
        {label: "High seas", value: "High seas"},
        {label: "National waters", value: "National waters"},
        {label: "non-EU waters", value: "non-EU waters"},
        {label: "non-national waters", value: "non-national waters"},]);
//console.log(this.competenceAreaGeneral);
            return;
        }

    

    /**
* loads the typeoflegalinst from the json
*
* @param {string} inputValue the value typed by the user
* @param {function} callback callback to return the data to FaoSelect
*/
    loadSpecies = (inputValue, callback) => {
        if (inputValue === undefined || inputValue.length < 3) {
            return;
        }
     
        this.typeoflegalinst = [];
        let param = inputValue
        let _tempSpecies = speciesLOCAL.map(e => ({
            label: e['en'],
            value: e.code
        }))
        for (var i = 0; i < _tempSpecies.length; i++) {
            if (_tempSpecies[i].label.indexOf(param) !== -1) {
                this.typeoflegalinst.push({
                    label: _tempSpecies[i].label,
                    value: _tempSpecies[i].value
                });
            }
        } callback(this.typeoflegalinst);

    }

    /**
* loads the typeoflegalinst from the json
*
* @param {string} inputValue the value typed by the user
* @param {function} callback callback to return the data to FaoSelect
*/
    loadTypeoflegalinst = (inputValue, callback) => {
        let filterRegex = new RegExp(".*(\\b" + inputValue + ").*", "i");

        if (this.countries.length > 0) {
            this.typeoflegalinst = typeoflegalinstrLOCAL.map(e => ({
                label: e['en'],
                value: e.id
            }))
            //callback(this.typeoflegalinst.filter(e => {
            callback(
                this.typeoflegalinst.filter(e => {
                    return e.label.match(filterRegex) !== null;
                }));
            return;
        }

        // fetch(Config.apis.typeoflegalinst)
        //     .then(response => response.json())
        //     .then(data => {
        //callback(

        // this.typeoflegalinst = data.map(e => ({
        //     label: e['en'],
        //     value: e.id
        // }))
        //);
        //});
    }

    /**
* loads the generic values in format id/lang from an api (json)
*
* @param {string} inputValue the value typed by the user
* @param {function} callback callback to return the data to FaoSelect
*/
    loadGenericApi = (api) => {

        return (inputValue, callback) => {
            console.warn(inputValue)
            let filterRegex = new RegExp(".*(\\b" + inputValue + ").*", "i");

            if (this.countries.length > 0) {
                this.typeoflegalinst = api.map(e => ({
                    label: e['en'],
                    value: e.id
                }))
                // callback(this.typeoflegalinst.filter(e => {
                callback(
                    this.typeoflegalinst.filter(e => {
                        return e.label.match(filterRegex) !== null;
                    }));

                return;
            }


            // fetch(api)
            //     .then(response => response.json())
            //     .then(data => {
            callback(
                //this.typeoflegalinst = data.map(e => ({
                this.typeoflegalinst = api.map(e => ({
                    label: e['en'],
                    value: e.id
                }))
            );

            //});
        }
    }




    /**
     * loads the languages from the service
     *
     * @param {string} inputValue the value typed by the user
     * @param {function} callback callback to return the data to FaoSelect
     */
    loadLanguages = (inputValue, callback) => {
        let filterRegex = new RegExp(".*(\\b" + inputValue + ").*", "i");

        if (this.languages.length > 0) {
            callback(this.languages.filter(e => {
                return e.label.match(filterRegex) !== null;
            }));

            return;
        }

        fetch(Config.apis.languages)
            .then(response => response.json())
            .then(data => {
                let options = [];
                for (let key in data) {
                    if (data.hasOwnProperty(key)) {
                        options.push({
                            label: data[key]['en'],
                            value: key
                        });
                        if (key === 'es') {
                            options.push({
                                label: "- - - - - - - - - - - -",
                                value: key,
                                isDisabled: true
                            });
                        }
                    }
                }
                this.languages = options;
                callback(options);
            });
    }



    /**
     * reset the state to clear the form
     *
     */
    resetState = () => {
        window.location.reload(false);
       /*  this.setState(this.initialState);
        this.props.onReset(this.initialState); */
    }

    /**
     * event fired when the user click on search btn
     *
     */
    onSearch = () => {
        console.warn(this)
        //this.props.onSearch locaated in component.SearchBox.js!!
        
        this.props.onSearch(this.state);
    }

    onFormUpdate = () => {

        this.props.onFormUpdate(this.state);

    }

    /* Copy to clipboard */
    copyToClipboard() {
        /* Get the text field */
        var copyText = document.getElementById("queryState");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");

        /* Alert the copied text */
        alert("The query link has been copied to clipboard");
    }


    render() {
        const { intl } = this.props;
        if(this.state.remote_rfmos.length===0 || this.state.remote_countries.length===0) 
        return null;
        
      /*   console.log(JSON.stringify(this.state)) */

        //const currentURL = window.location.href + '?query=' + btoa(JSON.stringify(this.state));

  

        if (this.props.querySearchCompare !== undefined
            && this.props.querySearchCompare !== ""
            && ((this.props.querySearchCompare.countries !== undefined
                && this.props.querySearchCompare.countries !== this.state.countries)
                || (this.props.querySearchCompare.rfmos !== undefined
                    && this.props.querySearchCompare.rfmos !== this.state.rfmos)
                || (this.props.querySearchCompare.typeofmeasure !== undefined
                    && this.props.querySearchCompare.typeofmeasure !== this.state.typeofmeasure))
            && !this.state.externalQueryProcessed) 
            {
            //  this.onChange("rfmos", this.props.querySearchCompare.rfmos);
                debugger;
            this.setState({
                details: {
                    countries: this.props.querySearchCompare.countries,
                    rfmos: this.props.querySearchCompare.rfmos,
                    typeofmeasure: this.props.querySearchCompare.typeofmeasure,
                    //typeofmeasure:'*',
                    externalQueryProcessed: true
                }
            }, this.onFormUpdate());
            this.setState({ externalQueryProcessed: true });

        }



        return (


<div>
            <div id="adv_search row">
                <div className="row">
            <div class="col s6">
            <div className="fdr_query select_order row valign-wrapper">
<div className="fdr_label col s3">Free text all fields</div>
                <div className="fdr_text col s8 valign-wrapper">
                <FormattedMessage id="SearchApp.PubSearchBox.form.fields.query.placeholder" >
                                {placeholder =>
                                    <FaoInputText id="query_free" size="32" maxLength="256" autoComplete="off" placeholder={placeholder} name="query_free" 
                                    onChange={this.onChange} value={this.state.query_free} />
                                }
                                </FormattedMessage> 
                                <span style={{display:'inline'}}><i class="material-icons info tooltipped" data-position="top" data-tooltip="Search in all fields. Can be combined with other parameters of  the search form. Retrieves by default documents, both on all aquatic  species and on specific species, that are in force">error</i></span>       
                </div>

</div>
</div>
<div class="col s6"></div>
</div>

                <div style={{ padding: 'unset' }} class="col s6">

       
                <div className="fdr_query select_order row valign-wrapper">
                <div className="fdr_label col s3"><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.sortorder" /></div>
                <div className="fdr_text col s8">
                    <FaoSingleSelect
                        name="sortorder"
                        // width="575"
                        options={this.sortOptions}
                        onSelect={this.onChange}
                        value={this.state.sortorder}
                    />
                </div>
                </div>

             

                

            <div className="fdr_query country row valign-wrapper">
                <div className="fdr_label  col s3"><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.countries" /></div>
                <div className="fdr_text  col s8">
                    <FaoSelect
                        name="countries"
                        // // width="575"
                        // //loadOptions={this.loadCountries}
                        // loadOptions={countriesLOCAL}
                        // onSelect={this.onChange}
                        // value={countriesLOCAL}

                        // loadOptions={this.loadCountries}
                        // onSelect={this.onChange}
                        // value={this.state.countries}


                        loadOptions={this.loadCountries}
                        onSelect={this.onChange}
                        value={this.state.countries}
                    />
                </div>
                </div> 

                

                <div className="fdr_query rfmo row valign-wrapper">
                <div className="fdr_label col s3"><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.rfmo" /></div>
                <div className="fdr_text col s8">
                    <FaoSelect
                        name="rfmos"
                        // width="575"
                        loadOptions={this.loadRfmos}
                        onSelect={this.onChange}
                        value={this.state.rfmos}
                    />
                </div>
                </div>

                <div className="fdr_query year row valign-wrapper">
                <div className="fdr_label pubYear col s3"><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.yearOfText" /></div>
                <div className="fdr_text pubYear col s8">
                    <FormattedMessage id="SearchApp.PubAdvSearch.form.fields.pubYearFrom" />
                <FaoInputText name="yearfrom" onChange={this.onChange} style={this.dateInputStyle} value={this.state.yearfrom} />
                    <FormattedMessage id="SearchApp.PubAdvSearch.form.fields.pubYearTo" />
                    <FaoInputText name="yearto" id="advyearto" onChange={this.onChange} style={this.dateInputStyle} value={this.state.yearto} />
                </div>
                </div>

                <div className="fdr_query words_reference row valign-wrapper">
                <div className="fdr_label col s3"><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.wordsfromreference" /></div>
                <div className="fdr_text col s8">
                    <FaoInputText name="reference" onChange={this.onChange} value={this.state.reference} />
                </div>
                </div>

                <div className="fdr_query legal_instrument row valign-wrapper">
                <div className="fdr_label pubToli col s3"><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.typeoflegalinstr" /></div>
                <div className="fdr_text pubToli col s8">
                    <FaoSelect
                        name="typeoflegalinst"
                        // width="575"
                        loadOptions={this.loadTypeoflegalinst}
                        onSelect={this.onChange}
                        value={this.state.typeoflegalinst}
                    />
                </div>
                </div>

                {/* end of col.s6 */}
                </div>  
                <div class="col s6">
                <div className="fdr_query typeofmeasure row valign-wrapper">
                <div className="fdr_label pubTypeofmeasure col s3"><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.typeofmeasure" /></div>
                <div className="fdr_text pubTypeofmeasure col s8">
                    <FaoSelect
                        name="typeofmeasure"
                        width="575"
                        // loadOptions={this.loadGenericApi(Config.apis.typeofmeasure)}
                        loadOptions={this.loadGenericApi(typeofmeasureLOCAL)}
                        onSelect={this.onChange}
                        value={this.state.typeofmeasure}
                    />
                </div>
                </div>

                <div className="fdr_query byvessel row valign-wrapper">
                <div className="fdr_label pubVesselbyflag col s3"><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.vesselbyflag" /></div>
                <div className="fdr_text pubVesselbyflag col s8">
                    <FaoSelect
                        name="vesselbyflag"
                        // width="575"
                        // loadOptions={this.loadGenericApi(Config.apis.vesselbyflag)}
                        loadOptions={this.loadGenericApi(vesselbyflagLOCAL)}
                        onSelect={this.onChange}
                        value={this.state.vesselbyflag}
                    />
                </div>
                </div>

                <div className="fdr_query pubCountry row valign-wrapper">
                <div className="fdr_label pubCountry col s3"><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.competencecountries" /></div>
                <div className="fdr_text pubCountry col s8">
                    <FaoSelect
                        name="competencecountries"
                        width="575"
                        loadOptions={this.loadCompetenceAreaCountries}
                        onSelect={this.onChange}
                        value={this.state.competencecountries}
                    />
                </div>
                </div>

                <div className="fdr_query pubRFMO row valign-wrapper">
                <div className="fdr_label pubRFMO col s3"><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.competencerfmos" /></div>
                <div className="fdr_text pubRFMO col s8">
                    <FaoSelect
                        name="competencerfmos"
                        // width="575"
                        loadOptions={this.loadRfmos}
                        onSelect={this.onChange}
                        value={this.state.competencerfmos}
                    />
                </div>
                </div>
                <div className="fdr_query pubRFMO row valign-wrapper">
                <div className="fdr_label pubRFMO col s3"><FormattedMessage id="SearchApp.labels.competencearea" /></div>
                <div className="fdr_text pubRFMO col s8">
                    <FaoSelect
                        name="competencearea"
                        // width="575"
                        loadOptions={this.loadCompetenceAreaGeneral}
                        onSelect={this.onChange}
                        value={this.state.competenceAreaGeneral}
                    />
                </div>
                </div>


                <div className="fdr_query mostrelevant row valign-wrapper">
                <div className="fdr_label col s3"><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.mostrelevant" /></div>
                <div className="fdr_check multi col s8">
                    <FaoCheckbox
                        name="mostrelevant"
                        label="SearchApp.PubAdvSearch.form.fields.label.yes"
                        isSelected={false}
                        onCheckboxChange={this.onChange}
                        selectedValue="Yes"
                        linkedValue={this.state.mostrelevant}
                    />
                </div>
                </div>
                <div className="fdr_query allacquaticspecies row valign-wrapper">
                <div className="fdr_label col s3"><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.allacquaticspecies" /></div>
                <div className="fdr_check multi col s8">
                <FaoMultiCheckbox
                        name="allacquaticspecies"
                        label="All aquatic species"
                        isSelected={true}
                        onCheckboxChange={this.onChange}
                        linkedValue={this.state.allacquaticspecies}
                        selectedValue="Yes"
                        
                    />

                <FaoMultiCheckbox
                        name="allacquaticspecies"
                        label="Specific species"
                        isSelected={true}
                        onCheckboxChange={this.onChange}
                        // linkedValue={this.state.allacquaticspecies}
                        selectedValue="No"
                        
                    />
               
                </div>
                </div>
                <div className="fdr_query internalcheck row valign-wrapper">
                <div className="fdr_label internalcheck col s3"><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.inforce" /></div>
                <div className="fdr_check multi col s8">
                    <FaoCheckbox
                        name="inforce"
                        label="SearchApp.PubAdvSearch.form.fields.label.yes"
                        isSelected={true}
                        onCheckboxChange={this.onChange}

                        // changed but not working... materializecss?
                        selectedValue="Yes"
                        linkedValue={this.state.inforce}
                    />
                    <FaoCheckbox
                        name="inforce"
                        label="SearchApp.PubAdvSearch.form.fields.label.no"
                        isSelected={false}
                        onCheckboxChange={this.onChange}
                        //before it was
                        // selectedValue="No"
                        selectedValue="No"
                        linkedValue={this.state.inforce}
                    />
                </div>
                </div>
                </div>
                </div>
                <div className="fdr_btns col s12">
                    <input className="col s2 offset-s3 btn btn-secondary" type="submit" id="advSearch" name="advSearch" value={intl.formatMessage({ id: 'SearchApp.PubAdvSearch.form.fields.searchBtn' })}
                     onClick={this.onSearch} />
                    <input className="col s2 offset-s3 btn btn-secondary" type="submit" id="resetSearch" name="resetSearch" value={intl.formatMessage({ id: 'SearchApp.PubAdvSearch.form.fields.resetSearchBtn' })} onClick={this.resetState} />

                    {/* <input className="col s2 offset-s1 btn btn-secondary" type="submit" id="copytoclip" name="copytoclip" value={intl.formatMessage({ id: 'SearchApp.PubAdvSearch.form.fields.share' })} onClick={this.copyToClipboard} /> */}

                </div>
                

                
                <div id="hideAdvSearch"><a href="/" onClick={this.props.toggleFn}><FormattedMessage id="SearchApp.PubAdvSearch.form.fields.hideBtn" /></a></div>

                {/* <textarea className="copyfrom" type="text" value={currentURL} id="queryState" aria-hidden='true' /> */}
            </div>

        );
    }
}

export default injectIntl(PubAdvSearch);