import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
// import './js_loaders.js';


// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import { HashLink as Link } from 'react-router-hash-link';
import { IntlProvider } from 'react-intl';
import translations from './i18n/locales.js';
import Home from './Home/component.home.js';
import Advsearch from './AdvSearch/component.searchApp.js'
import Autocomplete from './UI-FAO/Autocomplete/component.autocomplete.js';
// import RFMO from './json/RFMO_new';
// import Country_ISO3 from './json/geo_new_portlex_countries';
// import Geo_portlex from './json/geo_new_portlex_countries';
import CountryProfile from './CountryProfile/component.countryProfile.js';
import RFMOprofile from './RFMOprofile/component.RFMOprofile';

// import './json/html-react-parser.min.js';
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";

import M from 'materialize-css';
require("../src/App.css");

// require("../src/timberlex.css");
require("../src/new_styles_hover.css");
require("../src/faolex.css");
require("../src/busy_load.css");


 


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      countrySearchVal: [],
      rfmoSearchVal: [],
      tomSearchVal: [],
      freeTextSearchVal: '',
      value: this.props.value,
      showSearch: true,
      footerTopHeight: {
        top: '2000px'
      }
    };
    this.countrySearch = [];
    this.rfmoSearch = [];
    this.tomSearch = [];
    this.freeTextSearch = '';
    this.TOMprofile = '';
    this.querySearchCompare = '';
    this.footerTopHeight = '1500px';

    this.searchCompare = this.searchCompare.bind(this);
    this.reset = this.reset.bind(this);
    //this.geo_portlex=Geo_portlex;
    

  }
  


  async componentDidUpdate() 
  {

    fetch('https://faolex.fao.org/docs/json/PortlexCP.json')
      .then(res => res.json())
      .then(function (res)
      {
        var this_app={};
        var geo_portlex=res.document
        
        var $ = require( "jquery" );
        $('.busy-load-container').show();
      

        setTimeout(function()
        {
          $('.busy-load-container').hide();
          $('.top_map_info').animate({
            top: ($('header').height())+'px',
            //'100px',
            opacity: 1
            //
        }, 1000, function() {

            setTimeout(function() {

              console.warn('fading out')
              $('.top_map_info').fadeOut('slow');
            }, 6000)
        })
        },2000)  
    //#region map region
        if ($('#map').length>0 && $('.mapboxgl-marker').length === 0)
        {
          
          //console.log($('#map').width())
          //console.log($('#map').height())
        // ReactDOM.render(<map_app />, document.getElementById('map'));
        mapboxgl.accessToken="pk.eyJ1IjoicGVyaWt1dCIsImEiOiJVNzBjMl9FIn0.38swLsSY2ao8E8rU8FYgyw";
       // $('#map').width($('.countryListHeader').width())
        
        const map = new mapboxgl.Map({
            container: 'map', // container id
            //style: 'mapbox://styles/mapbox/streets-v8',
            style: {
            //    "glyphs": "./font/{fontstack}/{range}.pbf",
                "version": 8,
                "sources": {
                    "gaul_level_0_line_source": {
                        "type": "vector",
                        "tiles": [
                            "https://geospatial.jrc.ec.europa.eu/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=hotspots:gaul_0_simplified&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}"
                        ],
                    }
                },
                "layers": [{
                    "id": "gaul_0_simple",
                    "source": "gaul_level_0_line_source",
                    "source-layer": "gaul_0_simplified",
                    "type": "fill",
                    // "minzoom": 4,
                    // "maxzoom": 6,
                    'layout': {
                        'visibility': 'visible'

                    },
                    'paint': {
                        "fill-color": "#a2a7ab",
                        "fill-outline-color": "#87989c",
                        "fill-opacity": 0

                    }
                }]
            },
            center: [8.7, 12],
            maxZoom: 6,

            //center: [141.15448379999998, 39.702053　],
            zoom: 1,

            attributionControl: false
        });
        map.addControl(new mapboxgl.NavigationControl());     

        class map_legend_control {
            onAdd(map) {
                this.map = map;
                this.container = document.createElement('div');
                //this.container.className = 'click_country_control_container';
                this.container.className = 'map_legend_control_container';
    
                return this.container;
            }
            onRemove() {
                this.container.parentNode.removeChild(this.container);
                this.map = undefined;
            }
        }
        var legend_control_ctrl = new map_legend_control();
        map.addControl(legend_control_ctrl, 'bottom-right');
        $('.map_legend_control_container')
        .html('<div id="legend"><div class="row with_national_plan valign-wrapper active"><div class="col s3"></div><div class="col s9">PSMA party</div></div><div class="row no_national_plan active valign-wrapper"><div class="col s3"></div><div class="col s9">No PSMA party</div></div></div>');

       

        $('#legend').on('click',function(e)
        {
          var row=$(e.target).parents().closest('.row');
          
          if (row)
          {
            var $present_mk=$('.mapboxgl-marker.present');
            var $not_present_mk=$('.mapboxgl-marker.not_present');
            
            if (row.hasClass('with_national_plan'))
            {
              if (row.hasClass('active'))
              {
                row.removeClass('active');
                $present_mk.hide();
                
              }
              else

              {
                row.addClass('active');
                $present_mk.show();
              }
            }
            if (row.hasClass('no_national_plan'))
            {
              if (row.hasClass('active'))
              {
                row.removeClass('active');
                
                $not_present_mk.hide();
              }
              else
              {
                row.addClass('active');
                $not_present_mk.show();
              }
            }
          }
        });
     
   
        map.on('load', function() {
      
          $('#map').on('click', function(e) {
            console.log('clicking map')

            console.info($(e.target))
            if ($(e.target).hasClass('mapboxgl-popup-close-button')) {
                $('.mapboxgl-popup:visible').hide()
            }
            var marker=$(e.target).parents().closest('.mapboxgl-marker')
            
            if (marker && marker.length > 0) 
              {
            // if($(e.target).parents().closest('.mapboxgl-popup').length>0)
            // {
              $('.mapboxgl-popup').find('.country_popup div').show()
              
            }

            if ($(e.target).hasClass('explore_country')) {
              console.log('clicking explore_country')
              
              var parent = $(e.target).parents().closest('.country_popup')
              
              var code=parent.attr('adm_iso');
              var iso3=parent.attr('iso3_code');
           
              

              window.location ="/CountryProfile?"+iso3;
      

          }


          })
          map.addSource("fao", {
            "type": "raster",
            "tiles": ["https://geoservices.un.org/arcgis/rest/services/ClearMap_WebPlain/MapServer/tile/{z}/{y}/{x}"],

            'tileSize': 240
        });

        var fao = {

            'id': 'fao',
            'type': 'raster',
            'source': 'fao'

        }
        map.addLayer(fao)

         

          map.scrollZoom.disable();

          var counter = 0;
          this_app.markers = {};
          var counter = 0;
          var markerWidth = 27;
          var markerHeight = 41;
          var counter = 0;

        
      
      geo_portlex.forEach(function(data) {
        //   console.warn(data)
           
 
       //     "country_code": "ALB",
       // "iso3": "ALB",
       // "iso2": "AL",
       // "en": "Albania",
       // "fr": "Albanie",
       // "es": "Albania",
       // "ar": "ألبانيا",
       // "ru": "Албани",
       // "zh": "阿尔巴尼亚",
       // "psma_party": "20170407",
       // "national_plans": "No",
       // "measures": "5"
        if (!data.coordinates)
        {

          return false;
        }

      var l=new mapboxgl.LngLat(data.coordinates[0],data.coordinates[1]);
        if (!l)
        {
          debugger;
          console.warn(l)
        }
        

               var popup = new mapboxgl.Popup({
                 offset: {
                     'bottom': [0, (-markerHeight / 2) + 5]
                 }
             });
             var inner_popup = ''
                 if (data.psma_party) {
                     //var psma_text = 'Yes';
                     //green

                    //
                     var color = '#20ac20';
                     var _class='present';
 
                     //data.psma_party ===> yyyymmdd
                     var year=data.psma_party.substring(0,4);
                     var month=data.psma_party.substring(4,6);
                      var day=data.psma_party.substring(6,8);
                     
                     
                     inner_popup+=' <div class="row"><div class="col s8">PSMA Party</div><div class="col s4"><span class="new badge" data-badge-caption="">' + day+'/'+month +'/'+year+ '</span></div></div>'
                 } else {
                   //  var psma_text = 'No';
                   var _class='not_present';
                     //red
                     var color='#f22626';
                 }
                 if (data.psma_signatory) {
 
                   var year=data.psma_signatory.substring(0,4);
                     var month=data.psma_signatory.substring(4,6);
                      var day=data.psma_signatory.substring(6,8);
 
                   inner_popup+=' <div class="row"><div class="col s8">PSMA Signatory</div><div class="col s4"><span class="new badge" data-badge-caption="">' + day+'/'+month +'/'+year+ '</span></div></div>'
 
                 }
                 if (data.national_plans) {
                   //var psma_text = 'Yes';
                   //var color = 'green';
                   inner_popup+=' <div class="row"><div class="col s9">National plans</div><div class="col s3"><span class="new badge" data-badge-caption="">' + data.national_plans + '</span></div></div>'
               } else {
                 //  var psma_text = 'No';
               //   var color = 'red';
               }
               
               if (data.measures && data.measures>0) {
 
                 inner_popup+=' <div class="row"><div class="col s9">Number of measures</div><div class="col s3"><span class="new badge" data-badge-caption="">' + data.measures + '</span></div></div>'
               }
           //  inner_popup+='</div>';
     
             
 
                 var html = ' <div code="' + counter + '" adm_iso="' + data.country_code + '" iso3_code="' + data.iso3 + '" class="country_popup">' +
                     '<div class="adm_name">' + data.en + '</div>' + inner_popup + '<div class="explore_country">Explore</div></div>';
 
 
             //         var html = ' <div code="' + counter + '" adm_iso="' + data.properties.adm_0_iso + '" class="country_popup">';
             //         html += '<div class="adm_name">' + data.properties.adm_0_name + '</div>';
 
                     var simple_html = ' <div code="' + counter + '" adm_iso="' + data.country_code + '" iso3_code="' + data.iso3 + '" class="country_popup">';
                     simple_html += '<div class="adm_name">' + data.en+ '</div>';
 
                 popup.setHTML(html);
               
                 
                 this_app.markers[counter] = new mapboxgl.Marker()
                     //.setLngLat([data.lng, data.lat])
                     .setLngLat(data.coordinates)
                     .setPopup(popup)
                     .addTo(map)
 
             //     console.log('markers created')
 
                 var t_marker = this_app.markers[counter];
                 //t_marker.togglePopup();
                 // $('.mapboxgl-popup').hide();
                 var markerDiv = t_marker.getElement();
                 $(markerDiv).addClass(_class);
                 $(markerDiv).attr('code', counter);
                 $(markerDiv).attr('color', color);
                 
                 
                 markerDiv.addEventListener('click', function(e) {
                   var code = $(markerDiv).attr('code');
                   
                   this_app.markers[code].togglePopup();
 
                   var popup = new mapboxgl.Popup({
                       offset: {
                           'bottom': [0, -markerHeight / 2]
                       }
                   })
                   
                   popup.setHTML(html);
                   this_app.markers[code]
                       //.setLngLat([coords[0], coords[1]])
                       .setPopup(popup);
                   //this_app.markers[code].togglePopup();
 
 
               });
            
 
 
                 markerDiv.addEventListener('mouseenter', function(e) {
                   console.log(counter);
                   var code = $(markerDiv).attr('code');
                   console.warn(e)
                   console.warn(this_app.markers)
                       // var simple_popup = new mapboxgl.Popup();
                       // simple_popup.setHTML(simple_html);
                       // this_app.markers[code]
                       //     //.setLngLat([coords[0], coords[1]])
                       //     .setPopup(simple_popup);
 
                   var open = $('.mapboxgl-popup:visible').find('.country_popup');
                   console.log(open.length)
                   if (open.length> 0) {
                       open.each(function(d) {
 
 
                           var prev_code = $(this).attr('code');
                           console.log(prev_code)
                           this_app.markers[prev_code].togglePopup();
                       })
                   }
                   // this_app.throttle(
                   //         function() {
                   console.log('throttle code ' + code)
                   this_app.markers[code].togglePopup();
                   
               
 
               }, false);
                 markerDiv.addEventListener('mouseleave', function(e) {
                     // this_app.throttle(t_marker.togglePopup(), 100);
 
 
 
                 });
                 
                 counter = parseInt(counter) + 1;
                 
               });
 
               $('.mapboxgl-marker svg').attr('height', '20px');
             $('.mapboxgl-marker svg').attr('width', '15px');
             var present_mk_red=false;
             var present_mk_green=false;
             $('.mapboxgl-marker').each(function(d)
             {
               var color=$(this).attr('color');
 
               $(this).find('svg> g g').attr('fill', color);
               
               if (!present_mk_red || !present_mk_green)
               {
                 //var clone=jQuery('#map svg').eq(0).clone();

                


               if (color==='#20ac20' && present_mk_green===false)
               {
                 var clone=$(this).find('svg').clone();
                 $('#legend .row').eq(0).find('.col.s3').append(clone);
                 present_mk_green=true;
               }
               if (color==='#f22626' && present_mk_red===false)
               {
                 var clone=$(this).find('svg').clone();
                 
                 $('#legend .row').eq(1).find('.col.s3').append(clone);
                 present_mk_red=true;
               }
             }
             })
        
     
           

        
        
          });
          //end map on load

     

        // #35ac55
        // red
        // #ff5722
        // <div id="legend"><div class="row"><div class="col s3"></div><div class="col s9">With national plan</div></div></div>')
      }

    })
  //#endregion
  }
  
  componentDidMount() {
    // const script = document.createElement("script");    script.async = true;    //script.src = "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.70/pdfmake.min.js";    
    // script.src ='./pdfmake.min.js';
    // this.div.appendChild(script);  
    // alert(%REACT_APP_global%)

    // this.getSuggestions();
    // console.log(this)
    var this_app={};
    var $ = require( "jquery" );
    $('.App .facet-nav').hide();
    
  
    var elems = document.querySelectorAll('.collapsible');
        
   //console.log(elems.length+' collapsibles from App.js')
    
    var instances = M.Collapsible.init(elems);  

    $('.busy-load-container').hide();

 

    if (document.getElementsByClassName('divRouting')[0]) {
      this.setState({
        footerTopHeight: {
          top: document.getElementsByClassName('divRouting')[0].offsetHeight + 100 + 'px'
        }
      })

    }


  }

  cleanQuerySearchCompare = () => {
    this.setState({ querySearchCompare: "" })
  }

  loadGenericApi = (api) => {
   console.warn(api);
    return (inputValue, callback,...args) => {
      console.log ("arguments", args)
      let filterRegex = new RegExp(".*(\\b" + inputValue + ").*", "i");


      //var formalGreeting = (arr) =>  arr.map(name => "Hello " + name);
      if (filterRegex) {
     

        //var func = (x, y) => { return x + y; }; 
        this.typeoflegalinst = api.map((e) => {

        
         return {
          label: e['en'],
          value: e.id
        }

        })

        callback(
          this.typeoflegalinst.filter(e => {
           // console.info(e.label.match(filterRegex))
            {
              /*
                0: "Restrictions on landing/transhipment"
                1: ""
                groups: undefined
                index: 0
                input: "Restrictions on landing/transhipment"
                length: 2
              */
            }
            let _t=e.label.match(filterRegex) !== null;
          //  console.log(_t)
            return _t;
          }));

        return;
      }


      // fetch(api)
      //     .then(response => response.json())
      //     .then(data => {
      callback(
        //this.typeoflegalinst = data.map(e => ({
        this.typeoflegalinst = api.map(e => ({
          label: e['en'],
          value: e.id
        }))
      );

      //});
    }
  }

  // loadCountries = (inputValue, callback) => {
  //   let filterRegex = new RegExp(".*(\\b" + inputValue + ").*", "i");

  //   if (this.countrySearch.length> 0) {
  //     callback(this.countrySearch.filter(e => {
  //       return e.label.match(filterRegex) !== null;
  //     }));

  //     return;
  //   }
   


      
  //   if (Country_ISO3.length> 0) { //FF
      
  //     callback(
  //       //this.countries = data.map(e => ({
  //       this.countrySearch = Country_ISO3.map(e => ({
  //         label: e['en'],
  //         value: e.iso3
  //       }))
  //     );
  //   } //FF
  //   //});
  // }

  onChangeCountry = (name, value) => {
    var _tempCountry = [];
    console.info(name,value)
    for (var i = 0; i < value.length; i++) {
      _tempCountry.push({
        label: value[i].label,
        value: value[i].value
      });

    }
    
    //alert(_tempCountry[0].value)
    //this.setState({ TOMprofile: [] });
    
    console.log(_tempCountry)
    this.setState({ countrySearchVal: _tempCountry});
    //[0].value ∫
  }

  onChangeRFMO = (name, value) => {
    var _tempRFMO = [];
    for (var i = 0; i < value.length; i++) {
      _tempRFMO.push({
        label: value[i].label,
        value: value[i].value
      });

    }
    //this.setState({ TOMprofile: [] });
    this.setState({ rfmoSearchVal: _tempRFMO });
  }

  onChangeTOM = (name, value) => {
    var _tempTOM = [];
    for (var i = 0; i < value.length; i++) {
      _tempTOM.push({
        label: value[i].label,
        value: value[i].value
      });

    }
    this.setState({ TOMprofile: [] });
    this.setState({ tomSearchVal: _tempTOM });
  }

  onChangeFreeTextSearch = (event) => {
    this.setState({
      freeTextSearchVal: event.target.value,
    })
  }

  onSearchTOMprofileFromCountry(param) {
    this.TOMprofile = param;
    document.getElementById('advSearchID').click();
  }

  onSearchTOMprofileFromRFMO(param) {
    this.TOMprofile = param;
    document.getElementById('advSearchID').click();
  }

  searchCompare() {
    var q = {};
    var paramCountry = this.state.countrySearchVal;
    var paramTOM = this.state.tomSearchVal;
    var paramRFMO = this.state.rfmoSearchVal;
    // var paramFreeText = this.state.freeTextSearchVal
    console.log(paramCountry)
    

    q = {
     // query: paramFreeText,
      countries: paramCountry,
      rfmos: paramRFMO,
      typeofmeasure: paramTOM,
      searchCompare :true
    }

    this.querySearchCompare = q
    this.TOMprofile = '';
    this.setState({ showSearch: true })
    //window.location = document.getElementById('resultsearch').href + "
    //comparing=true&
    window.location ="/advSearch?query=" + btoa(JSON.stringify(this.querySearchCompare)) + "#R1";
    //document.getElementById('resultsearch').click();

    return;

  }

  reset() {
    this.setState({
      tomSearchVal: [],
      countrySearchVal: [],
      rfmoSearchVal: []
     // freeTextSearchVal: ''
    })
  }

  render() {


    return (
      <IntlProvider locale={'en'} key={this.props.lang} defaultLocale="en" messages={translations['en']}>
        <div className="App">
        
          <Router basename={`${process.env.PUBLIC_URL}`}>
          
            <header className="header row">

                <div className="col s2 m2 left">
                <a href='https://www.fao.org/home/en/' target='_blank' rel="noopener noreferrer" >
                {/*  <i className="material-icons dp48 home">home</i> */}
                <img src="./fao-logo-white-3lines-en.svg" style={{  height: 45 }} alt="FAO logo" />
                  </a>
                        </div>
                        <div className="col s10"><div className="main_title">Port-Lex</div><span>DATABASE ON PORT STATE MEASURES TO PREVENT, DETER AND ELIMINATE ILLEGAL, UNREPORTED AND UNREGULATED (IUU) FISHING
                        </span>
                      </div>


            </header>

            <div className="page-content row" style={{ width:'100%' }}>
              <div id="verticalMenu" className="sidenav_react vertical-menu col s2">
<div className="sidenav_react_buttons">
              <ul className="collapsible">
              <li>
    <div className="collapsible-header home active"><Link to="/" onClick={() => this.setState({ showSearch: true })}>Port-Lex Home</Link></div>
    </li>
    <li>
      <div className="collapsible-header cp">Country/Territory profile</div>
      <div className="collapsible-body" key={"country-profile-button"} >
      <div className="panel">
                  <Autocomplete onClick={() => this.setState({ showSearch: true })}
                  autoHighlight
                  openOnFocus
                  autoComplete
                  autoFocus
                    suggestions={[]}
                    options={[]}
                    typeOfList={"country"}
                  />
                </div>
      </div>
    </li>

    <li>
    <div className="collapsible-header rfmo">RFMO profile</div>
      <div className="collapsible-body" key={"rfmo-profile-button"} >
      <div className="panel">
      <Autocomplete
                    suggestions={[]}
                    typeOfList={"RFMO"}
                  />
                </div>
      </div>
    </li>

  
    <li>
    <div className="collapsible-header adv_search"> <Link id="advSearchID" to="/advSearch" onClick={() => this.setState({ showSearch: true,search_from_link:true })}>Advanced Search</Link></div>
    </li>
                </ul>
               
                </div>
                <div className="facet-nav">
                <div className='facet-nav-info valign-wrapper'>
                         <span>Click on a value below to refine the filter</span>
                         <span><i className="material-icons info tooltipped" data-position="top" data-tooltip="Selected filters are in OR within each field and in AND across the different fields">error</i></span>
                </div>
                <div className="content"></div>
                </div>


              </div>
              <div className="divRouting col s10">
  {/* A <Switch> looks through its children <Route>s and  renders the first one that matches the current URL. */}
                <Switch>
                  <Route exact path="/">
                    <Home className='proving' />
                  </Route>
                  <Route path="/advSearch">
                    <Advsearch c={this.state.countrySearchVal} className='prova comparing' TOM={this.TOMprofile} querySearchCompare={this.querySearchCompare} cleanQuerySearchCompare={this.cleanQuerySearchCompare} />
                  </Route>
                  <Route path="/CountryProfile">
                    <CountryProfile className='prova' onSearchTOM={this.onSearchTOMprofileFromCountry.bind(this)} />
                  </Route>
                  <Route path="/RFMOprofile">
                    <RFMOprofile className='prova' onSearchTOM={this.onSearchTOMprofileFromRFMO.bind(this)} />
                  </Route>
                </Switch>



              </div>
            </div>



          </Router>
<div className="row">
  <div style={{padding: '20px', height: '100px' }}>
          <div style={this.state.footerTopHeight} className='col s10 offset-s2 footer'>
            To assist us in our efforts to provide comprehensive, up-to-date, and reliable information, please advise us of
             any errors and omissions, by sending an email to faolex@fao.org. Any other queries can be addressed to the same
              email address
          <div className="col s12 center contact">
               <div className="col s1 offset-s5"><img alt="FAO" src="./css/Contact_Icon.png"/>
               </div>
                                        <div className="col s3 offset-s4">Contact to
                                            <a href="mailto:faolex@fao.org"> faolex@fao.org</a>
                                        </div>
                                    </div>
          </div>
          </div>
          </div>
          <div className="row footer_extra_container">
        <div className="footer_extra valign-wrapper">
            <div className="col s10">
                <span className="footer_span"><a href="https://www.fao.org/contact-us/en/ " target="_blank ">Contact us</a></span>
                <span className="footer_span"><a href="https://www.fao.org/contact-us/terms/en/" target="_blank ">Terms and conditions</a></span>
                <span className="footer_span" ><a href="https://www.fao.org/contact-us/scam-alert/en/ " target="_blank ">Scam Alert</a></span>
                <span className="footer_span"><a href="https://www.fao.org/aud/69204/en" target="_blank ">Report Misconduct</a></span>
            </div>
            <div className="col s2">&#169;FAO, 2022</div>
        </div>
    </div>

        </div>
      </IntlProvider>
    );
  }

};


export default App;
