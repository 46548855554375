import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

require("./component.autocomplete.css");


class Autocomplete extends Component 
{
    static propTypes = {
        suggestions: PropTypes.instanceOf(Object)
    };

    static defaultProps = {
        suggestions: []
    };

    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInput: "",
            // The Type of the List in Suggestion
            typeOfList: props.typeOfList,
            // the relative id of the sugestions
            idSuggestion: props.id,
            countries:[],
            rfmos:[]
        };
        
    }



    async componentDidMount() 
    {
        
        
        if (this.state.typeOfList === "country") 
        {
        //this.getSuggestions();

        

        
        
        let url='https://faolex.fao.org/docs/json/PortlexCP.json';
        const response = await fetch(url)
        var dataFromApi = await response.json();
        this.setState({countries:dataFromApi.document.filter(d=>d.iso3)});
        }
        else
        {
            let url='https://faolex.fao.org/docs/json/RFMO.json';
            const response = await fetch(url)
            var dataFromApi = await response.json();
            this.setState({rfmos:dataFromApi.document.filter(d=>d.code)});
        }
        
        console.warn(this.state)
    }
    onChange = e => {
        
        var listArray = [];
        
        
        if (this.state.typeOfList === "country")// || this.state.typeOfList === "RFMO") {
            
        {
            for (var i = 0; i < this.state.countries.length; i++) {
                //listArray.push(this.props.suggestions[i].en);
                listArray.push(this.state.countries[i].en);
            }
           
            // for (var i = 0; i < this.props.suggestions.length; i++) {
            //     //listArray.push(this.props.suggestions[i].en);
            //     listArray.push(this.props.suggestions[i].en);
            // }
            //const { suggestions } = this.props;
        }

        if (this.state.typeOfList === "RFMO")// || this.state.typeOfList === "RFMO") {
            
        {
            
            for (var i = 0; i < this.state.rfmos.length; i++) {
                //listArray.push(this.props.suggestions[i].en);
                listArray.push(this.state.rfmos[i].en);
            }
           
            // for (var i = 0; i < this.props.suggestions.length; i++) {
            //     //listArray.push(this.props.suggestions[i].en);
            //     listArray.push(this.props.suggestions[i].en);
            // }
            //const { suggestions } = this.props;
        }
        const suggestions = listArray;
        
        

        this.setState.typeOfList = this.props.typeOfList;
        const userInput = e.currentTarget.value;

        console.log(suggestions)
        console.warn(userInput)

        
        const typeList=this.state.typeOfList
       
        // Filter our suggestions that don't contain the user's input
        const filteredSuggestions = suggestions.filter(function(sugg)
        {
         
            if (sugg)
            {
                if (typeList === "RFMO")
                {
                    if (sugg.toLowerCase().indexOf(userInput.toLowerCase()) >-1)
                    return true
                }
                else
                {
                    if (sugg.toLowerCase().indexOf(userInput.toLowerCase())===0)
                    return true
                }
            }

        });

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value
        });
        
    };

    onClick = e => {
        
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText
        });
        if (this.state.typeOfList === "country") {
            /*
            for (var i = 0; i < countriesISO3.length; i++) {
                if (countriesISO3[i].en === e.currentTarget.innerText) {

                    if (!countriesISO3[i].iso3)
                    window.location.replace(`${process.env.PUBLIC_URL}/CountryProfile?` + countriesISO3[i].country_code); // if is a country profile
                    else
                    window.location.replace(`${process.env.PUBLIC_URL}/CountryProfile?` + countriesISO3[i].iso3); // if is a country profile
                    return;
                }
            }
            */
            for (var i = 0; i < this.state.countries.length; i++) {
                if (this.state.countries[i].en === e.currentTarget.innerText) {

                    if (!this.state.countries[i].iso3)
                    window.location.replace(`${process.env.PUBLIC_URL}/CountryProfile?` + this.state.countries[i].country_code); // if is a country profile
                    else
                    window.location.replace(`${process.env.PUBLIC_URL}/CountryProfile?` + this.state.countries[i].iso3); // if is a country profile
                    return;
                }
            }

        }
        // if (this.state.typeOfList === "TOM") {
        //     window.location.replace(window.location.origin + "/?" + e.currentTarget.innerText); // if is a type of measure
        // }
        if (this.state.typeOfList === "RFMO") {
            
            var rfmo_id=this.state.rfmos.filter(function(d)
            {              
                return d.en===e.currentTarget.innerText
            })[0].code;
            
            window.location.replace(`${process.env.PUBLIC_URL}/RFMOProfile?` + rfmo_id); // if is a RFMO profile
            //+ _tempRFMO[1]); // if is a RFMO profile
        }

    };
 

//     onmousedown = e => {
// alert('mousedown')
//     }
//     onfocus = e => {
//         alert('onfocus')
//             }
    onKeyDown = e => {
        const { activeSuggestion, filteredSuggestions } = this.state;

        // User pressed the enter key
        if (e.keyCode === 13) {
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion]
            });
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    render() {
        const {
            // onmousedown,
            // onfocus,
            onChange,
            onClick,
            onKeyDown,
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput
            }
        } = this;
        if (this.state.typeOfList==='RFMO' && this.state.rfmos.length===0) return null;
        if (this.state.typeOfList==='country' && this.state.countries.length===0) return null;
        let suggestionsListComponent;

        // setTimeout(function()
        // {
        //     this.searchInput.focus();
                      
    
        //    // handleClick()
        // },1000)
    
        if (userInput==='')
        {
            var listArray = [];
            

            if (this.state.typeOfList === "country")
            { 
                
                //console.log(this.props.suggestions)
                
                //this.state.countries is 0 length
               // console.log(this.state.countries)
                
                for (var i = 0; i < this.state.countries.length; i++) {
                    //listArray.push(this.props.suggestions[i].en);
                    listArray.push(this.state.countries[i].en);
                }
                //const { suggestions } = this.props;
            }
            else
            {
                for (var i = 0; i < this.state.rfmos.length; i++) {
                    //listArray.push(this.props.suggestions[i].en);
                    listArray.push(this.state.rfmos[i].en);
                }
            }
        
            const suggestions = listArray;
            suggestionsListComponent = (
                <ul className="suggestions">
                    {suggestions.map((suggestion, index) => {
                        let className;
                        
                        // Flag the active suggestion with a class
                        // if (index === activeSuggestion) {
                        //     className = "suggestion-active";
                        // }

                        return (
                            <li className={className} key={suggestion} onClick={onClick}>
                                {suggestion}
                            </li>
                        );
                    })}
                </ul>
            );
        }
        else
        { 
            

            if (showSuggestions && userInput) {
              if (filteredSuggestions.length) {
                  suggestionsListComponent = (
                    <ul className="suggestions">
                        {filteredSuggestions.map((suggestion, index) => {
                            let className;
                            console.log(suggestion)
                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li className={className} key={suggestion} onClick={onClick}>
                                    {suggestion}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No suggestions</em>
                    </div>
                );
            }
        }
    }
        return (
            <Fragment>
                <input
                    type="text"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    className="placeholder"
                    placeholder="Type here for searching"
                    value={userInput}
                 //value='all'
                    ref={(input) => { 
                        // debugger
                        console.log(input);
                        this.searchInput = input; }}
                />
                {suggestionsListComponent}
            </Fragment>
        );
    }
}

export default Autocomplete;
