
class URLQueryHelper {


    static getUrlQuery() {

        return URLQueryHelper.getUrlParam('query', null);;
    }

    static getUrlParam(parameter, defaultvalue) {
        var urlparameter = defaultvalue;
        if (window.location.href.indexOf(parameter) > -1) {
            urlparameter = URLQueryHelper.getUrlVars()[parameter];
        }
        return urlparameter;
    }

    static getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        console.log(parts);
        return vars;
    }

}

export default URLQueryHelper;