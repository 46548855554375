import Config from '../../../config.js';

/**
 * Class to create the query for the service
 */
class PubQueryBuilder {


    /**
     * format a value for the query params
     *
     * @static
     * @param {object} value the value to be formatted
     * @returns {string} the query param
     */

    static formatParamValue(value) {
        let r = "";
        if (!value || (typeof value !== "object") || !value.value || !value.value.trim()) {
            return "";
        }

        r = value.value.replace(/\./g, "%2E");
        if (r.includes(" ")) {

            r = "\"" + r + "\"";
        }

        return r;
    }

    static formatParamValueTypeofMeasure(value, key) {

        let r = "";


        // {
        //     "label": "Designation of ports",
        //     "value": "Designation of ports"
        // }




        //typeoflegalinstrument:(Bilateral agreement) OR typeoflegalinstrument:(National legislation)

        //currently
        //typeofmeasure:(\"Designation of ports\" | \"Landing/transhipment authorization\") inforce:(Yes)"
        if (!value || (typeof value !== "object") || !value.value || !value.value.trim()) {
            return "";
        }


        if (key === 'allaquaticspecies' && value.value === 'Yes') {

        } else {
           // r = key + ':(' + value.value.replace(/\./g, "%2E") + ') ';
           r = key + ':("' + value.value + '") ';
        }
        // if (r.includes(" ")) {

        //     r = "\"" + r + "\"";
        // }

        return r;
    }

    /**
     * create a multiple value param for the query
     *
     * @param {string} key the key of the param
     * @param {array(object)} (optional) values the values of the param
     * @returns {string} the param formatted
     */
    static buildArrayParam(key, values) {
        // if (key == 'typeofmeasure')



        let rvalues = "";

        if (!key || !values || !Array.isArray(values) || !values.length) {
            return "";
        }

        //values
        // [
        //     {
        //         "label": "Designation of ports",
        //         "value": "Designation of ports"
        //     },
        //     {
        //         "label": "Landing/transhipment authorization",
        //         "value": "Landing/transhipment authorization"
        //     }
        // ]

        //key == 'typeofmeasure' || typeoflegalinstrument
        // if (key == 'sss') {
        if (key === 'typeofmeasure' || key === 'typeoflegalinstrument') {

            if (key === 'typeofmeasure') {
                var vals = values.map(d => '"'+d.value+'"')
            
                var pos = vals.indexOf('Prior notification of port entry/landing/transhipment');
                if (pos > -1) {
                    values[pos].value = 'Prior notification of port entry'
                }

                var pos = vals.indexOf('Restrictions on landing/transhipment');
                if (pos > -1) {
                    
                    values[pos].value = '"Restrictions on landing/transhipment"'
                }
             

            }

            // [
            //             {label: "Port entry authorization"
            // value: "Port entry authorization"}
            // ]
            rvalues = values
                .map((e) => PubQueryBuilder.formatParamValueTypeofMeasure(e, key))
                .filter((e) => e)
                .join(" OR ");
        } else {


            if (key !== 'allaquaticspecies') {
              
              
                rvalues = values
                    .map((e) => PubQueryBuilder.formatParamValue(e))
                    .filter((e) => e)
                    .join(" | ");

            } else {

            }
        }

        if (!rvalues) {
            return "";
        }
        //key == 'typeofmeasure' || 
        if (key === 'typeofmeasure' || key === 'typeoflegalinstrument') {

            console.warn(rvalues)
                // return ` ${key}:(${rvalues})`;
            return ' (' + rvalues + ')';
        } else {

            if (key !== 'allaquaticspecies') {

if(key === 'competencearea' 
&& rvalues.includes('National waters') 
&& rvalues.includes('EU waters') 
&& !rvalues.includes('non-national waters')
&& !rvalues.includes('non-EU waters')){
    console.log("enters here")
    return ` ${key}:(${rvalues}) NOT competencearea:("non-EU waters" | "non-national waters")`;
} else if(key === 'competencearea' && rvalues.includes('National waters') && !rvalues.includes('non-national waters')) {
    console.log("enters here")
    return ` ${key}:(${rvalues}) NOT competencearea:("non-national waters")`;
} else if(key === 'competencearea' && !rvalues.includes('non-EU waters') && rvalues.includes('EU waters'))  {
    console.log("enetered correctly")
    return ` ${key}:(${rvalues}) NOT competencearea:("non-EU waters") `;
}

                
                return ` ${key}:(${rvalues})`;
}
         else {

            }
        }

    }

    /**
     * create a multiple value param for the query
     *
     * @param {string} key the key of the param
     * @param {array(object)} (optional) values the values of the param
     * @returns {string} the param formatted
     */
    static buildArrayParamAndApply(key, values, toapply) {
        let rvalues = "";

        if (!key || !values || !Array.isArray(values) || !values.length) {
            return "";
        }

        rvalues = values
            .map(e => toapply(e))
            .map((e) => PubQueryBuilder.formatParamValue(e))
            .filter((e) => e)
            .join(" | ");

        if (!rvalues) {
            return "";
        }


        return ` ${key}:(${rvalues})`;
    }


    /**
     * create a single value param for the query
     *
     * @param {string} key the key of the param
     * @param {string|number} (optional) values the values of the param
     * @returns {string} the param formatted
     */
    static buildStringParam(key, values) {
        let rvalues = "";

        if (!(key || "").trim() || !(values || "").trim()) {
            return "";
        }

        rvalues += "" + values;

        return ` ${key}:(${rvalues})`;
    }


    /**
     * validate a year and parse it to number
     *
     * @static
     * @param {string} year the year from the input
     * @returns {number} the year if the input is valid, null otherwise
     */
    static parseYear(year) {
        year = (year || "").trim();

        if (/^\d{4}$/.exec(year)) {
            return parseInt(year);
        }

        return null;
    }
    static buildArrayParamOr(obj) {

        var q = ' (';

        console.log(obj)
        for (var p in obj) {
            var o = obj[p];
            if (p === 'countries' && o) {
                var l = o.length;
                if (l > 0) {
                    for (var x in o) {
                        q += 'country:(' + o[x].value + ') ';
                        if (x < l - 1)
                            q += '| ';

                    }
                }
                //q+='country:('+o[x]+')';
            }
            if (p === 'rfmos' && o) {

                var l = o.length;
                if (l > 0) {
                    // if (o['rfmo'].length > 0)
                    //     q += ' | '



                    for (var x in o) {
                        q += 'rfmo:(' + o[x].value + ')';
                        if (x < l - 1) {
                            q += ' | ';
                        }
                    }
                } else {
                    q += 'rfmo:(' + o[0].value + ')'
                }

            }
            if (p === 'organizationcode' && o) {


                var l = o.length;
                if (l > 0) {
                    // if (o['organizationcode'].length > 0)
                    //     q += ' | '



                    for (var x in o) {
                        q += 'organizationid:(' + o[x].value + ')';
                        if (x < l - 1) {
                            q += ' | ';
                        }
                    }
                } else {
                    q += 'organizationid:(' + o[0].value + ')'
                }

            }
        }
        q += ')';
        //(\"POL\")

        // let rvalues = "";

        // if (!key || !values || !Array.isArray(values) || !values.length) {
        //     return "";
        // }

        // rvalues = values
        //     .map((e) => PubQueryBuilder.formatParamValue(e))
        //     .filter((e) => e)
        //     .join(" | ");

        // if (!rvalues) {
        //     return "";
        // }
        console.warn(q)
        return q;
        //return '('+` ${key}:(${rvalues})`+')';
    }

    /**
     * Create the query object to pass to the service
     *
     * @param {object} formData form input values
     * @param {array} selectedFacets the selected facets
     * @returns {string} the object ready to send to the service
     */
    static createQueryParams = (formData, selectedFacets, startRecord = 0) => {

        let yearto, yearfrom, q = '',
            f;


        formData = (formData || {});

      
        console.log(formData)


        selectedFacets = (selectedFacets || []);
        f = formData;


        yearfrom = PubQueryBuilder.parseYear(f.yearfrom);
        yearto = PubQueryBuilder.parseYear(f.yearto);


        q += (formData.query_free || "*");


        if (f.rfmos && f.rfmos.length > 0) {
            //q += PubQueryBuilder.buildArrayParamOr({ "countries": f.countries, "organizationcode": f.rfmos })
            q += PubQueryBuilder.buildArrayParamOr({ "rfmos": f.rfmos })
        }

        if (f.countries && f.countries.length > 0) {
            q += ' | ';
            //q += PubQueryBuilder.buildArrayParamOr({ "countries": f.countries, "organizationcode": f.rfmos })
            q += PubQueryBuilder.buildArrayParamOr({ "countries": f.countries })
        }



        q += (yearfrom) ? ` yearoftextfrom:${yearfrom}` : "";
        q += (yearto) ? ` yearoftextto:${yearto + 1}` : "";
        q += PubQueryBuilder.buildArrayParam("typeoflegalinstrument", f.typeoflegalinst);

        /*if(f.vesselbyflag != null){
            f.vesselbyflag = f.vesselbyflag.map(e => e.replace("%20", " "));
        }*/
        q += PubQueryBuilder.buildArrayParam("vesselbyflag", f.vesselbyflag);
        q += PubQueryBuilder.buildArrayParam("competencecountrycodeop", f.competencecountries);
        q += PubQueryBuilder.buildArrayParam("competencerfmoop", f.competencerfmos);
        q += PubQueryBuilder.buildArrayParam("competencearea", f.competencearea);

        if (f.typeofmeasure) {
            q += PubQueryBuilder.buildArrayParam("typeofmeasure", f.typeofmeasure);
        }

        //q += '*';
        q += PubQueryBuilder.buildStringParam("mostrelevant", f.mostrelevant);

        //q += PubQueryBuilder.buildArrayParam("allaquaticspecies", f.allaquaticspecies);
        //  q += ' allaquaticspecies:(Yes) ';
        if (f.allacquaticspecies && f.allacquaticspecies.length > 0) {
            if (f.allacquaticspecies.length > 1) {
                q += " (allaquaticspecies:(Yes) OR  allaquaticspecies:(No)) ";
            } else {
                q += " allaquaticspecies:(" + f.allacquaticspecies + ") ";
            }
        }
        //  q += ' ';
        //     q += 'allaquaticspecies:(Yes)';
        // if (f.allacquaticspecies == '') {
        //     //  q += " "; //(∑| allaquaticspecies:('No')) ";
        //     q += " (allaquaticspecies:(Yes) |  allaquaticspecies:(No)) ";

        // } else {

        //     if (f.allacquaticspecies == 'Yes')
        //         q += ' allaquaticspecies:(Yes) ';
        //     else
        //         q += " (allaquaticspecies:(Yes) |  allaquaticspecies:(No)) ";
        // }
        //  q += PubQueryBuilder.buildArrayParam("allaquaticspecies", f.allacquaticspecies);
        q += PubQueryBuilder.buildStringParam("inforce", f.inforce);
        q += PubQueryBuilder.buildArrayParam("speciescode", f.species);
        q += PubQueryBuilder.buildStringParam("reference", f.reference);

        console.log(selectedFacets)
            // q += selectedFacets.map((e) => ` ${e.operatorName}:(${e.fullItem.includes(" ") ? "\"" + e.fullItem + "\"" : e.fullItem})`).join("");
        q += selectedFacets.map(function(d) {

                console.info(d)
                if (d.operatorName === 'typeofmeasure') {
                    return d.operatorName + ':' + d.fullItem
                } else {
                    if (d.fullItem.includes(" "))
                        return d.operatorName + ':"\""' + d.fullItem
                }

            }).join("")
            //q = "\"CCAMLR keyword:(962)  subjectselection:(FI)  typeoftext:(L | R)";
            // q =  "* keyword:(962)  subjectselection:(FI)  typeoftext:(L | R)"


        console.warn(q)
        var filterlogic;
        // Default filter
        for (var cfilter in Config.filterConfig) {

            if (window.location.href.indexOf(Config.filterConfig[cfilter].key) > -1) {

                filterlogic = Config.filterConfig[cfilter].filter;
                break;
            }
        }

        if (!(filterlogic === undefined)) {
            console.log(filterlogic)
            for (var operator in filterlogic) {

                if (filterlogic[operator].values !== undefined) {
                    q += ` ${filterlogic[operator].defaultOperator}:(`;
                    for (var value in filterlogic[operator].values) {
                        q += `${filterlogic[operator].values[value]}`;

                        if (value < (filterlogic[operator].values.length - 1)) {
                            q += ` | `;
                        }
                    }

                    q += `)`;
                }


            }
            /* if(!(Config.facetDefaultSettings.defaultOperator === undefined) && 
                 !(Config.facetDefaultSettings.defaultValue === undefined))
 
                 q+= ` ${Config.facetDefaultSettings.defaultOperator}:(${Config.facetDefaultSettings.defaultValue})`;*/
        }

        console.warn(q)



        return { query: q, obj: { 'test': 'empty' } };
    }

    /**
     * creates the data to send to the request for the service
     *
     * @param {object} formData form input values
     * @param {array} selectedFacets the selected facets
     * @returns {object} the data, null if errors or empty formData
     */
    static createRequestData = (formData, selectedFacets, sortOption, startRecord = 0) => {
        let r;
        formData = (formData || {});
        selectedFacets = (selectedFacets || []);

        console.log(sortOption);


        r = {
            "query": PubQueryBuilder.createQueryParams(formData, selectedFacets).query,
            //https://developers.google.com/cloud-search/docs/reference/rest/v1/settings.searchapplications  ??
            //https://developers.google.com/cloud-search/docs/reference/rest/v1/query/search#google.apps.search.v1.QueryService.Search
            // "SourceCrowdingConfig":
            // {
            //     "numResults":200
            // },
            "pageSize": 100,

            "requestOptions": {


                "searchApplicationId": Config.PwsSearchApplicationId,
            },
            "facetOptions": [{
                    "operatorName": "country",
                    "numFacetBuckets": 200
                }, {
                    "operatorName": "rfmo",
                    "numFacetBuckets": 100
                },
                {
                    "operatorName": "typeofmeasure",
                    "numFacetBuckets": 100
                },

                {
                    "operatorName": "typeoflegalinstrument",
                    "numFacetBuckets": 100
                }, {
                    "operatorName": "allaquaticspecies",
                    "numFacetBuckets": 100
                }, {
                    "operatorName": "vesselbyflag",
                    "numFacetBuckets": 100
                }, {
                    "operatorName": "competencearea",
                    "numFacetBuckets": 100
                }, {
                    "operatorName": "yearoftext",
                    "numFacetBuckets": 100
                }
            ],
            "queryInterpretationOptions": {
                "enableVerbatimMode": true
            },
            "start": startRecord

        };

        // r.sortOptions = {
        //     //    "operatorName": sortOption,
        //    "operatorName": "yearoftext",
        //     //working
        //     //"operatorName": "mostrelevant",
        //     "sortOrder": "DESCENDING"
        // }
        if (sortOption === "yearoftext")
        {
            formData.sortorder = "yearoftext"
        }
        else
        {
            formData.sortorder = "relevance"
        }

        if (sortOption === "yearoftext")
        // || (formData.sortorder === "yearoftext")) {
            {
            r.sortOptions = {
                "operatorName": "yearoftext",
                "sortOrder": "DESCENDING"
            }
        } else {

            r.sortOptions = {
                "operatorName": "mostrelevant",
                "sortOrder": "DESCENDING"
            }
        }
        //  else {
        //     r.sortOptions = {
        //         "operatorName": "mostrelevant",
        //         "sortOrder": "DESCENDING"
        //     }
        // }

        // if ((sortOption === "reference") || (formData.sortorder === "reference")) {
        //     r.sortOptions = {
        //         "operatorName": "reference",
        //         "sortOrder": "DESCENDING"
        //     }
        // }

        if (!r.query) {
            return null;
        }
        console.info(r)


        return r
            //  return { query: r, initial_params: formData };
    }

}

export default PubQueryBuilder;