import React, {
  Component,
} from 'react';
import {
  FormattedMessage
} from 'react-intl';

/**
 * customization of simple select for FAO documentation here: https://react-select.com/home
 * @prop {function} onChange callback receiving the selected values
 * @prop {string} name the name of the property
 * @prop {string} label the label related to the checkbox
 * @prop {bool} isSelected true if the start state of the checkbox is selected
 * @prop {string} value the value to pass to the operator
 */

class FaoMultiCheckbox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isSelected: this.props.isSelected
    };
  }

  /**
   * handle the change of the input and call the callback function of the parent
   *
   * @param {object} selectedOption the selected option 
   */
  handleChange = () => {
    
    console.log("state changed from " + this.state.isSelected+' to '+!this.state.isSelected)

    if (!this.state.isSelected) {
      this.props.onCheckboxChange(this.props.name, this.props.selectedValue);
    } else {
      //if false...
      this.props.onCheckboxChange(this.props.name, null);
    }
    this.setState({
      isSelected: !this.state.isSelected
    });


  }


  render() {

    return (
      <label>
        <input
          type="checkbox"
          name={this.props.name}
          checked={this.state.isSelected}
          onChange={this.handleChange}
          className="form-check-input filled-in"
        />
        <FormattedMessage id={this.props.label} />
      </label>

    );
  }

}

export default FaoMultiCheckbox;