import React, {
    Component,
} from 'react';
import Select from 'react-select';



/**
 * customization of react-select for FAO documentation here: https://react-select.com/home
 * @prop {function} loadOptions Function that returns a promise, which is the set of options to be used once the promise resolves.
 * @prop {function} onChange callback receiving the selected values
 * @prop {string} name the name of the property
 */
class FaoSingleSelect extends Component {

    constructor(props) {
        //console.log(props)
        super(props);
        this.state = {
            value: this.props.value
        };
    }

    /**
     * creates the style object for react-select
     *
     * @returns {object} the style object
     */
    customStyles = () =>  ({
        // menu: (provided, state) => ({
        //     ...provided,
        //     width: state.selectProps.width,
        //     borderBottom: '1px dotted pink',
        //     color: state.selectProps.menuColor,
        //     padding: 20,
        //   }),
          option: (provided, state) => ({
            ...provided,
            
            // maxHeight:'20px',
            // maxWidth:'100px',
            borderBottom: '1px dotted grey',
            // maxHeight:'20px',
            // maxWidth:'100px',
            fontSize:'0.9rem',
            cursor: 'pointer',
            '&:hover': { backgroundColor: '#F5F5F5' },
            color: state.isSelected ? 'red' : '#367CB8',
            padding: 8,
            textAlign: "left",
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
            cursor:'pointer'
          }),
      
          input: (provided, state) => ({
            ...provided,
            margin: '0px',
          }),
          indicatorSeparator: state => ({
            display: 'none',
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
          }),
          placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: '#607d8b',
                fontSize:'3rem'
                // transform: 'translateY(0%)',
                // top:'none'
            }
        },
        control: (provided,state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '30px',
            height: '30px',
            boxShadow: state.isFocused ? null : null,
            // maxHeight:'20px',
            // maxWidth:'100px',
    // provided.maxHeight= "35px";
            // if (this.props.width) {
            //     provided.width = this.props.width;
            // }
            // return provided;
        })
    })


    /**
     * handle the change of the input and call the callback function of the parent
     *
     * @param {object} selectedOption the selected option 
     */
    handleChange = (selectedOption) => {
        this.setState({
            value: selectedOption
        });
        this.props.onSelect(this.props.name, selectedOption.value);
    }


    /**
     * handle the change of the value property from the parent
     *
     * @param {object} prevProps the previous props object
     */
    componentDidUpdate = (prevProps) => {
        let value = "";

        if (this.props.value !== prevProps.value) {
            if (!this.props.value) {
                value = "";
            } else {
                value = this.props.value
            }

            this.setState({
                value: value
            });
        }
    }

    render() {

        const val = this.props.options.filter(e => e.value === this.props.value)

        return (
            <div>
                <Select classNamePrefix="mySelsect"
                    styles={this.customStyles()}
                    className="fao_selesct_container"
                    //loadOptions={this.props.loadOptions}
                    options={this.props.options}
                    onChange={this.handleChange}
                    value={val}
                    defaultValue={val}
                />
            </div>
        );
    }
}

export default FaoSingleSelect;
