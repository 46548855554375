import {
    addLocaleData
} from 'react-intl';
import flatten from 'flat';

import intlAR from 'react-intl/locale-data/ar';
import intlZH from 'react-intl/locale-data/zh';
import intlEN from 'react-intl/locale-data/en';
import intlFR from 'react-intl/locale-data/fr';
import intlRU from 'react-intl/locale-data/ru';
import intlES from 'react-intl/locale-data/es';

import ar from './translations/ar.json';
import zh from './translations/zh.json';
import en from './translations/en.json';
import fr from './translations/fr.json';
import ru from './translations/ru.json';
import es from './translations/es.json';

addLocaleData([...intlAR, ...intlZH, ...intlEN, ...intlFR, ...intlRU, ...intlES]);

export default {
    ar: flatten(ar),
    zh: flatten(zh),
    en: flatten(en),
    fr: flatten(fr),
    ru: flatten(ru),
    es: flatten(es)
};
