import React, {
    Component
} from 'react';


/**
 * customization of input text for FAO
 * @prop {function} onChange callback receiving the selected valuej
 * @prop {object} - all other props will be passed to the input
 *
 */
class FaoInputText extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value:this.props.value || ""
        };
    }

    /**
     * handle the change of the input and call the callback function of the parent
     *
     * @param {object} selectedOption the selected option 
     */
    onChange = e => {
        //alert('input change')
        //console.log();
        this.props.onChange(this.props.name, e.target.value);
    }

    /**
     * handle the change of the value property from the parent
     *
     * @param {object} prevProps the previous props object
     */
    componentDidUpdate=(prevProps)=>{
        if(this.props.value!==prevProps.value){
            this.setState({value:this.props.value});
        }
    }


    render() {
        return (
            <input {...(this.props)} type="text" onChange={this.onChange} value={this.state.value} />
        );
    }
}

export default FaoInputText;

