import React, {
    Component
} from 'react';
import PubAdvSearch from './component.advSearch.js'
import URLQueryHelper from '../URLQueryHelper.js'


import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import M from 'materialize-css';


/**
 * shows the search form
 * @prop {string} lang the language
 * @prop {function} onSearch callback for search btn
 *
 */
class PubSearchBox extends Component {
    // TODO: read and presets the inputs from the url //

    constructor(props) {
        super(props);

        let openAdvSearch = false;
        let query = URLQueryHelper.getUrlQuery();
        let q = query !== null ? JSON.parse(atob(query.split("#")[0])).query : "";

        this.box = React.createRef();

        openAdvSearch = true;
        console.warn(this)
        console.log(q)
        this.comparing=false;
        

        this.state = {
            advSearchOpened: openAdvSearch,
            query: q,
            externalQueryProcessed: false,
        };


    }
    componentDidMount() {
        
        var $ = require( "jquery" );
        $('.facet-nav').hide();        
            var elems = document.querySelectorAll('.tooltipped');
            var instances = M.Tooltip.init(elems); 
            console.log(instances);       
    }



    /**
     * toggle the advanced search form
     *
     * @param {object} e the event
     */
    toggleAdvSearch = (e) => {
        e.preventDefault();

        const {
            advSearchOpened
        } = this.state;
        this.setState({
            advSearchOpened: !advSearchOpened,
        });
    }


    /**
     * reset event of AdvSearch
     *
     * @param {object} selectedOption the selected option 
     */
    onAdvReset = (data) => {
        this.setState({ query: "" });
    }

    /**
     * event fired when the user click on search btn
     *
     */
    onSearch = () => {
      
        console.log(this)
        this.props.onSearch({
            query: this.state.query
        });
    }


    /**
     * event fired when the user click on search btn
     *
     */
    onAdvSearch = (data) => {
        console.log('on ADVANCED searxch')
        if (data.query !== this.state.query)
            data.query = this.state.query
            console.info("here")
            console.info(this)

        this.props.onSearch({
            query: this.state.query,
            comparing:false,
            ...data
        });
    }

    onFormUpdate = (data) => {
        this.props.onFormUpdate({
            query: this.state.query,
            ...data
        });
    }

    /**
     * handle the change of the input and call the callback function of the parent
     *
     * @param {string} name name of the input
     * @param {string|object} value value of the input
     */
    onChange = (name, value) => {
        this.setState({ [name]: value });
    }


    render() {
        return (
            <div>
                
                <div className="searchbox queries" >
                    <div className="main-search" id="main-searchdiv">
                        
                    
                    <div id="advSearchWr" style={this.state.advSearchOpened ? {} : { display: "none" }}>
                        
                    
                        
                    
                    <PubAdvSearch {...(this.props)} toggleFn={this.toggleAdvSearch} 
                    onReset={this.onAdvReset} onSearch={this.onAdvSearch} onFormUpdate={this.onFormUpdate} />
                </div>
                        
                  
                    </div>
                  
                   

                </div>
                </div>
      
  
 
 
        );
    }
}

export default PubSearchBox;
