import React, {
    Component
} from 'react';
import './css/gsa_docrep_style.css';
import './css/gsa_style.css';
import './component.searchApp.css';

import PubSearchBox from './component.SearchBox.js';
import { IntlProvider } from 'react-intl';
import translations from '../i18n/locales.js';
import PubResultsWidget from './PubResultsWidget.js';
import URLQueryHelper from '../URLQueryHelper.js';

import $ from 'jquery';
import M from 'materialize-css';
// import "materialize-css/dist/css/materialize.min.css";
// import "materialize-css/dist/js/materialize.min.js";

/**
 * shows a form to start the search
 * from AdvSearch form or from initial page
 * @prop {string} lang the language
 *
 */
class SearchApp extends Component {
    constructor(props) {
        super(props);
        console.warn(props,this)
   
        
        this.resultsWidget = React.createRef();
        this.state = {
            showLoading: false,
            formData: {},
            externalSearch: false
        }



    }
    componentDidMount() {
        console.log('componentDidMount component searchApp.js')

        $('.sidenav_react_buttons .collapsible-header.active').removeClass('active');
        $('.sidenav_react_buttons .collapsible-header.adv_search').addClass('active');
        
        
        
        window.search_from_link=false;
        var pdfMake=window.pdfMake;
        pdfMake.fonts = {

            // download default Roboto font from cdnjs.com
            Roboto: {
                normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
            },
            OpenSans:
            {
                normal:'https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0B4gaVQUwaEQbjA.woff',
                OpenSansBolded:'https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0B4gaVQUwaEQbjA.woff',
                OpenSansItalic:'https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVQUwaEQbjB_mQ.woff'
            }
        };

        pdfMake.tableLayouts = {
            exampleLayout: {
              hLineWidth: function (i, node) {
                  if (i===3)
                  console.warn(node)
                  if (i===0)
                  {
                  return 0.1
                  }
                  else
                  {
                      if (i===node.table.body.length)
                      return 0.1
                      else
                  return 0
                  }
                
                // return (i === node.table.headerRows) ? 4 : 0.1;
              },
              vLineWidth: function (i) {
                return 1;
              },
              hLineColor:'#b3afb7',
              vLineColor:'#b3afb7',
              paddingLeft: function (i) {
                return i === 0 ? 4 : 8;
              },
              paddingRight: function (i, node) {
                return i === 0 ? 4 : 8;
              }
            }
          };
        var el=document.querySelector('.tabs');
        M.Tabs.init(el);
        console.warn('tabs from componentDidMount')
        $('.tab').eq(0).find('span').click();

        setTimeout(function()
        {
            //$('.allacquaticspecies .faocheck input').prop('checked','checked');
        },1500)
        
        
 

    }
    componentDidUpdate() {
        console.log('TWICE updated component searchApp.js! check https://stackoverflow.com/questions/51458441/componentdidupdate-called-twice-every-route-change')

        var $ = require( "jquery" );
        
        // var el=document.querySelector('.tabs');
        // M.Tabs.init(el);
        
        
        //just a workaround!
        $('.refine_filter').unbind('click');
        $('.refine_filter').on('click',function(e)
        {
            
            console.warn($(this))
            var _this=e.target;
            if ($(_this).hasClass('active')===false)
            {
                $(_this).addClass('active');
                $('.results_left').addClass('col s3 m3').show();
                $('.results_right').removeClass('col s12 m12').addClass('s9 m9');
                //.show();
            }
            else
            {
                $(_this).removeClass('active');
                $('.results_left').removeClass('col s3 m3').hide();
                $('.results_right').removeClass('col s9 m9').addClass('s12 m12');
            }
           
        })
        //wait(() => {
        /* Find matching element by id */
        const anchor = document.getElementById("maincontent");

        let query = URLQueryHelper.getUrlQuery();

        if (anchor && query !== null) {

            /* Scroll to that element if present */
            anchor.scrollIntoView();

        }
        //}, 400);

    }

    resultState = {
        resultFormat: null,
        sortOperator: 'relevance',
    };

 
    /**
     * event fired when the user click on search btn
     *
     * @param {object} formData form's data 
     */
    onSearch = (formData) => {
     
        console.info(formData)
        
        this.search_from_link=false;
        formData.comparing=false;
        window.search_from_link=false;
        this.resultState.resultFormat = formData.resultformat;
        this.resultState.sortOrder = formData.sortorder;
        
        console.warn(formData)
      
        this.resultsWidget.current.onSearch(formData);
    }

    onReset = () => {
        this.setState({ showLoading: true });
    }


    onFormUpdate = (formData) => {
        console.warn('onFormupdate')
        if (!this.state.externalSearch) {
            this.setState({ formData: formData })
            this.setState({ externalSearch: true })
        }
        return false;
    }

    /**
     * handle the onFetchStart event
     *
     */
    onFetchStart = () => {
        
        this.setState({ showLoading: true });
        if ($('.busy-load-container').length===0)
        {
           $('body').append(' <div class="busy-load-container" id="qje70el23u69orhl0fy8"> <div class="busy-load-container-item"> <div class="spinner-accordion busy-load-spinner-css busy-load-spinner"> <div class="rect rect1"></div> <div class="rect rect2"></div> <div class="rect rect3"></div> <div class="rect rect4"></div> <div class="rect rect5"></div> </div> </div> </div>')
        }
        else
        {
            $('.busy-load-container').show();
        }
    }

    /**
     * handle the onFetchComplete event
     *
     */
    onFetchComplete = () => {
            
            //debugger
            console.warn(this)
      
            
            setTimeout(function()
            {
                console.log('done all?')
                console.log($('.collapsible').length+' from searchapp')
                var el=document.querySelectorAll('.collapsible');
                var instances = M.Collapsible.init(el);
                console.log(instances);
                

                 $('.facet-nav .collapsible .collapsible-header').trigger('click');
            },1500)
            
            
           
     
            
         //   this.resultsWidget.state.formData
          var results=this.resultsWidget.current.state.gcsData;
          console.log(results);
          $('.result-list table').each(function(i,d)
          {
            $(this).find('th span.counter').text(i+1)

          })
        
  

      
        
        $('.print_pdf').off('click')
        $('.print_pdf').on('click',function()
        {
            var query_arr=[];
            
            $('.sortby_list >div').each(function(i,d)
            {
                if ($(d).hasClass('sort-active'))
                {
                    if (i===0)
                    query_arr.push({label:'Sort parameter: ',id:'sort',sels:'By relevance'})
                    else
                    query_arr.push({label:'Sort parameter: ',id:'sort',sels:'By year'})
                }
            })



            if ($('.free_text_row').find('input').val()!=='')
            {
                var $this=$('.free_text_row');
                query_arr.push({label:$this.find('.fdr_label').text(),id:'free_text_row',sels:$this.find('input').val()})
            }
            $('.fdr_query').each(function()
            {

                var p_class=$(this).attr('class').split(' ')[1];
                console.log(p_class)
                if (p_class!=='select_order')
                {
                    if ($(this).find('.fao_select_container').length>0)
                    {
                        var sels=[];
                        $(this).find('.my_faoSelect__multi-value__label').each(function()
                        {
                        console.info($(this).text())
                        sels.push($(this).text())
                        })

                        if (sels.length>0)
                        {

                            query_arr.push({label:$(this).find('.fdr_label').text(),id:p_class,sels:sels,multiple:true})
                        }
                    }
                    else
                    {
                        if ($(this).find('.fdr_check').length>0)
                        {
                            
                            //just reverted back to only have one ... no sense to have 2
                            if ($(this).find('.fdr_check input').length>1)
                            {
                                //internalcheck
                                if ($(this).find('.fdr_check input:checked').length>1)
                                {
                                    
                                    query_arr.push({label:$(this).find('.fdr_label').text(),id:p_class,sels:'Yes OR No'})
                                }
                                else

                                {
                                if ($('.internalcheck').find('.fdr_check input').eq(0).prop('checked'))
                                {
                                    query_arr.push({label:$(this).find('.fdr_label').text(),id:p_class,sels:'Yes'})
                                }
                                else
                                {
                                    query_arr.push({label:$(this).find('.fdr_label').text(),id:p_class,sels:'No'})
                                }
                            }
                               
                            }
                            else
                            {

                            // }
                            // if ($(this).find('.fdr_check input').eq(0).prop('checked').length>0)
                            // {
                                //aquatic species
                                if ($(this).find('.fdr_check input').eq(0).prop('checked')===true)
                                {
                                    query_arr.push({label:$(this).find('.fdr_label').text(),id:p_class,sels:'Yes',checkbox:true});
                                }
                                else 
                                {
                                    query_arr.push({label:$(this).find('.fdr_label').text(),id:p_class,sels:'Not selected',checkbox:true});
                                }
                             }
                            
                        }
                        if (p_class==='year')
                        {
                            var year_obj={label:$(this).find('.fdr_label').text(),id:p_class,sels:[]};
                            var input_from=$(this).find('input').eq(0);
                            if (input_from.val()!=='')
                            {
                                year_obj.sels.push({id:'from',val:input_from.val()});
                            }
                            var input_to=$(this).find('input').eq(1);
                            if (input_to.val()!=='')
                            {
                                year_obj.sels.push({id:'to',val:input_to.val()});
                            }

                            if (year_obj.sels.length>0)
                            query_arr.push(year_obj)
                        }
                        if (p_class==='words_reference')
                        {
                            if ($(this).find('input').val()!=='')
                            query_arr.push({label:$(this).find('.fdr_label').text(),id:p_class,sels:$(this).find('input').val()});
                        }
                        
                        //year, free Texts and checkboxes
                    }
                }
                else
                {
                    //only  By year OR By relevance is single value select
                    // if ($(this).find('.mySelsect__single-value').length==0)
                    // {
                    //     //default one
                    //     query_arr.push({label:$(this).find('.fdr_label').text(),id:'sort',sels:'By year'})
                    // }
                    // else
                    // {
                    //     query_arr.push({label:$(this).find('.fdr_label').text(),id:'sort',sels:$('.mySelsect__single-value').text()})
                    // }
                    
                        
                }

            });
            var facets_filters_arr=[];
            
            $('.facet-nav .collapsible').each(function()
            {
                var _class=$(this).attr('class').split(' ')[1];
             
                    //var _class=$(this).attr('class').split(' ')[1];
                    console.log(_class);
                  
                    
                    if ($(this).find('.collapsible-body li.inactive').length>0)
                    {
                        var o={label:$(this).find('.collapsible-header span:first').text()}
                        if ($(this).find('.collapsible-body li.inactive').length===1)
                        {
                            console.warn($(this).find('.collapsible-body li.inactive span'))
                            var vals=[$(this).find('.collapsible-body li.inactive span').eq(0).text()];
                        }
                        else
    
                        {
                            var vals=[]
                         
                            $(this).find('.collapsible-body li.inactive span.s10').each(function(i,d)
                            {
                                console.log($(d),$(d).text())
                                vals.push($(d).text())
                            })
                        }
                        o.sels=vals;
                   facets_filters_arr.push(o)
                    }
                   
                  
                  
                })
            
            
            console.info(facets_filters_arr)
            var json_pdf={
                pageMargins: [40, 10, 40, 60],
                footer: function(currentPage, pageCount) {
                    return {
                        table: {
                            //layout: 'headerLineOnly',
                            widths: '*',
                            body: [
                                [{
                                    border: [false, true, false, false],
                                    text: "Page " + currentPage.toString() + ' of ' + pageCount,
                                    alignment: 'right',
                                    style: 'normalText',
                                    //[left, top, right, bottom] 
                                    margin: [0, 20, 50, 0],
            
                                }]
                            ]
                        },
                        layout: 'noBorders'
                    };
                },
                     
                 styles:  {
                    portlex: { color: '#737f91', "fontSize": 15,  "margin": [0, 8, 0, 5] },
                    date: { "fontSize": 8, "margin": [0, 15, 0, 5] },
                    country: {
                        fontSize: 21,
                        alignment:'center',
                        
                    },
                    header: {
                        fontSize: 15,
                        
                        // margin: [left, top, right, bottom]
                        margin: [0, 10, 0, 10],
                        //darker blue
                        color: '#69686e',
                        OpenSansBolded: true,
            
                        
                            // bold: true
                    },
                  
                    link: {
                        fontSize: 8,
                        margin: [0, 5, 0, 0],
                        color: '#002a66',
                         OpenSansItalic: true
                    },
                    search_label: {
                        fontSize: 8,
                        // margin: [left, top, right, bottom]
                        margin: [0, 5, 20, 20],
                        color: '#002a66',
                        OpenSansBolded: true
                         
                    },
                    search_label_and: {
                        fontSize: 8,
                        // margin: [left, top, right, bottom]
                        margin: [0, 2, 0, 2],
                        color: '#ba093e',
                        
                        OpenSansBold: true
                    },


                    
                    search_container: {
                        fontSize: 8,
                        // margin: [left, top, right, bottom]
                        margin: [0, 5, 0, 10],
                        color: '#4e4d4f',
                        decoration: 'underline'
                       
                    },
                    
                
                    table_style: {
                      
                        margin: [0, 5, 0, 5]
            
            
                    },
                    measurecitedinfull: {
                        // margin: [left, top, right, bottom]
                        fontSize: 8,
                        margin: [0, 3, 0, 3],
                        font: 'OpenSans',
                        //greyish
                        color: '#1e1d1f'
                    },
                    td_title: {
                        // margin: [left, top, right, bottom]
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        font: 'OpenSans',
                        //darker
                        color: '#1e1d1f'
                    },
                    
            
            
                },
                defaultStyle: {
                    // margin: [left, top, right, bottom]
                    fontSize: 8,
                    margin: [0, 0, 0, 5],
                    "font": "OpenSans",
                    //greyish DARKER
                    color: '#1e1d1f'
                },
                content:[]
            
            };
            $('.busy-load-container').show();
            
          

            $('.busy-load-container').css('display','block');
            var pdfMake=window.pdfMake;

            pdfMake.fonts = {

                // download default Roboto font from cdnjs.com
                Roboto: {
                    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
                },
                OpenSans:
                {
                    normal:'https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0B4gaVQUwaEQbjA.woff',
                    OpenSansBolded:'https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0B4gaVQUwaEQbjA.woff',
                    OpenSansItalic:'https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4iaVQUwaEQbjB_mQ.woff'
                }
            };

        json_pdf.content.push({ "text": 'PORTLEX', style: 'portlex', alignment: 'center' })        
        
        json_pdf.content.push({text:[{text:'Your search parameters',style:'search_container'},{text:'   '+$('.result-list table').length+' records'}]});
        //query_arr.push({label:$(this).find('.fdr_label').text(),id:p_class,sels:sels})
        console.warn(query_arr)
        for (var p in query_arr)
        {
            
            var q=query_arr[p];
       
            if (q.id!=='sort')
            {
                //if (q.id!=='year')
                if (q.multiple)
                {
                        if (q.sels.length>1)
                        {
                        var all=q.sels.join(' OR ')  
                            
                            json_pdf.content.push({text:[{text:q.label+'  ',style:'search_label'},all]});
                        
                        }
                        else
                        {
                            json_pdf.content.push({text:[{text:q.label+'  ',style:'search_label'},q.sels[0]]});
                            
                        }
                  }
                else
                {
                    

                    if (q.id==='year')
                    {
                            var arr=[{text:[
                                {text:q.label,style:'search_label'}
                            ]}]
                        
                            

                            for (var p in q.sels)
                            {
                                // year_obj.sels.push({id:'from',val:input_to.val()});
                                if (q.sels[p].id==='from')
                                {
                                    arr[0].text.push(' From ',q.sels[p].val);
                                }

                                if (q.sels[p].id==='to')
                                {
                                    arr[0].text.push(' to ',q.sels[p].val);
                                }

                            }

                            json_pdf.content.push({text:arr})
                    }
                   // if (q.id=='free_text_row' || q.id=='words_reference')
                   else
                    {
                        
                        json_pdf.content.push({text:[{text:q.label+'  ',style:'search_label'},q.sels]});
                    }

                   // query_arr.push({label:$(this).find('.fdr_label').text(),id:p_class,sels:'Yes'});

                   
                    
                }
            }
            else
            {
                json_pdf.content.push({text:[{text:'Sort parameter: ',style:'search_label'},{text:q.sels}]});
            }
            
            

        }

        if (facets_filters_arr.length>0)
        {


        json_pdf.content.push({text:[{text:'Your filter over search',style:'search_container'},{text:'   '+$('.result-list table:visible').length+' records'}]});
        for (var p in facets_filters_arr)
        {
            var q=facets_filters_arr[p];
            if (q.sels.length>1)
            {

                var all=q.sels.join(' OR ')  
                
                json_pdf.content.push({text:[{text:q.label+'  ',style:'search_label'},all]});
            
            }
            else
            {
                json_pdf.content.push({text:[{text:q.label+'  ',style:'search_label'},q.sels[0]]});
                
            }
            if (facets_filters_arr.length>1)
            {
                if (p<facets_filters_arr.length-1)
                {
                    json_pdf.content.push({text:' AND ' ,style:'search_label_and'});
                }
            }
            
        }

        }

         json_pdf.content.push({text:$('.result-list table:visible').length+' records',style:'header'});

            $('.result-list table:visible').each(function(i,d)
            {
                var table_info = {
                style: 'table_style',
                layout:'exampleLayout',
                table: {
                    body:[],
                    // headerRows: 1,
                   // widths: ['*', '*'],
                   widths: ['30%', '70%'],
               
                },
        
            }

            
            var th=$(this).find('thead')
            var flag=th.find('img');
            var countries=th.find('.country_name span').not('.counter')
            if (countries.length===1)
            {
                var country=countries.text();
            }
            else
            {
                var country='';
                for (var p in countries)
                {
                    country+=countries.eq(p).text();
                    if (p<countries.length-1)
                    {
                        country+=' ; ';
                    }
                }
            }
            
            
            table_info.table.body.push(['',country
                ]);

                /*

countryCode[0]
referenceOfMeasure
faolexId[0]

measureCitedInFull

typeOfLegalInstrument[0]
inForce
dateOfText[0]
otherReferenceHttp
allAquaticSpecies

                */
            $(this).find('tbody tr').each(function(i,d)
            {
                var  o=[];
                var $tr=$(d);
               
                $(d).find('td').each(function(i2,d2)
                {
                    var $td=$(d2);

                    if (i2===0)
                    {
                        o.push({text:$(d2).text()})
                    }
                    
                    if (i2===1)
                    {

                        if (i===1)
                        {
                            var o2=[]
                            //measurecitedinfull
                            $td.find('p').each(function(i3,d3)
                            {
                                var t=$(d3).text();
                                o2.push({text:t,style:'measurecitedinfull'})
                                
                            
                            })
                           
                            o.push(o2)

                        }
                        else
                        {
                            if ($td.find('a').length>0)
                            {
                              
                                var link=$td.find('a').attr('href')
                                console.log(link)
                                

                                o.push({
                                    style: 'link',
                                    text: $td.text(),
                                    link: link
                                })
                                //o.push($td.text())
                            }
                            else
                            {
                                if ($td.find('.small').length>1)
                                {
                                   
                                   var h='';
                                    $td.find('.small').each(function(i,d)
                                    {
                                        h+=$(this).text();
                                        if (i<$td.find('.small').length-1)
                                        {
                                            h+=' ; ';
                                        }
                                    })
                                    o.push(h);
                                }
                                else
                                {
                                    o.push($td.text())
                                }
                                
                                
                            }
                            
                        }
                        //if ($('.react-expand-collapse__content').not('.react-expand-collapse--expanded'))
                    // o.content=$(d2).text();
                       
                        
                        table_info.table.body.push(o)
                    }
                    
                })
                
            
            })

            json_pdf.content.push(table_info);
            

        })
        var e = [

            {
                columns: [{
                        svg: window.fao_svg,
                        //svg: zm
                        width: 140,
                        height: 45
                            // height: 50
                    },
    
                    {
                        "text": 'Created on ' + new Date().toLocaleDateString("en-EU"),
                        style: 'date',
                        alignment: 'right'
                    }
    
                ]
            }
        ];
        for (var p in e) {

            json_pdf.content.unshift(e[p])
        }
        pdfMake.createPdf(json_pdf).download('Portlex Search Results');
         setTimeout(function()
         {
            $('.busy-load-container').hide() 
         },3000)  
        })
        
        setTimeout(function()
        {
            $('.searchbox .tabs .tab').eq(1).css('opacity',1);
            if (!this.search_from_link || this.search_from_link===false)
            {
                console.log('triggering results tab after query')
                $('.searchbox.row li.tab').eq(1).find('a span').trigger('click');
                $('.busy-load-container').hide();
            }
            else
            {
                
                $('.searchbox.row li.tab').eq(0).find('a span').trigger('click'); 
            }
          
   

        },500)
        this.setState({ showLoading: false });

        if (document.getElementsByClassName('divRouting')[0]) 
        {
            var x = document.getElementsByClassName('divRouting')[0].offsetHeight;
            document.getElementsByClassName('footer')[0].style.top = x + 400 + 'px';

           
        }
    }

         


    render() {
        return (
            <IntlProvider locale={'en'} key={this.props.lang} defaultLocale="en" messages={translations['en']} >
              
                <div className="search-app-container">
                <div className="searchbox row">
                  <ul id="tabs-swipe-demo" className="tabs">
    <li className="tab col s3"><a href="#query_tab"><span>Advanced Search</span></a></li>
    <li className="tab col s3"><a className="active" href="#results_tab"><span>Results</span></a></li>
    
  </ul>
                <div id="query_tab" className="tab_info_container">
                    {/* publishes filters and also the .tabs, inside component.SearchBox */}
                    <PubSearchBox resultWidget={this.resultsWidget} {...(this.props)} onSearch={this.onSearch} resultState={this.resultState} 
                    onFormUpdate={this.onFormUpdate} externalFormData={this.state.formData} onReset={this.onReset} />
                    </div>           
                    {/* initial results must be inside corresponding tab */}
                    <div id="results_tab" className="tab_info_container">
                         <PubResultsWidget 
                         ref={this.resultsWidget} 
                         {...(this.props)} onFetchStart={this.onFetchStart} onFetchComplete={this.onFetchComplete} resultState={this.resultState} 
                         externalSearch={this.state.externalSearch} externalFormData={this.state.formData} />
                    </div>
                </div>
                    {/* {((this.state.showLoading) ? <FaoBlockUi /> : "")} */}
                    {/* {((this.state.showLoading) ?   $('.busy-load-container').show() : "")} */}
                </div>
            </IntlProvider>
        );
    }
}

export default SearchApp;
