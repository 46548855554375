//OLD, not sure if working...

import {
    Component
} from 'react';
import Config from '../../config';
import $ from 'jquery';
import countriesJSON from '../../json/new_portlex_countries.json';
import typeofmeasure from '../../json/typeofmeasure.json';

import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
/**
 * class to manage the http communication with GCS
 */
export default class GcsService extends Component {


    /**
     * get the list of docs 
     *
     * @param {object} query the query data to send in the request
     * @param {function} callback the callback to execute
     */
    getList = (query, callback) => {
        if (!query || !callback) {
            return false;
        }
        var this_app = {
            numPages: 0,
            rows: [],
            simplified_records: []
        }
        this_app.fields_to_filter = [{

                title: 'Country/Territory',
                //the field on the data results
                field: 'countryIso',
                //operatorName is used on facetResults
                operatorName: 'country',
                true_false_arr: [],
                values: []
            },
            {

                title: 'Type of legal instrument',
                field: 'typeOfLegalInstrument',
                operatorName: 'typeoflegalinstrument',
                true_false_arr: [],
                values: []
            },
            {

                title: 'Year of text',
                field: 'yearOfText',
                operatorName: 'yearoftext',
                true_false_arr: [],
                values: []
            },
            {

                title: 'Type of measure',
                field: 'typeOfMeasure',
                operatorName: 'typeofmeasure',
                true_false_arr: [],
                values: []
            },
            {

                title: 'RFMO',
                field: 'rfmo',
                operatorName: 'rfmo',
                true_false_arr: [],
                values: []
            },
            {

                title: 'GeneralCompetenceArea',
                field: 'competencearea',
                operatorName: 'competencearea',
                true_false_arr: [],
                values: []
            },
        ];


        // $('.result-list .parent-item-container').remove();

        function associated_data(data) {

           /*  console.warn(data) */

            var new_f_arr = [];
            var fields_arr = this_app.fields_to_filter.map(d => d.field)
            for (var p in data) {
                var o = data[p];
                o.index = parseInt(p);

                var feature_fields = o.metadata.fields;

                var mapped_feature_fields = o.metadata.fields.filter(d => fields_arr.indexOf(d.name) > -1);
                var sorted_mapped_feature_fields = []

                for (var p in fields_arr) {
                    for (var s in mapped_feature_fields) {
                        if (mapped_feature_fields[s].name === fields_arr[p])
                            sorted_mapped_feature_fields.push(mapped_feature_fields[s])
                    }
                }

                if (sorted_mapped_feature_fields.length !== fields_arr.length) {


                    var arr = sorted_mapped_feature_fields.map(d => d.name)
                    for (var p in fields_arr) {

                        if (arr.indexOf(fields_arr[p]) === -1) {
                            var missing_f = this_app.fields_to_filter.filter(d => d.field === fields_arr[p])[0];

                            sorted_mapped_feature_fields.splice(missing_f.pos, 0, {
                                name: missing_f.field,
                                textValues: { values: ['No information available in the record'] },
                                no_info: true
                            })

                        }
                    }
                }


                var f_list = sorted_mapped_feature_fields.map(d => d.name);
                var new_o = { index: parseInt(p), info: [] };
                for (var index in sorted_mapped_feature_fields) {

                    if (fields_arr.indexOf(sorted_mapped_feature_fields[index].name) > -1) {

                        var f = sorted_mapped_feature_fields[index];



                        var new_f = {};


                        var pos = f_list.indexOf(f.name);


                        if (pos > -1) {

                            new_f.field = f.name;


                            if (f.integerValues) {


                                new_f.type = 'integer';

                                new_f.data = f.integerValues;
                            }
                            if (f.textValues) {

                                if (f.name !== 'abstract') {
                                    new_f.type = 'text';

                                    if (f.textValues.values[0].indexOf('#') > -1) {
                                        // if (f.name == 'geographicalArea')
                                        //DUPLICATED values for geographicalArea in GCS!!
                                        //     debugger

                                        new_f.data = {};
                                        var temp_arr = [];
                                        var temp_arr_html = [];

                                        f.textValues.values.forEach(function(d) {
                                            if (temp_arr.indexOf(d.split('#')[0]) === -1) {
                                                temp_arr.push(d.split('#')[0]);
                                                return d.split('#')[0];
                                            }

                                        });

                                        new_f.data.values = temp_arr;

                                    } else {
                                        new_f.data = f.textValues;

                                    }




                                }


                            }

                            //<div>Type of text: ' + o.typeOfTextEn + '</div><div>Year: ' + o.year + '</div><div><a target="_blank" href="http://extwprlegs1.fao.org/docs/pdf/' + o.title + '">Document</a></div>';

                        }
                        new_o.info.push(new_f);
                    }
                }
                new_f_arr.push(new_o);
                new_o.index = p;
                this_app.simplified_records.push(new_o);

            }

            console.log(this_app.simplified_records)

        }

        // $(document).ajaxStop(function() {
        //     // 0 === $.active

        //     console.warn(this_app.counted_ajax_done)
        //     if (this_app.counted_ajax_done == this_app.numPages) {
        //         debugger
        //         console.warn(this_app)
        //         alert('all done??')
        //     }
        // });
        this_app.counted_ajax_done = 0;

        function promises_ajax(i, datastring) {

           /*  console.warn('exec promises ajax ajax' + i)
            console.log(arguments) */

            //return 
            return $.ajax({
                type: "POST",
                url: Config.apis.gcs,
                contentType: "text/plain",
                data: JSON.stringify(query),
                success: function(data) {

                    //console.log(data)
                    if (!this_app.records) {
                        this_app.records = [];
                        this_app.records.push(data.results)
                    } else {
                        [].push.apply(this_app.records, data.results)
                    }

                },
                error: function() {
                    alert('some query failed, results may be incomplete')
                }
            })
        }

        console.warn(JSON.stringify(query))

        function exec_subfacet(query) {
            return $.ajax({
                type: "POST",
                url: Config.apis.gcs,
                contentType: "text/plain",

                data: JSON.stringify(query),
                success: function(data) {

                    if (data.resultCountExact) {
                        var counts = parseInt(data.resultCountExact);
                    } else {
                        if (data.resultCountEstimate)
                            var counts = parseInt(data.resultCountEstimate);
                    }

                    if (counts > 0) {

                        var facets_data = data.facetResults;

                        create_facets(counts, facets_data)
                    }

                }
            })
        }

        function exec_filter() {
            console.log(this_app.records_joined)




            var t_f_obj_arr = [];


            console.info(this_app.simplified_records)
            for (var p in this_app.simplified_records) {
                var o = this_app.simplified_records[p];
                o.index = parseInt(p)
                    //console.log(o)
                if (p === 4) {
                    console.log(o)
                        //   debugger

                }
                var t_f_obj = { id: p, arr: [] };

                var filters_arr = o.info.map(d => d.field);


                for (var x in this_app.filters) {

                    var this_filter = this_app.filters[x];
                    if (this_filter.field !== 'abstract') {


                        var f = o.info.filter(d => d.field === this_filter.field)[0];

                        if (f) {
                            var f_data = f.data.values;

                            //   console.log(filters[x].values,f_data[0])
                            console.log(this_filter)
                                //array indexof...

                            if (this_filter.values.length === 0) {


                                if (f_data.indexOf(this_filter.values[0]) > -1) {
                                    // this_filter.true_false_arr.push('true')

                                    this_filter.true_false_arr.push(o.index)
                                } else {

                                }
                            } else {


                                //the values of the record contains a value to be hidden??
                                var found = f_data.some(r => this_filter.values.indexOf(r) >= 0);

                                //we found the value in the array of what we wish to hide!
                                if (found) {

                                    console.log('YES found ' + o.index + ' ??')
                                    this_filter.true_false_arr.push(o.index)
                                }


                            }
                        } else {
                            debugger
                            console.warn(o);
                            console.warn(this_filter);
                            return false
                        }
                    }

                }



            }

            var all_true_false_arr = [];

            console.log(JSON.stringify(this_app.filters))
            for (var x in this_app.filters) {


                //     console.log(this_app.filters[x])
                if (this_app.filters[x].true_false_arr.length > 0) {

                    all_true_false_arr.push.apply(all_true_false_arr, this_app.filters[x].true_false_arr);


                }




            }

            console.log(all_true_false_arr);

            var n = all_true_false_arr.reduce(function(memo, feature) {

                var pos = memo['arr'].indexOf(feature);
                if (pos < 0) {
                    memo.arr_obj.push({ val: feature, counts: 1 })
                        // memo.arr_obj.filter(d=>d.val===feature)[0].counts++

                    memo.arr.push(feature)
                } else {

                    memo.arr_obj[pos].counts++;
                }
                return memo;

            }, {
                arr: [],
                arr_obj: []
            });

            var f = this_app.filters.filter(d => d.true_false_arr.length > 0);
            var in_all = n.arr_obj.filter(d => d.counts === f.length).map(d => d.val)
            console.log(in_all)
                // var some_present = [];
                // for (var i = 0; i < this_app.simplified_records.length; i++) {
                //     for (var p in this_app.filters) {


            //         var t_fs = this_app.filters[p].true_false_arr;
            //         //if (arrs[p].true_false_arr.some(r => this_filter.values.indexOf(r) >= 0);)
            //         if (t_fs.length > 0) {

            //             if (t_fs.indexOf(i) > -1) {
            //                 some_present.push(i)
            //             }
            //         }
            //     }
            //     // var found = f_data.some(r => this_filter.values.indexOf(r) >= 0);
            // }

            // console.log(some_present)
            if (f.length === 0) {
                console.log('no filter, we show all')
                this_app.rows.removeClass('hidden')
                $('.query_results_count_filtered').hide();
            } else {

                if (in_all.length === 0) {
                    this_app.rows.addClass('hidden')
                        // if (all_true_false_arr.length == 0) {
                        //     this_app.rows.removeClass('hidden')
                        // } else {
                        //     this_app.rows.addClass('hidden')
                        // }
                        //this_app.rows.removeClass('hidden')
                } else {
                    this_app.rows.addClass('hidden')
                    setTimeout(function() {

                        var counter = 1;
                        this_app.rows.each(function(i, d) {
                            //$(this).removeClass('hidden')


                            //????'
                            if (in_all.indexOf(i) > -1) {
                                console.log(' v ' + i)
                                $(this).removeClass('hidden')

                                $(this).find('th span.counter').text(counter);
                                counter++;



                            }
                        })


                    }, 1500)
                }
            }

            console.warn(in_all, f)
            if (f.length === 0) {
                $('.query_results_count_filtered').hide();
            } else {
                $('.query_results_count_filtered').show();

                if (in_all.length === 0) {
                    // $('.query_results_count_filtered').hide();
                    $('.query_results_count_filtered span.counts').text(0)
                        //$('.query_results_count_filtered span.counts').text(this_app.simplified_records.length)

                } else {
                    //$('.query_results_count_filtered').hide();
                    $('.query_results_count_filtered span.counts').text(in_all.length)
                }
            }

        }

        function create_facets(total, facets_data) {
            $('.facet-nav .content').empty();
            $('.query_results_count_wrapper').show();
            //$('body').busyLoad("hide");


            var iso3_codes = countriesJSON.map(d => d.iso3);
            for (var p in facets_data) {

                var o = facets_data[p];
               

                var field_in_data = this_app.fields_to_filter.filter(d => d.operatorName === o.operatorName)[0];

                if (field_in_data) {

                    var html = '<ul field="' + field_in_data.field + '" class="collapsible ' + o.operatorName + '"> <li> <div class="collapsible-header valign-wrapper"><span class="col s10">' + field_in_data.title + '</span><span class="col s2"><i class="material-icons up_down">keyboard_arrow_down</i></span></div> <div class="collapsible-body"> <ul>';
                    if (o.operatorName === 'yearoftext') {
                        var sorted_buckets = o.buckets
                            .sort(function(a, b) {
                                // console.warn(typeof +b.value.integerValue)
                                return +b.value.integerValue - (+a.value.integerValue)
                            })
                    } else {
                        if (o.operatorName === 'typeofmeasure') {



                            var sorted_buckets = []
                            typeofmeasure.forEach(function(key) {

                                //console.warn(o.buckets)
                                var found = false;
                                o.buckets.filter(function(item) {
                                    /* console.log(item) */
                                    if (!found && item.value.stringValue === key.id) {

                                        // count: 9
                                        // percentage: 52
                                        // value: {stringValue: 'All waters'}
                                        sorted_buckets.push(item);
                                        found = true;
                                        return false;
                                    } else
                                        return true;
                                })
                            })

                           // console.info(sorted_buckets)
                        } else {
                            var sorted_buckets = o.buckets;
                        }
                    }
                    for (var x in sorted_buckets) {
                        var obj = sorted_buckets[x];
                        var count = parseInt(obj.count);


                        // if (!obj.count && !obj.percentage)
                        //     debugger
                        var percentage = parseInt(obj.percentage);

                        if (!obj.count)
                            var count = Math.ceil((total * percentage) / 100);
                        else
                            var count = parseInt(obj.count)

                        if (obj.value.stringValue) {
                            if (obj.value.stringValue.includes('#')) {
                                //html += '<li>' + obj.value.stringValue.split('#')[0] + ' counts ' + obj.count + '</li>'
                                html += '<li class="row valign-wrapper"><span class="col s10">' + obj.value.stringValue.split('#')[0] + '</span><span data-badge-caption="" class="col s2 my_blue badge">' + count + '</span></li>';
                            } else {

                                if (o.operatorName === 'country') {



                                    var pos = iso3_codes.indexOf(obj.value.stringValue);
                                    if (pos > -1) {
                                        //console.log(pos, countriesJSON[pos])
                                        var t = countriesJSON[pos].en;
                                    } else {
                                        // debugger
                                        console.info(obj)
                                        var t = 'no country for ' + obj.value.stringValue;
                                    }
                                    html += '<li class="row valign-wrapper"><span iso3="' + obj.value.stringValue + '" class="col s10">' + t + '</span><span data-badge-caption="" class="col s2 my_blue badge">' + count + '</span></li>';
                                } else

                                {
                                    //if (o.operatorName == 'typeofmeasure') {

                                    // 202 100
                                    // x   18
                                    // debugger
                                    // if (!obj.count)
                                    //     var count = Math.ceil((total * percentage) / 100);
                                    // else
                                    //     var count = parseInt(obj.count)

                                    html += '<li class="row valign-wrapper"><span class="col s10">' + obj.value.stringValue + '</span><span data-badge-caption="" class="col s2 my_blue badge">' + count + '</span></li>';
                                    // } else

                                    // {
                                    //     html += '<li class="row valign-wrapper"><span class="col s10">' + obj.value.stringValue + '</span><span data-badge-caption="" class="col s2 my_blue badge">' + count + '</span></li>';
                                    // }
                                    // }

                                }

                            }
                        } else {

                            html += '<li class="row valign-wrapper"><span class="col s10">' + obj.value.integerValue + '</span><span data-badge-caption="" class="col s2 my_blue badge">' + count + '</span></li>';

                        }
                        // html+='<li>'+html+'</li>'
                    }
                    html += '</ul> </div> </li> </ul>';

                    $('.facet-nav .content').append(html);
                }
            }
            if ($('.facet-nav .collapsible').length > 0) {


                $('.facet-nav').show();




                $('.results_left,.results_right').show();
                var filters = [];
                $('.facet-nav .content .collapsible-body').on('click', function(e) {

                    if (this_app.rows.length === 0) {
                        this_app.rows = $('.result-list .parent-item-container');
                    }

                    console.log($(e.target))
                    if ($(e.target).hasClass('valign-wrapper') || $(e.target).parents().closest('.valign-wrapper').length > 0) {
                        //$(e.target).hasClass('valign-wrapper') ||
                        if ($(e.target).hasClass('valign-wrapper'))
                            var row = $(e.target);
                        else
                            var row = $(e.target).parents().closest('.row.valign-wrapper');

                        console.log(row.find('.s10'))


                        //countryEn
                        var field = $(this).parents().closest('.collapsible').attr('field');
                        if (field === 'countryIso') {

                            var val = row.find('.s10').attr('iso3');
                            console.warn(val)
                        } else {
                            var val = row.find('.s10').text();
                        }

                        var f = filters.filter(d => d.field === field)[0];
                        //debugger
                        console.log(this_app.fields_to_filter, field, f)
                        if (!f) {
                            var field = this_app.fields_to_filter.filter(d => d.field === field)[0];



                            var cloned_field = Object.assign({}, field);
                            cloned_field.values = [val]

                            filters.push(cloned_field)
                        } else {
                            if (row.hasClass('inactive')) {

                                var pos = f.values.indexOf(val);

                                f.values.splice(pos, 1);

                            } else {
                                //filters.filter(d => d.field == field)[0]

                                f.values.push(val)
                            }

                        }

                        row.toggleClass('inactive');

                        console.warn(filters);

                        this_app.filters = filters;
                        console.warn(this_app.filters)

                        var t = '* | '
                        for (var p in this_app.filters) {

                            //we add here the ones we want to hide!!!
                            this_app.filters[p].true_false_arr = []

                            var this_filter = this_app.filters[p];

                            if (this_filter.values.length > 0) {
                                console.info(this_filter.values)
                                for (var x in this_filter.values) {
                                    ////* |  (country:(ALB) | country:(AGO) ) typeoflegalinstrument:(\"National legislation\") inforce:(Yes)
                                    if (x === 0) {
                                        t += ' ('
                                    }
                                    t += this_filter.operatorName + ':(' + this_filter.values[x] + ')';

                                    if (x === this_filter.values.length - 1)
                                        t += ')'
                                    else
                                    if (this_filter.values.length > 1)
                                        t += ' | '

                                }
                            }
                        }
                        //default
                        t += ' inforce:(Yes)'; // allaquaticspecies:(Yes)';

                        var query = {
                            "query": t,
                            "pageSize": 100,

                            "requestOptions": {


                                "searchApplicationId": Config.PwsSearchApplicationId,
                            },
                            "facetOptions": [{
                                    "operatorName": "country",
                                    "numFacetBuckets": 200
                                }, {
                                    "operatorName": "rfmo",
                                    "numFacetBuckets": 100
                                },
                                {
                                    "operatorName": "typeofmeasure",
                                    "numFacetBuckets": 100
                                },

                                {
                                    "operatorName": "typeoflegalinstrument",
                                    "numFacetBuckets": 100
                                }, {
                                    "operatorName": "allaquaticspecies",
                                    "numFacetBuckets": 100
                                }, {
                                    "operatorName": "vesselbyflag",
                                    "numFacetBuckets": 100
                                }, {
                                    "operatorName": "competencearea",
                                    "numFacetBuckets": 100
                                }, {
                                    "operatorName": "yearoftext",
                                    "numFacetBuckets": 100
                                }
                            ],
                            "queryInterpretationOptions": {
                                "enableVerbatimMode": true
                            },
                            "start": 0

                        };
                        console.info(t)


                        // $.when(exec_subfacet(t))
                        //     .then(function(d) {
                        //         debugger;
                        //         console.info(d);
                        //     })

                        exec_filter()
                    } else {

                    }

                })
            }

        }

        console.info(query)

        var datastring = JSON.stringify(query);
        //   var datastring={"query":'* vesselbyflag:(\"non-EU vessels\") typeofmeasure:("Port entry authorization") inforce:(Yes)',"pageSize":100,"requestOptions":{"searchApplicationId":"searchapplications/1be285f8874b8c6ba8027fcdb39b28ed"},"facetOptions":[{"operatorName":"country","numFacetBuckets":200},{"operatorName":"rfmo","numFacetBuckets":100},{"operatorName":"typeofmeasure","numFacetBuckets":100},{"operatorName":"typeoflegalinstrument","numFacetBuckets":100},{"operatorName":"allaquaticspecies","numFacetBuckets":100},{"operatorName":"vesselbyflag","numFacetBuckets":100},{"operatorName":"competencearea","numFacetBuckets":100},{"operatorName":"yearoftext","numFacetBuckets":100}],"queryInterpretationOptions":{"enableVerbatimMode":true},"start":0,"sortOptions":{"operatorName":"yearoftext","sortOrder":"DESCENDING"}}
        console.log(datastring)
        $.ajax({
            type: "POST",
            url: Config.apis.gcs,
            contentType: "text/plain",

            data: JSON.stringify(query),
            success: function(data) {

                this_app.counted_ajax_done++;
                // var datastring = JSON.stringify(query)
               // console.log(data.results)
                $('.query_results_count_filtered').hide()
                console.warn(query)
                    //* |  (country:(ALB) | country:(AGO) ) typeoflegalinstrument:(\"National legislation\") inforce:(Yes)
                var datastring = query;
                //JSON.parse(datastring);
                if (data.resultCountExact) {
                    var counts = parseInt(data.resultCountExact);
                } else {
                    if (data.resultCountEstimate)
                        var counts = parseInt(data.resultCountEstimate);
                }

                this_app.numPages =
                    Math.ceil(counts / 100);

                if (!this_app.records) {
                    this_app.records = [];
                    // this_app.records.push(data.results)
                    this_app.records.push.apply(this_app.records, data.results);

                }

                if (counts === 0) {

                    $('.busy-load-container').hide();


                    $('.searchbox .tab').eq(1).find('a span').trigger('click');
                    $('#results_tab .no_results').show();
                    $('#maincontent,.facet-nav').hide();

                }


                if (counts > 0) {
                    $('#results_tab .no_results').hide();

                    $('#maincontent').show();
                    $('#results_tab .no_info').hide()
                    var facets_data = data.facetResults;
                    create_facets(counts, facets_data)
                    if (this_app.numPages === 1) {


                        // $('.query_results_count_wrapper').show(); applied in create_facets
                        this_app.records_joined = [].concat.apply([], this_app.records);

                        $('.query_results_count span').text(this_app.records.length)
                        associated_data(this_app.records_joined)
                        callback(this_app.records_joined);

                        //WHY???
                        $('.result-list .parent-item-container').removeClass('hidden');
                        // $('.measure-cited-full').readmore({
                        //     substr_len: 300

                        // })







                    }




                    if (this_app.numPages > 1) {
                        var promises_arr = []
                        for (var i = 2; i <= this_app.numPages; i++) {

                            datastring.start = (i - 1) * 100;

                            promises_arr.push(promises_ajax(i, datastring))
                            if (i === this_app.numPages) {



                            }


                        }

                        console.warn(promises_arr)
                            //Promise.all([ajax1(), ajax2()]).then(() => {
                        Promise.all(promises_arr).then(() => {

                            console.info(this_app.records)



                            this_app.records_joined = [].concat.apply([], this_app.records);
                            //debugger

                            $('.query_results_count span').text(this_app.records_joined.length)

                            $('.query_results_count_wrapper').show();

                            associated_data(this_app.records_joined)
                            callback(this_app.records_joined);

                            //WHY?????
                            $('.result-list .parent-item-container').removeClass('hidden');


                        }).catch(() => {
                            // all requests finished but one or more failed
                        })
                    }
                }


            }
        })

    }
}