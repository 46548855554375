import React, {
    Component
} from 'react';
import {
    FormattedMessage
} from 'react-intl';
import ResultItem from './ResultItem.js';
//import Config from '../../../config';
import $ from 'jquery';


/**
 * Show the result list
 * @prop {string} lang the language
 *
 */
export default class ResultList extends Component {
    constructor(props) {
        super(props);
   
        this.state = {};
        this.faolex_countries = props.faolex_countries;
    }

    faolex_countries;
 
    render() {
        if (this.props.this_query)
        {
        console.log(this.props.this_query)
        
        }
   
console.log(this)
        
     //   const data = (this.props.data || []);

        const data = (this.props.formData.gcsData || []);
        console.warn(data)
        if (data.length>0)
        {
          
            console.warn('some result from ResultList.js')
          
        }
        else
        {
            console.warn('NO result from ResultList.js')
        }
    
        
        $('.searchbox .tabs .tab').show();

        return (
        
            <div id="R1" className="result-list">
                {data.length===0 ? (
                    <div style={{ whiteSpace: 'nowrap' }} className="no-records test"><FormattedMessage id="SearchApp.labels.no_records" /></div>
                ) : ""}
                    {  data.length>0 ? (
                    //this.props.comparing?
                   
                             <div class="results_div">
                                 {
                                     
                            data.map(
                            (value, index) =>
                                <ResultItem
                                this_query={this.props.this_query}
                                formData={this.props.formData.formData}
                                comparing={this.props.comparing}
                                lang={'en'} data={value} key={index} index={index} faolex_countries={this.faolex_countries} 
                                resultState={this.props.resultState} />
                        ) }
                        
                        </div>
                        // : data.map(
                        //     (value, index) =>
                        //         <ResultItem
                        //         this_query={this.props.this_query}
                        //         formData={this.props.formData.formData}
                        //         comparing={this.props.comparing}
                        //         lang={'en'} data={value} key={index} index={index} faolex_countries={this.faolex_countries} resultState={this.props.resultState} />
                        // ) 
                    ):'<div>no results</div>'
                       
                    }
    
                {/* {data.map(
                    (value, index) =>
                        <ResultItem
                        this_query={this.props.this_query}
                        formData={this.props.formData.formData}
                        comparing={this.props.comparing}
                        lang={'en'} data={value} key={index} index={index} faolex_countries={this.faolex_countries} resultState={this.props.resultState} />
                )} */}
                
            </div>
        );
    }
}
